<div class="card-he tab">
  <div class="card-wrapper" #dataToExport>
    <mat-card class="example-card" style="background: white">
      <mat-card-header class="inline-centered-items horizon-field" style="background: white">
        <div>
          <div class="heading ananlog-heading">
            <span class="heading-title">COST OF TREATMENT AND REIMBURSEMENT STATUS</span>
            <!-- <mat-icon>info_outline</mat-icon> -->
          </div>
          <div class="record-count">
            <span>{{ mainRecordCount }} found</span>
          </div>
        </div>
        <mat-form-field class="pull-right">
          <mat-label>Price type</mat-label>
          <mat-select name="priceType" [(ngModel)]="selectedCOTPriceType" >
            <ng-container *ngFor="let priceType of priceTypes">
              <mat-option
              *ngIf="priceType.visible"
              [value]="priceType.value"
              [disabled]="priceType.disabled"
              (click)="changePrice(priceType.viewValue)"
            >
              {{ priceType.viewValue }}
            </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="toggle-button-group">
          <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle [ngClass]="isGridClicked ? 'toggle-btn-active' : 'toggle-btn'"
              (click)="gridToggle('grid',true)">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [ngClass]="isChartClicked ? 'toggle-btn-active' : 'toggle-btn'"
              (click)="gridToggle('chart',true)">
              <mat-icon>pie_chart</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div>
          <a class="download-div" (click)="dwonloadFile()"><img src="/assets/dwonload-purple.svg" class="dwonload-icon"
              alt="Export Data" href="#" /></a>
        </div>
      </mat-card-header>
      <mat-card-content style="background: white">
        <he-project-analogue-cot-grid [ngClass]="!isGridClicked ? 'grid-display-hide' : 'grid-display-show'"
          (recordsCountEvent)="mainGridCountChanged($event)"
          [selectedCOTPriceType]="selectedCOTPriceType" [selectedCOTPriceTypeView]="selectedCOTPriceTypeView"></he-project-analogue-cot-grid>

        <he-project-analogue-selection-cot-chart [ngClass]="
            !isChartClicked ? 'grid-display-hide' : 'grid-display-show'
          " [selectedPriceType]="selectedCOTPriceType"
          [selectedCOTPriceTypeView]="selectedCOTPriceTypeView"
          (chartInstance)="saveInstance($event)"></he-project-analogue-selection-cot-chart>
      </mat-card-content>
      <mat-card-footer style="background: white">
        <div class="keys">
          <div>
            <div class="title">Key:</div>
          </div>
          <div class="keysDiv">
            <ul class="keysList">
              <li>
                <div class="circleBase circle1"></div>
                Not recommended
              </li>
              <li>
                <div class="circleBase circle2"></div>
                Reimbursed with Restrictions
              </li>
              <li>
                <div class="circleBase circle3"></div>
                Reimbursed
              </li>
              <li>
                <div class="circleBase circle4"></div>
                NA
              </li>
            </ul>
          </div>
        </div>
      </mat-card-footer>
      <div class="note" *heFeatureFlag="{ Name: 'div-bayer-note', Page: 'common' }">
        Note - A 5-year time horizon was applied to calculate COT using loading
        (Year 1) and maintenance doses (Year 2-5)
      </div>
    </mat-card>
    <div id="download">
      <img #canvas />
      <a #downloadLink></a>
    </div>
  </div>
</div>
<!-- need to connect end points ANALOGUE PRICE PER INPUT-->

<!-- Regulatory Approval Timeline Table -->
<!-- <div class="card-he tab">
  <div class="card-wrapper">
    <mat-card class="example-card">
      <mat-card-header class="inline-centered-items">
        <div>
          <div class="heading ananlog-heading">
            <span class="heading-title">REGULATORY APPROVAL AND HTA DECISION DATES</span>
          </div>
          <div class="record-count">
            <span>{{ recordCount }} found</span>
          </div>
        </div>
        <div class="pull-right">
          <a class="download-div" (click)="dwonloadTimelineFile()"><img src="/assets/dwonload-purple.svg"
              class="dwonload-icon" alt="Export Data" href="#" /></a>
        </div>
      </mat-card-header>
      <mat-card-content>
        <he-project-analog-sub-grid [analogueRegulatoryData]="analogueRegulatoryData"
          (recordsCountEvent)="subGridCountChanged($event)"></he-project-analog-sub-grid>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->

<div class="card-he tab" *heFeatureFlag="{ Name: 'analogue-timeline', Page: 'Angalogue' }">
  <div class="card-wrapper" #dataToExportTimeline>
    <mat-card class="example-card">
      <mat-card-header style="background: white" class="inline-centered-items">
        <div class="heading ananlog-heading">
          <span class="heading-title">ANALOGUE REGULATORY APPROVAL AND REIMBURSEMENT TIMELINE</span>
          <!-- <mat-icon>info_outline</mat-icon> -->
        </div>
        <div class="pull-right">
          <div class="pull-right">
            <a class="download-div" [matMenuTriggerFor]="downloadMenu"><img src="/assets/dwonload-purple.svg"
                class="dwonload-icon" alt="Export Data" href="#" /></a>
            <mat-menu #downloadMenu="matMenu">
              <button mat-menu-item (click)="dwonloadTimeLineImage()" class="downloadMenu-item">Download as Image</button>
              <button mat-menu-item (click)="dwonloadTimelineFile()" class="downloadMenu-item">Download as CSV</button>
            </mat-menu>
          </div>
        </div>
      </mat-card-header>
      <mat-card-header class="button-centered-align" style="background: white">
        <mat-button-toggle-group [value]="viewMode" name="fontStyle" aria-label="Font Style"
          (change)="changeView($event.value)">
          <mat-button-toggle value="Country">By Country</mat-button-toggle>
          <mat-button-toggle value="Analogue">By Analogue</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-card-header>
      <mat-card-content style="background: white">
        <he-angalogue-timeline [analogueRegulatoryData]="analogueRegulatoryData"
          [viewMode]="viewMode" (selectOption)="changeOption($event)" ></he-angalogue-timeline>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="footer-comment">
  <span>GPI obtains raw currency exchange rates from
    <a href="https://openexchangerates.org/">Open Exchange Rates</a> and
    calculates a rolling 30-day average. This average is then used to convert
    monetary values (incl. pack prices, cost of treatment, etc.).
  </span>
</div>