import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { HorizonProjectService } from '../../../../horizon-project/horizon-project.service';
import { AnalogueCotDataFormat } from '../../../../horizon-project/models/AnalogueCotDataFormat';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import {
  dmy_ymd,
  ToReadableDate,
} from '../../../../horizon-project/utility-functions/DateFormat';
import { chartCicleColorDecider } from '../../../../horizon-project/utility-functions/ReimbursedColorDecide';
import {
  CapitalFirstLetter,
  Capitalise,
} from '../../../../horizon-project/utility-functions/StringFormatter';
import { HeaderService } from '../../../../shared/services/header.service';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Subject, takeUntil } from 'rxjs';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { CurrencyPipe } from '@angular/common';

export interface Analogue {
  [key: string]: string | number | boolean;
}

const ELEMENT_DATA: Analogue[] = [];

@Component({
  selector: 'he-project-analogue-cot-grid',
  templateUrl: './project-analogue-cot-grid.component.html',
  styleUrls: ['./project-analogue-cot-grid.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ProjectAnalogueCotGridComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Output() recordsCountEvent = new EventEmitter<number>(true);

  @Input() selectedCOTPriceType = '';
  @Input() selectedCOTPriceTypeView = '';
  @ViewChild(MatTableExporterDirective)
  exporter?: MatTableExporterDirective;

  showCotgridSection: boolean = true;
  cotData: AnalogueCotDataFormat[] = [];
  formattedCotGridData: any[] = [];
  countries: any[] = [];
  countriesInfoData: any[] = [];
  brands: any = [];
  brandObj: any = {};

  displayedColumns: string[] = [
    // 'position',
    'brandedInnName',
    'inn',
    'initialLaunchDate',
    'indication',
    'macenismOfAction',
  ];
  dataSource = new MatTableDataSource([{}]);
  checked = false;
  disabled = false;
  projectId = '';
  currentPriceType = '';
  selectedCurrency = '';

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  private unsubscriber$ = new Subject<void>();

  constructor(
    private anlogueService: AnalogueService,
    private headerService: HeaderService,
    private currencyPipe: CurrencyPipe,
    private activatedRouter: ActivatedRoute,
    private currencyConversionService: CurrencyConversionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);

    // need to remove extra call here with some condition
    // this.headerService.setSelectedPriceType(
    //   changes.selectedCOTPriceType.currentValue
    // );

    this.formatCotGridData().then(() => {
      this.AddCurrencyData();
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.anlogueService.analogueCotDataLoading
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.showCotgridSection = !data;
      });
    this.currencyConversionService
      .GetSelectedCurrency()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((currency: string) => {
        this.selectedCurrency = currency;
        this.formatCotGridData().then(() => {
          this.AddCurrencyData();
        });
      });
  }

  /**
   * this function calls the service to get the analogue cot data for the project
   * further chains the formatting method for the data.
   */
  private async loadData() {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (p) => {
        this.projectId = p.project;
        if (this.projectId) {
          await this.anlogueService
            .getAnalogueCotData(this.projectId)
            .then((cotData: AnalogueCotDataFormat[]) => {
              this.cotData = cotData;
              this.formatCotGridData();
            })
            .then(() => {
              this.AddCurrencyData();
            });
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.recordsCountEvent.emit(this.dataSource.data.length);
  }

  public toggle(event: MatSlideToggleChange, position: number) {
    ELEMENT_DATA[position - 1].highlight = event.checked;
  }

  /**
   * Formats the cot data in the format and updates the data souce to show in the grid.
   * Also filters the specific price type data based on which option is selected.
   */
  public async formatCotGridData() {
    this.showCotgridSection = false;
    this.formattedCotGridData = [];
    this.brands = [];
    this.brandObj = {};
    let countriesInfoData: any[] = [];
    const countries = this.cotData
      ? [...new Set(this.cotData.map((item) => Capitalise(item.country)))]
      : [];
    if (this.cotData.length != 0) {
      this.cotData.forEach((element) => {
        if (!countriesInfoData.some((x) => x.country === element.country)) {
          countriesInfoData.push({
            country: Capitalise(element.country),
            infoIconData: element.infoIconData.filter(
              (x: any) => x.priceType === this.selectedCOTPriceTypeView
            )[0].infoIconData,
          });
        }
      });
    }

    this.countries = countries.sort();
    this.countriesInfoData = countriesInfoData.sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    this.cotData.forEach((element, index) => {
      this.brandObj = {
        brandName: element.brandName,
        tli: element.tli,
        inn: element.inn,
        moa: element.moa,
      };
      this.brands.filter(
        (brand: any) =>
          brand.brandName === element.brandName &&
          brand.tli === element.tli &&
          brand.inn === element.inn &&
          brand.moa === element.moa
      ).length === 0
        ? this.brands.push(this.brandObj)
        : null;
    });
    this.brands.forEach((element: any, index: any) => {
      const brandsCotData = this.cotData.filter(
        (x) =>
          x.brandName.toLowerCase().trim() ===
            element.brandName.toLowerCase().trim() &&
          x.tli.toLowerCase().trim() === element.tli.toLowerCase().trim() &&
          x.inn.toLowerCase().trim() === element.inn.toLowerCase().trim() &&
          x.moa.toLowerCase().trim() === element.moa.toLowerCase().trim()
      );
      const sortBrandCotData = brandsCotData?.sort(function (a, b) {
        return (
          new Date(dmy_ymd(a.initialLaunchDate)).getTime() -
          new Date(dmy_ymd(b.initialLaunchDate)).getTime()
        );
      });
      const formatCotRow: any = {
        highlight: true,
        position: sortBrandCotData[0].sequenceNo,
        brandedInnName: Capitalise(sortBrandCotData[0].brandName),
        initialLaunchDate: ToReadableDate(
          sortBrandCotData[0].initialLaunchDate
        ),
        indication: Capitalise(sortBrandCotData[0].tli),
        inn: CapitalFirstLetter(sortBrandCotData[0].inn),
        macenismOfAction: Capitalise(sortBrandCotData[0].moa),
        infoIconData: Capitalise(
          sortBrandCotData[0].infoIconData.filter(
            (x: any) => x.priceType === this.selectedCOTPriceTypeView
          )[0].infoIconData
        ),
      };
      countries.forEach(async (element) => {
        const countriesBrandData = brandsCotData.filter(
          (x) => x.country.toLowerCase().trim() === element.toLowerCase().trim()
        )[0];
        switch (this.selectedCOTPriceType.toLowerCase()) {
          case 'launchprice':
            formatCotRow[element] = await this.getCurrencyConvertedValue(
              countriesBrandData?.launchPrice,
              element
            );
            break;
          case 'reimbursedprice':
            formatCotRow[element] = await this.getCurrencyConvertedValue(
              countriesBrandData?.reimbursedPrice,
              element
            );
            break;
          case 'currentprice':
            formatCotRow[element] = await this.getCurrencyConvertedValue(
              countriesBrandData?.currentPrice,
              element
            );
            break;
          case 'netprice':
            formatCotRow[element] = await this.getCurrencyConvertedValue(
              countriesBrandData?.netPrice,
              element
            );
            break;
        }

        formatCotRow[element + 'Color'] = chartCicleColorDecider(
          countriesBrandData?.reimbursedClassification
        );
        formatCotRow[element] = Math.round(
          Number.parseFloat(formatCotRow[element])
        );
      });
      this.formattedCotGridData.push(formatCotRow);
    });
    this.formattedCotGridData.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    this.anlogueService.analogueGridData.next(this.formattedCotGridData);
    this.dataSource = new MatTableDataSource(this.formattedCotGridData);
    this.displayedColumns = [
      'brandedInnName',
      'inn',
      'initialLaunchDate',
      'indication',
      'macenismOfAction',
      ...this.countries,
    ];
    this.recordsCountEvent.emit(this.formattedCotGridData.length);
    setTimeout(() => {
      this.showCotgridSection = true;
    }, 500);
  }

  /**
   * desides the filename based on selected price type and passes it to exporter service to download the csv file.
   */
  dwonloadData() {
    let fileName = '';
    switch (this.selectedCOTPriceType) {
      case 'launchPrice':
        fileName = 'Analogue COT_LaunchPrice';
        break;
      case 'reimbursedPrice':
        fileName = 'Analogue COT_ReimbursedPrice';
        break;
      case 'currentPrice':
        fileName = 'Analogue COT_CurrentPrice';
        break;
      case 'netPrice':
        fileName = 'Analogue COT_NetPrice';
        break;
    }
    let header =
      'Brand Name,INN,Initial Launch Date,Indication,Mechanism of Action,';
    this.countriesInfoData.forEach((country) => {
      header +=
        '"' + country.country + ' \rInfo: ' + country.infoIconData + '" ,';
    });
    let line1 = '';
    this.dataSource.filteredData.forEach((data: any) => {
      line1 +=
        data.brandedInnName +
        ',' +
        data.inn +
        ',"' +
        data.initialLaunchDate +
        '",' +
        data.indication +
        ',' +
        data.macenismOfAction +
        ',';
      this.countriesInfoData.forEach((country) => {
        if (data[country.country] > 0) {
          line1 +=
            '" ' +
            this.currencyPipe.transform(
              data[country.country],
              country.currency,
              'symbol-narrow',
              '1.0-2'
            ) +
            '"' +
            ',';
        } else {
          line1 += 'NA,';
        }
      });
      line1 += '\r\n';
    });
    let csvArray = header + '\r\n' + line1;
    const a = document.createElement('a');
    const blob = new Blob(['\ufeff' + csvArray], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  /**
   * this function finds the currency code in case of local is selcted based on the country.
   */
  async AddCurrencyData() {
    this.countriesInfoData.forEach(async (element) => {
      if (this.selectedCurrency.toLowerCase().trim() === 'local') {
        const currency =
          await this.currencyConversionService.getCountryCurrencyData(
            element.country
          );
        element.currency = currency.currencyCode;
      } else {
        element.currency = this.selectedCurrency;
      }
    });
  }

  /**
   * converts the currency based on currency code selected.
   * @param data the number which you are trying to convert
   * @param country the country in which the data is application
   * @returns
   */
  async getCurrencyConvertedValue(data: number, country: string) {
    const numericValue = data;
    const defaultCurrency = this.currencyConversionService.GetDefaultCurrency();
    let convertedData = 0.0;
    if (this.selectedCurrency.toLowerCase().trim() === 'local') {
      const countryCurrency =
        await this.currencyConversionService.getCountryCurrencyData(country);
      convertedData = await this.currencyConversionService.convertCurrency(
        numericValue,
        defaultCurrency,
        countryCurrency.currencyCode
      );
    } else {
      convertedData = await this.currencyConversionService.convertCurrency(
        numericValue,
        defaultCurrency,
        this.selectedCurrency
      );
    }
    return convertedData;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
