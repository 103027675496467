export enum FilterNameEnum {
  indicationsAllowed = 'indicationsAllowed',
  indications = 'indications',
  dynamicIndications = 'dynamicIndications',
  countries = 'countries',
  brands = 'brands',
  comparatorDrug = 'comparatorDrug',
  calcPer = 'calcPer',
  includeWastage = 'includeWastage',
  reimbursementStatus = 'reimbursementStatus',
  priceCategory = 'priceCategory',
  priceType = 'priceType',
  currency = 'currency',
  orphanStatus = 'orphanStatus',
  brandedStatus = 'brandedStatus',
  discontinued = 'discontinued',
  piStatus = 'piStatus',
  topLevelIndications = 'topLevelIndications',
  onLoad = 'onLoad'
}

export enum FilterMeasuresPeriodEnum {
  annum = 'annum',
  month = 'month',
  day = 'day',
  cycle = 'cycle',
  ddd = 'ddd',
  pack = 'pack',
}

export enum FilterMeasuresPriceCategoryEnum {
  List = 'List',
  VisibleNet = 'VisibleNet',
  LessDiscount = 'LessDiscount',
}

export enum FilterMeasuresPriceTypeEnum {
  exfactoryprice = 'exfactoryprice',
  hospitalprice = 'hospitalprice',
  ppp = 'ppp',
  ppexvat = 'ppexvat',
  ppincvat = 'ppincvat',
  hierarchy = 'hierarchy'
}

export enum FilterProductParamsReimbursementEnum {
  all = 'All',
  reimbursed = 'Reimbursed',
  reimbursedWithRestrictions = 'Reimbursed with restrictions',
  notReimbursed = 'Not reimbursed',
}

export enum FilterProductParamsOrphanStatusEnum {
  all = 'All',
  orphan = 'Orphan',
  nonOrphan = 'Non orphan',
}

export enum FilterProductParamsBrandedStatusEnum {
  all = 'All',
  branded = 'BRANDED',
  generic = 'GENERIC',
  biosimilar = 'BIOSIMILAR',
}

export enum FilterIncludeExcludeEnum {
  include = 'Include',
  exclude = 'Exclude',
}
export enum FilterOrphanEnum {
  orphan = 'Orphan',
  nonOrphan = 'Non-orphan',
}

export enum ContextSourceTypeEnum {
  brand = 'brand',
  indication = 'indication'
}
export enum RequestTypeEnum {
  PnR = 0,
  CoT = 1,
  HTA = 2
}

export enum GridViewTypeEnum {
  packprice = 'packprice',
  indicationcot = 'indicationcot',
  hta = 'hta'
}

export enum HTAColumnNameEnum {
  ReimbursedClassification = 'ReimbursedClassification',
  PackDescription = 'PackDescription',
  RegulatoryIndication = 'RegulatoryIndication'
}
