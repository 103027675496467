<div [ngSwitch]="inputdata.inputType" id="{{inputdata.title}}">
    <div *ngSwitchCase="'1'"  class="custom-toogle">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="inputdata.selectedValue">
            <mat-button-toggle *ngFor="let value of inputdata.valueList" id="{{value}}" [value]="value" (click)="selectToggle(value)">{{value}}</mat-button-toggle>
          </mat-button-toggle-group>
    </div>
    <div *ngSwitchCase="'2'" class="custom-input">
        <mat-form-field appearance="outline" style="width:100%;">
            <mat-select placeholder="Please select" (selectionChange)="selectToggle($event.value)" [value]="inputdata.selectedValue">
              <mat-option *ngFor="let topping of inputdata.valueList" id="{{topping}}" [value]="topping">{{topping}}</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div *ngSwitchCase="'3'" class="custom-input custom-autocomplete">
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-chip-grid #chipGrid class="">
          <mat-chip-row *ngFor="let fruit of inputdata.selectedValueList" (removed)="remove(fruit)">
            {{fruit}}
            <button matChipRemove [attr.aria-label]="'remove ' + fruit">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="Search relevant key words (Like 'ase' or 'enzyme')" #fruitInput [formControl]="fruitCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-grid>
        <mat-icon matPrefix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let fruit of filteredList  | async" [value]="fruit">
            {{fruit}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'4'" class="custom-input">
      <div class="multi-inputs" >
        <ng-container *ngFor="let multiInput of inputdata.multiInputList;index as i">
          <mat-form-field appearance="outline" style="width:42%;" >
            <mat-select placeholder="Please select Therapy Area" (selectionChange)="selectMultiValue(i,$event.value)" [value]="multiInput.selectedTherapyValue">
              <mat-option *ngFor="let topping of multiInput.therapyValueList" [value]="topping.name">{{topping.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width:42%;" >
            <mat-select placeholder="Please select Indications" (selectionChange)="selectIndicationMultiValue(i,$event.value)" [value]="multiInput.selectedIndicationValue" multiple>
              <mat-option class="hide-all" #allSelected (click)="toggleAllSelection(i)" [value]="0" *ngIf="multiInput.selectedTherapyValue != ''">Select all</mat-option>
              <mat-option *ngFor="let topping of multiInput.indicationValueList" [value]="topping.name">{{topping.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button [disabled]="inputdata.multiInputList?.length === 1" (click)="removeInput(i)" class="add-action-btn">
            <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
          </button>
        </ng-container>

        <button mat-stroked-button *ngIf="inputdata.multiInputList?.length !== 8" (click)="addNew()" class="add-action-btn">
          <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
        </button>
      </div>

  </div>
</div>