<mat-nav-list fxFlex *ngIf="sideBarOpen">
  <div class="flex-container" fxLayout="row" fxLayoutAlign="flex-start center">
    <h3 class="sidebar-title" *ngIf="editProjectId === ''">
      Create new project
    </h3>
    <h3 class="sidebar-title" *ngIf="editProjectId !== ''">Edit project</h3>
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <div id="project-list-container">
    <a
      class="asset-link"
      mat-list-item
      a
      [ngClass]="{ 'list-item-active': selectedScenarioIndex === -1 }"
      (click)="
        goToDetails(
          editProjectId !== ''
            ? '/horizon/edit-project/' + this.editProjectId
            : '/horizon/create-new-project',
          -1
        )
      "
    >
      <div>
        <span class="project-title">Project Details</span>
      </div>
    </a>
    <a
      class="asset-link"
      mat-list-item
      [matTooltip]="ErrorDetails"
      matTooltipClass="horizon-tooltip"

      [ngClass]="selectedScenarioIndex === 0 ? 'list-item-active': editProjectId !== '' ? 'list-item-disable' :projectService.isdetailsValid ? '' :'list-item-disable' "
      (click)="goToDetails('/horizon/analogue-selection', 0)"
      *heFeatureFlag="{
        Name: 'analogue-selection-criteria',
        Page: 'project-sidebar'
      }"
    >
      <div>
        <span class="project-title">Analogue Selection</span>
      </div>
    </a>
    <a
    class="asset-link"
    mat-list-item
    [matTooltip]="ErrorAnalogues"
    matTooltipClass="horizon-tooltip"

    [ngClass]="selectedScenarioIndex === -2 ? 'list-item-active':  analogueIsValid ? '' :'list-item-disable'  "
    *heFeatureFlag="{
      Name: 'net-price-assumptions',
      Page: 'project-sidebar'
    }" 
    (click)="goToDetails('/horizon/price-assumption', -2)"

  >
    <div>
      <span class="project-title">Net Price Assumptions</span>
    </div>
  </a>
    <div *ngFor="let scenario of scenarios">
      <a
        [ngClass]="
         selectedScenarioIndex === scenario.id ?  'list-item-active':netPriceValid ? '' :'list-item-disable'  
        "
        [matTooltip]="ErrorNetprice"
      matTooltipClass="horizon-tooltip"

        (click)="goToScenario(scenario.id, true)"
        mat-list-item
        id="goto-scenario-btn"
      >
        <div>
          <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px; margin-right: 10px; opacity: 0.5" /> -->
          <span class="project-title"
            >{{ scenario.scenarioName }}
            {{ scenario?.scenarioDetails?.scenarioName }}</span
          >
          <button
            mat-icon-button
            class="delete-scenario"
            *ngIf="scenario.newScenario"
            (click)="deleteScenario(scenario.id)"
          >
            <mat-icon aria-label="Delete Scenario">remove_circle</mat-icon>
          </button>
        </div>
      </a>
    </div>
    <mat-divider></mat-divider>
    <a
      mat-list-item
      [ngClass]="projectData?.framework ? netPriceValid ? 'asset-link'  :'add-scenario-disable' : 'add-scenario-disable'"
      [matTooltip]="ErrorNetprice"
      matTooltipClass="horizon-tooltip"
      [disabled]="!projectData?.framework"
      (click)="addScenario()"
      id="add-scenario-btn"
    >
      <div>
        <img
          src="assets/plus.svg"
          class="project-icon"
          style="margin-top: -3px; margin-right: 10px"
        />
        <span class="add-scenario-title">Add Scenario</span>
      </div>
    </a>
    <!-- <a class="asset-link"> -->
    <div class="btn-div">
      <button
        mat-raised-button
        *ngIf="isSaveAsDraftFeature && editProjectId === ''"
        class="saveAs-draft-btn"
        id="draft-project-btn"
        [ngClass]="
          draftProjectDisabled
            ? 'saveAs-draft-btn-disabled'
            : 'saveAs-draft-btn'
        "
        [disabled]="draftProjectDisabled"
        (click)="saveAsDraft()"
      >
        Save As Draft
      </button>

      <button
        mat-raised-button
        color="primary"
        class="create-project-btn"
        *ngIf="editProjectId === ''"
        [ngClass]="
          createProjectDisabled
            ? 'create-project-btn-disabled'
            : 'create-project-btn'
        "
        [disabled]="createProjectDisabled"
        (click)="createProject()"
        id="submit-project-btn"
      >
        Create Project
      </button>

      <button
        mat-raised-button
        color="primary"
        class="create-project-btn"
        *ngIf="editProjectId !== ''"
        [ngClass]="
          createProjectDisabled
            ? 'create-project-btn-disabled'
            : 'create-project-btn'
        "
        [disabled]="createProjectDisabled"
        (click)="editProject()"
        id="save-project-btn"
      >
        Save Project
      </button>
    </div>
    <!-- </a> -->
    <button
      mat-stroked-button
      color="warn"
      *ngIf="isSaveAsDraftFeature && isDraftProject && editProjectId === ''"
      class="delete-draft-btn"
      id="delete-project-btn"
      (click)="deleteDraftProject()"
    >
      Delete Draft Project
    </button>
    <a class="asset-link" (click)="clearScenario()" id="cancel-project-btn">
      <div class="cancel-btn-div">
        <span class="cancel-btn">cancel</span>
      </div>
    </a>
  </div>
</mat-nav-list>
<mat-nav-list fxFlex *ngIf="!sideBarOpen">
  <div
    style="width: fit-content"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    *heFeatureFlag="{ Name: 'create-project-btn', Page: 'common' }"
  >
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</mat-nav-list>
