import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoutesEnum, UserAccessPages } from '../common';
import { CachedAccessPermissions, PermissionsResponse } from '../../core/access-permissions';

@Injectable()
export class AuthGuardService implements CanActivate {
  private listRoutes: PermissionsResponse[] = [];

  private urlName = '';

  constructor(
    private router: Router,
    private cachedAccessPermissions: CachedAccessPermissions
  ) {}

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    const accessibleRoute = await this.cachedAccessPermissions.run({payload: null}).toPromise();
    this.listRoutes = accessibleRoute?.payload as [];

    const mainUrlName = state.url.split('/')[1];
    switch (mainUrlName) {
      case 'project':
      case 'projects':
        this.urlName = 'projects';
        break;
      case RoutesEnum.AtlasPage:
        this.urlName = RoutesEnum.AtlasPage;
        break;
      case RoutesEnum.FAQ:
        this.urlName = RoutesEnum.FAQ;
        break;
      case 'insights':
      default:
        this.urlName = state.url.split('/')[2].split('?')[0];
        break;
    }

    if (mainUrlName !== 'insights') {
      this.checkPermissions();
    }
    return true;
  }

  public checkPermissions(): void {
    const selectedPermissions = UserAccessPages.filter(x => x.key === this.urlName);
    if (selectedPermissions.length > 0) {
      const accessRoutes: PermissionsResponse[] = [];
      for (const item in selectedPermissions) {
        if (selectedPermissions.hasOwnProperty(item)) {
          const accessRoute = this.listRoutes.find(x => x.code === selectedPermissions[item].value);
          if (accessRoute) {
            accessRoutes.push(accessRoute);
          }
        }
      }
      if (!accessRoutes.length) {
        this.router.navigateByUrl('/');
      }
    }
  }
}
