import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EntityFilter, EntityFilterPost, FilterResponseInterface } from '../../../../core/entity-filter';
import { map, tap } from 'rxjs/operators';
import { FilterItemInterface, FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FilterByBrandApi, FilterByInnApi, FilterByAtcApi } from '../../../../helios-api/filters';
import { FiltersValueService } from '../../filters-value.service';
import { FilterAutocompleteDescriptionInterface, FilteredGroupItemsInterface } from '../../../../uikit/filter-autocomplete-with-chips/interfaces';
import { EntityFilterAtc } from 'projects/helios-gui/src/core/entity-filter/EntityFilterAtc';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { DialogText,TooltipText } from '../../../common/constants';
import { UserPreferenceTypes } from 'projects/helios-gui/src/core/user-preferences';
import { FilterBrandPresetTypes, FilterPreset, FilterPresetCollection, FilterPresetService } from '../filter-preset';
import { UserPreferences } from 'projects/helios-gui/src/core/user-preferences';
import { Brandpreference } from '../../../common/constants';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';

@Component({
  selector: 'he-filter-by-brand',
  templateUrl: './filter-by-brand.component.html',
  styleUrls: ['./filter-by-brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterByBrandApi,
    FilterByInnApi,
    {
      provide: EntityFilter,
      useFactory: (gateway: FilterByBrandApi) => {
        return new EntityFilter(gateway);
      },
      deps: [FilterByBrandApi]
    },
    {
      provide: EntityFilterPost,
      useFactory: (gateway: FilterByInnApi) => {
        return new EntityFilterPost(gateway);
      },
      deps: [FilterByInnApi]
    },
    FilterByAtcApi,
    {
      provide: EntityFilterAtc,
      useFactory: (gateway: FilterByAtcApi) => {
        return new EntityFilterAtc(gateway);
      },
      deps: [FilterByAtcApi]
    }
  ]
})
export class FilterByBrandComponent implements OnInit {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  descriptions: FilterAutocompleteDescriptionInterface = {
    title: 'Drugs/Brands',
    emptyState: 'No results found',
    placeholder: 'Start typing to select',
    errorMessage: 'At least one drug should be selected to display information'
  };
  FilterBrandPresetTypes = FilterBrandPresetTypes;
  filteredGroupItems$: Observable<FilteredGroupItemsInterface[]> | undefined;
  loading = false;
  maxBrands = 0;
  maxBrandsExceeded = false;
  pendingConfirmationToClear=false;
  selectedBrandsToSave = [];
  saveBrandText= DialogText.BrandGroupSaveMessage;
  showInput=true;
  presetKeyName=Brandpreference.Brandpreference;
  public userPreferenceType = UserPreferenceTypes.FilterPreset;
  presets: FilterPreset[] = [];
  public filterPresets: BehaviorSubject<FilterPresetCollection[]> = new BehaviorSubject<FilterPresetCollection[]>([]);
  private readonly destroying$: Subject<void> = new Subject<void>();
  TooltipText=TooltipText;
  isSettingIconVisible=true;
  brandPreferenceFeatureFlag: boolean = false;

  constructor(
    public filtersValueService: FiltersValueService,
    private entityFilter: EntityFilter,
    private entityFilterPost: EntityFilterPost,
    private entityFilterAtc: EntityFilterAtc,
    // private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    public userPreference:UserPreferences,
    public filterPresetService:FilterPresetService,
    public featureFlagService:FeatureFlagService
    ) {
  }
  ngOnInit(): void {
    this.maxBrands = this.filtersValueService.maxBrands;
    this.hasMaxBrandsBeenExceeded(0);
    this.sharedService.selectedBrandPreferenceVal$.next('');
    this.sharedService.showBrandPrefSaveIcon$.next(true);
    this.sharedService.isBrandPreferenceValChanged$.next(true);
    this.subscribeFeatureFlags();
  }

  subscribeFeatureFlags() {
    this.featureFlagService.getBrandFiltersPresetFlag(this.destroying$).then((flagValue: boolean) => {
      this.brandPreferenceFeatureFlag = flagValue;
    });
  }
  get selectedItems(): FilterItemValueInterface[] {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.brands)?.filterValue || [];
  }

  get isBrandChipEnable(): boolean{
     return true;
  }

  selectedItemChanges(brand: FilterItemValueInterface): void {
    this.isSettingIconVisible=true;
    this.sharedService.isBrandPreferenceValChanged$.next(this.sharedService.selectedBrandPreferenceVal$.value !=''? true : false);
    this.sharedService.updateSelectedBrand('');
    let filterItemValue: FilterItemValueInterface[] = this.selectedItems;
    const hasItem: boolean = filterItemValue.some((item: FilterItemValueInterface) => item.id === brand.id);
    const isInnItem: boolean = brand.type?.toLowerCase() === 'inn';
    const atcItem: boolean = brand.type?.toLowerCase() === 'whoatc';

    if (isInnItem || atcItem) {
      const entityFilter = isInnItem ? this.entityFilterPost : this.entityFilterAtc;
      entityFilter.run(brand).subscribe((newItems: FilterResponseInterface[]) => {
        const newItemsOnly: FilterResponseInterface[] = newItems.filter(
          (newItem) => !filterItemValue.some((filterItem) => filterItem.id === newItem.id));
        filterItemValue = [...filterItemValue, ...newItemsOnly];

        const nextValue: FiltersObject = this.filtersValueService.updateItemInFilters(FilterNameEnum.brands, filterItemValue);
        this.filtersValueModified.emit(nextValue);
        this.hasMaxBrandsBeenExceeded(1);
      });
    }
    else if (!hasItem) {
      filterItemValue = [...filterItemValue, brand];
      const nextValue: FiltersObject = this.filtersValueService.updateItemInFilters(FilterNameEnum.brands, filterItemValue);
      this.filtersValueModified.emit(nextValue);
      this.hasMaxBrandsBeenExceeded(1);
    }
  }

  deletedItemChanges(brand: FilterItemValueInterface): void {
    this.isSettingIconVisible=true;    
    this.sharedService.updateSelectedBrand('');
    const nextValue: FiltersObject = this.filtersValueService.deletedItemInFilters(brand, FilterNameEnum.brands, this.filtersValue);
    this.filtersValueModified.emit(nextValue);
    const brandCount = this.hasMaxBrandsBeenExceeded(-1);
    this.sharedService.showBrandPrefSaveIcon$.next(brandCount == 0? false:true);
    this.sharedService.isBrandPreferenceValChanged$.next(brandCount == 0? false:true);
    let brandsObj: FilterItemInterface = this.filtersValueService.findItemInFilters(nextValue, FilterNameEnum.brands);
    this.restUrl(brandCount, brandsObj.filterValue[0]);
  }

  hasMaxBrandsBeenExceeded(selectionChange: number): number {
    const filterItemValue: FilterItemValueInterface[] = this.selectedItems;
    const brandCount = filterItemValue.length + selectionChange;
    this.maxBrandsExceeded = (brandCount > this.maxBrands);       
    return brandCount;
  }

  onQueryChange(value: string): void {
    this.loading = value.trim().length >= 2;
    this.filteredGroupItems$ = this.entityFilter.run({
      payload: {
        query: value
      }
    }).pipe(
      tap(() => this.loading = false),
      map((items: FilterResponseInterface[]): FilteredGroupItemsInterface[] => {
        const results: FilteredGroupItemsInterface[] = [];
        const brands: FilterItemValueInterface[] = items.filter((item) => item.type === 'brand');
        const inns: FilterItemValueInterface[] = items.filter((item) => item.type === 'inn');
        const atcs: FilterItemValueInterface[] = items.filter((item) => item.type === 'whoatc');
        if (brands.length) {
          results.push({
            groupName: 'Brand name',
            items: brands
          });
        }
        if (inns.length) {
          results.push({
            groupName: 'Active ingredient',
            items: inns
          });
        }
        if (atcs.length) {
          results.push({
            groupName: 'WHOATC',
            items: atcs
          });
        }
        return results;
      })
    );
  }

  restUrl(brandCount: number, filterItemValue: FilterItemValueInterface) {
    if (!(filterItemValue)) return;
    if (brandCount == 1)
     {
      const stateObj = filterItemValue;
      const params = {
        s: stateObj.name,
        t: stateObj.type,
        id: stateObj.id,
        ts: (new Date()).getTime(),
      };
      this.router.navigate([window.location.pathname], {
        queryParams: params,
        replaceUrl: true
      });
    }
  }

  resetFilterClicked(): any {
    this.pendingConfirmationToClear = true;
    this.sharedService.selectedBrandPreferenceVal$.next('');
  }

  removeSelectedBrandChips()
  {
    this.resetFilter();
    this.sharedService.selectedBrandPreferenceVal$.next('');
  }
  
  public onRejectBrandDelete(): void {
    this.pendingConfirmationToClear = false;
  }
  
  public onConfirmDelete(): void {
    this.resetFilter();
    this.pendingConfirmationToClear = false;  
  }

  resetFilter(): any {
    this.filtersValueModified.emit(
      this.filtersValueService.updateItemInFilters(FilterNameEnum.brands, [])
    );
    this.sharedService.showBrandPrefSaveIcon$.next(false);
    this.sharedService.isBrandPreferenceValChanged$.next(false);
  }

  get isResetButtonVisible(): boolean {
    return !!this.selectedItems.length;
  }

  changefilterValues(filtersValue: FiltersObject): void {
    this.filtersValueService.filterValues$.next(filtersValue);
  }

}
