import { AnalogSelectionFilterInterface, FilterAnalogCombinationEnum, FilterLOTEnum } from './interfaces';
import {
  FilterItemValueInterface,
  FilterMeasuresPeriodEnum,
  FilterMeasuresPriceCategoryEnum,
  FilterMeasuresPriceTypeEnum,
  FilterProductParamsBrandedStatusEnum,
  FilterProductParamsOrphanStatusEnum,
  FilterProductParamsReimbursementEnum,
  FilterMeasuresCOTTypeEnum,
  FilterCotLevelsEnum
} from '../../datastore/interfaces';
import { FilterAutocompleteDescriptionInterface } from '../../../uikit/filter-autocomplete-with-chips/interfaces';

export const description = {
  emptyState: 'No results found',
  placeholder: 'Start typing to select',
  errorMessage: 'Error'
};

export const descriptionsTLI: FilterAutocompleteDescriptionInterface = {
  title: 'Indications',
  emptyState: 'No results found',
  placeholder: 'Start typing to select',
  errorMessage: 'Invalid TLI'
};

export const descriptionsTherapyArea: FilterAutocompleteDescriptionInterface = {
  title: 'Therapy area',
  ...description
};

export const descriptionsMOA: FilterAutocompleteDescriptionInterface = {
  title: 'MOA',
  ...description
};

export const descriptionsRoa: FilterAutocompleteDescriptionInterface = {
  title: 'ROA',
  ...description
};

export const descriptionsATC: FilterAutocompleteDescriptionInterface = {
  title: 'ATC class',
  emptyState: 'No results found',
  placeholder: 'Start typing to select',
  errorMessage: 'Invalid ATC code'
};

export const descriptionsCountries: FilterAutocompleteDescriptionInterface = {
  title: 'Geographic availability',
  emptyState: 'No results found',
  placeholder: 'Start typing to select',
  errorMessage: 'At least one country should be selected to display information'
};

export const pricePerItems: FilterItemValueInterface[] = [
  { name: 'Month', type: FilterMeasuresPeriodEnum.month },
  { name: 'Annum', type: FilterMeasuresPeriodEnum.annum },
  { name: 'Day', type: FilterMeasuresPeriodEnum.day },
];

export const periodPerItems: FilterItemValueInterface[] = [
  { name: 'Annum', type: FilterMeasuresPeriodEnum.annum },
  { name: 'Month', type: FilterMeasuresPeriodEnum.month },
  { name: 'Day', type: FilterMeasuresPeriodEnum.day },
  { name: 'Cycle', type: FilterMeasuresPeriodEnum.cycle },
  { name: 'DDD (Defined Daily Dose)', type: FilterMeasuresPeriodEnum.ddd },
  { name: 'Pack', type: FilterMeasuresPeriodEnum.pack },
  { name: 'MG', type: FilterMeasuresPeriodEnum.mg },
  { name: 'Unit', type: FilterMeasuresPeriodEnum.unit },
  { name: 'PFS', type: FilterMeasuresPeriodEnum.pfs },
];

export const prevalenceBucketItems: FilterItemValueInterface[] = [
  { name: 'Include all', type: 'all' },
  { name: '1 in 100', type: '1 in 100' },
  { name: '1 in 1,000', type: '1 in 1,000' },
  { name: '1 in 10,000', type: '1 in 10,000' },
  { name: '1 in 100,000', type: '1 in 100,000' },
];

export const reimbursementItems: FilterItemValueInterface[] = [
  { name: 'Include All', type: FilterProductParamsReimbursementEnum.all },
  { name: 'Reimbursed', type: FilterProductParamsReimbursementEnum.reimbursed },
  { name: 'Reimbursed (including with restrictions)', type: FilterProductParamsReimbursementEnum.reimbursedWithRestrictions },
  { name: 'Not Reimbursed', type: FilterProductParamsReimbursementEnum.notReimbursed },
];

export const orphanStatusItems: FilterItemValueInterface[] = [
  { name: 'Include all', type: FilterProductParamsOrphanStatusEnum.all },
  { name: 'Orphan Only', type: FilterProductParamsOrphanStatusEnum.orphan },
  { name: 'Non-orphan', type: FilterProductParamsOrphanStatusEnum.nonOrphan },
];

export const brandedStatusItems: FilterItemValueInterface[] = [
  { name: 'Include all', type: FilterProductParamsBrandedStatusEnum.all },
  { name: 'Branded', type: FilterProductParamsBrandedStatusEnum.branded },
  { name: 'Generic', type: FilterProductParamsBrandedStatusEnum.generic },
  { name: 'Biosimilar', type: FilterProductParamsBrandedStatusEnum.biosimilar },
];

export const LOTItems: FilterItemValueInterface[] = [
  { name: 'Include all', type: FilterLOTEnum.all },
  { name: '1st +', type: FilterLOTEnum.first },
  { name: '2nd +', type: FilterLOTEnum.second },
  { name: '3rd +', type: FilterLOTEnum.third },
  { name: 'Adjuvant', type: FilterLOTEnum.adjuvant },
  { name: 'Diagnostic', type: FilterLOTEnum.diagnostic },
  { name: 'Palliative', type: FilterLOTEnum.palliative },
  { name: 'Preventive', type: FilterLOTEnum.preventive },
  { name: 'Other/unspecified', type: FilterLOTEnum.otherUnspecified },
];

export const combinationItems: FilterItemValueInterface[] = [
  { name: 'Include all', type: FilterAnalogCombinationEnum.all },
  { name: 'Combined', type: FilterAnalogCombinationEnum.combined },
  { name: 'Not combined', type: FilterAnalogCombinationEnum.notCombined },
];

export const measuresShowCOT: FilterItemValueInterface[] = [
  { name: 'Adult', type: FilterMeasuresCOTTypeEnum.adult },
  { name: 'Children (Paediatric)', type: FilterMeasuresCOTTypeEnum.paediatric },
];

export const measuresCalculateParametersItems: FilterItemValueInterface[] = [
  { name: 'Include wastage', type: true },
  { name: 'Exclude wastage', type: false },
];

export const measuresPriceCategoryItems: FilterItemValueInterface[] = [
  { name: 'List', type: FilterMeasuresPriceCategoryEnum.List },
  { name: 'Visible Net', type: FilterMeasuresPriceCategoryEnum.VisibleNet },
  { name: 'Less Discount', type: FilterMeasuresPriceCategoryEnum.LessDiscount },
];

export const measuresPriceTypeItemsNoPPincVAT: FilterItemValueInterface[] = [
  { name: 'Price hierarchy', type: FilterMeasuresPriceTypeEnum.hierarchy },
  { name: 'Ex factory price', type: FilterMeasuresPriceTypeEnum.exfactoryprice },
  { name: 'Hospital price', type: FilterMeasuresPriceTypeEnum.hospitalprice },
  { name: 'Pharmacy purchase price (PPP)', type: FilterMeasuresPriceTypeEnum.ppp },
  { name: 'Public price (PP) ex VAT', type: FilterMeasuresPriceTypeEnum.ppexvat },
];

export const measuresPriceTypeItems: FilterItemValueInterface[] = [
  ...measuresPriceTypeItemsNoPPincVAT,
  { name: 'Public price (PP) inc VAT', type: FilterMeasuresPriceTypeEnum.ppincvat },
];

export const measuresPriceTypeItemsExtended: FilterItemValueInterface[] = [
  { name: 'Price hierarchy', type: FilterMeasuresPriceTypeEnum.hierarchy },
  ...measuresPriceTypeItemsNoPPincVAT
];


export const ProjectAnalogCountriesLimitList = ['England', 'USA', 'Germany', 'South Korea', 'Spain', 'Norway', 'Sweden', 'France', 'Italy', 'Japan'];

export const AnalogSelectionFilterDefaultValue: AnalogSelectionFilterInterface = {
  topLevelIndication: '',
  therapyArea: '',
  moa: '',
  mahDateFrom: null,
  mahDateTo: null,
  prevalenceBucket: 'all',
  pricePer: FilterMeasuresPeriodEnum.month,
  priceMin: null,
  priceMax: null,
  reimbursementStatus: FilterProductParamsReimbursementEnum.all,
  orphanStatus: FilterProductParamsOrphanStatusEnum.all,
  brandedStatus: FilterProductParamsBrandedStatusEnum.all,
  roa: '',
  atcCodes: [],
  lineOfTherapy: FilterLOTEnum.all,
  comparator: false,
  combination: FilterAnalogCombinationEnum.all,
  countries: ProjectAnalogCountriesLimitList
};

export const cotLevelItems: FilterItemValueInterface[] = [
  { name: 'Highest', type: FilterCotLevelsEnum.highest },
  { name: 'Median', type: FilterCotLevelsEnum.median },
  { name: 'Lowest', type: FilterCotLevelsEnum.lowest },
];