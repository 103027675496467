import { AccessPagesEnum } from './../common/enums';
import { BrandsAvailabilityService } from './brands-availability-service/brands-availability.service';
import { CacheExportService } from './cache-export-service.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GeneralInfoAsyncLoadService,
  GeneralInfoComponent,
} from './general-info';
import { IndicationMatrixComponent } from './indication-matrix';
import { CotComparisonComponent } from './cot-comparison';
import { CotCorridorComponent, CotCorridorFetchPipe } from './cot-corridor';
import { CotHistoryComponent } from './cot-history';
import { FormulationAndPackDetailsComponent } from './formulation-and-pack-details';
import { PackPriceComponent } from './pack-price';
import { BrandsAvailiabilityComponent } from './brands-availiability';
import { ReimbursementHtaComponent } from './reimbursement-hta/reimbursement-hta.component';
import { MobileDrawer } from '../layout/drawer';
import { FiltersValueService } from './filters-value.service';
import { Observable, Subject, lastValueFrom, of, timer } from 'rxjs';
import { TawkChatService } from '../chat/tawk-chat.service';
import { ReimbursementMapComponent } from './reimbursement-map';
import { ReimbursementTimeComponent } from './reimbursement-time';
import { PageTitleComponent } from './page-title';
import { IndicationTabsComponent } from './indication-tabs';
import { PackDetailsComponent } from './pack-details/pack-details.component';
import { JourneyDetailsComponent } from './journey-details/journey-details.component';
import { JourneyHistoryComponent } from './journey-history/journey-history.component';
import { MultipleImageExport } from './multiple-image-export.service';
import { ExportCsv } from '../top-nav/export-csv.service';
import { MultipleCsvExport } from '../top-nav/multiple-csv-export.service';
import { ExportCsvObject } from '../top-nav/export-csv.object.service';
import { AppInsightsService } from '../app-insights/app-insights-logging.service';
import {
  CachedAccessPermissions,
  PermissionsResponse,
} from '../../core/access-permissions';
import { AccessBarrierService } from '../access-barrier';
import { SharedService } from '../../uikit/service/shared.service';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  takeUntil,
} from 'rxjs/operators';
import {
  CountryNames,
  HospitalReimbCountries,
  InfoButtonText,
} from '../../app/common/constants';
import { GridViewCustomAPIService } from './gridviewcustom-api.service';
import { FetchGridViewColumnsCustomPipe } from './gridviewcustom-fetchColumns.pipe copy';
import {
  GridViewTypeEnum,
  RequestTypeEnum,
  HTAColumnNameEnum,
} from '../../uikit/filters/enum';
import { FetchGridViewCustomPipe } from './gridviewcustom-fetch.pipe';
import {
  gridViewCustomColumnPnR,
  GridViewCustomColumn,
  gridViewCustomColumnCoT,
  gridViewCustomColumnHTA,
  gridViewCustomColumnPnRHistory,
} from './gridViewCustomColumnList';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PackDetailsPipe } from './pack-details/pack-details.pipe';
import { PackDetailsFormattingPipe } from './pack-details/pack-details-formatting.pipe';
import {
  UserPreferenceTypes,
  UserPreferences,
} from '../../core/user-preferences';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import {
  GRID_MODE_EXPANSION,
  SOC_INDEX,
  COT_CAL_BUTTON
} from '../feature-flag/feature-flag-constants';
import { ReimbursementComparatorComponent } from './reimbursement-comparator/reimbursement-comparator.component';
import { CustomColumnListOutput, FilterNameEnum } from './interfaces';
import { MatDialog } from '@angular/material/dialog';
import { CotCalculatorComponent } from './cot-calculator/cot-calculator/cot-calculator.component';
import {
  CotCorridorCacheService,
} from './cot-cache/cot-cache.service';
import { ProjectTypes } from '../../core/project-details/project';
import { FilterBrandPresetTypes, FilterPresetService } from './filters/filter-preset';

type SectionName =
  | 'general'
  | 'cot'
  | 'price'
  | 'reimbursement'
  | 'journey'
  | undefined;

@Component({
  selector: 'he-datastore',
  templateUrl: './datastore.component.html',
  styleUrls: ['./datastore.component.scss'],
  providers: [
    FiltersValueService,
    MultipleImageExport,
    MultipleCsvExport,
    FetchGridViewColumnsCustomPipe,
    FetchGridViewCustomPipe,
    { provide: AccessBarrierService, useExisting: CachedAccessPermissions },
  ],
})
export class DatastoreComponent implements OnInit, OnDestroy {
  GeneralInfo: Promise<GeneralInfoComponent> | Promise<any> | undefined;
  IndicationMatrix:
    | Promise<IndicationMatrixComponent>
    | Promise<any>
    | undefined;
  CoTComparison: Promise<CotComparisonComponent> | Promise<any> | undefined;
  CoTCorridor: Promise<CotCorridorComponent> | Promise<any> | undefined;
  CoTHistory: Promise<CotHistoryComponent> | Promise<any> | undefined;
  FormulationAndPackDetails:
    | Promise<FormulationAndPackDetailsComponent>
    | Promise<any>
    | undefined;
  PackPrice: Promise<PackPriceComponent> | Promise<any> | undefined;
  ReimbursementMap:
    | Promise<ReimbursementMapComponent>
    | Promise<any>
    | undefined;
  AvailabilityMap:
    | Promise<BrandsAvailiabilityComponent>
    | Promise<any>
    | undefined;
  PageTitle: Promise<PageTitleComponent> | Promise<any> | undefined;
  IndicationTabs: Promise<IndicationTabsComponent> | Promise<any> | undefined;
  ReimbursementHta:
    | Promise<ReimbursementHtaComponent>
    | Promise<any>
    | undefined;
  ReimbursementTime:
    | Promise<ReimbursementTimeComponent>
    | Promise<any>
    | undefined;
  ReimbursementComparator:
    | Promise<ReimbursementComparatorComponent>
    | Promise<any>
    | undefined;
  IrpPrice: Promise<ReimbursementTimeComponent> | Promise<any> | undefined;
  PackDetails: Promise<PackDetailsComponent> | Promise<any> | undefined;
  JourneyDetails: Promise<JourneyDetailsComponent> | Promise<any> | undefined;
  JourneyHistory: Promise<JourneyHistoryComponent> | Promise<any> | undefined;

  public formgroup: FormGroup;
  public CotCalculatorData: {
    preview: boolean;
    save: boolean;
    data: any[];
    selectedCountries: [];
    selectedBrands: [];
  } = {
    preview: false,
    save: false,
    data: [],
    selectedCountries: [],
    selectedBrands: [],
  };
  tabname: string = '';
  filterPanel = {
    visibility: true,
  };

  modes = {
    loading: 'loading',
    chart: 'chart',
    table: 'table',
  };
  mode = 'loading';

  isPackDetailsComponentLoaded: boolean = false;
  showButtons: boolean = false;

  activeSection: SectionName = 'general';
  accessBarrierService: AccessBarrierService | undefined;

  CotTab = AccessPagesEnum.cot;
  PriceTab = AccessPagesEnum.price;
  ReimAndHTATab = AccessPagesEnum.reimAndHTA;
  JourneyDetailsTab = AccessPagesEnum.journeyDetails;
  GeneralInformationTab = AccessPagesEnum.generalInformation;
  isPackDescriptionSelected: boolean = false;
  isIRPPermission: boolean = false;

  permission = false;
  flag = 'chart';
  private permissionList: PermissionsResponse[] = [];
  isGridModeDisabled = false;
  isWidgetModeDisabled = false;
  widgetModeTooltip = '';
  tabDisabledTooltip = 'Please contact support@globalpricing.com for more information';
  isCountryScopeSelected = false;
  public infoButtonText!: string;
  isTabDisabled = false;
  public showLoadingOverlay = false;
  noWidgetModePagesAccess = false;

  private readonly destroying$: Subject<void> = new Subject<void>();
  maxCotWidgetModeBrandsExceeded = false;
  selectcolumnList: string[] = [];
  unselectcolumnList: string[] = [];
  listOfAllColumnDetails: GridViewCustomColumn[] = [];
  isSavedisable: boolean = true;
  datasetpack: any[] = [];
  datasetcot: any[] = [];
  datasethta: any[] = [];
  public checkForMaxFiveHundredFlag = true;
  userPreferencesData: any;
  gridmodesortColumn: string='';
  gridmodesortType:number=0;
  gridmodetotRecortCount:number=0;

  pnrReimbColumnExist:boolean=false;
  htaReimbColumnExist:boolean=false;
  isPrelaunchdataExistinCOT: boolean=false;
  isPrelaunchdataExistinPNR: boolean=false;
  GRID_MODE_EXPANSION = GRID_MODE_EXPANSION;
  SOC_INDEX = SOC_INDEX;
  COT_CAL_BUTTON = COT_CAL_BUTTON;
  AccessPagesEnum = AccessPagesEnum;
  private featureFlagRoutingToGrid: boolean = false;

  isExportCurrentPageDownloading: boolean = false;
  isExportCurrentPageDisabled: boolean = false;
  exportCurrentPageRowsCount: number = 0;

  isExportAllPagesDownloading: boolean = false;
  isExportAllPagesDisabled: boolean = false;
  exportAllPagesRowsCount: number = 0;
  cotIdList:any[]=[];
  queryParams: any;
  header:string="Please name your COT project"
  buttonText:string="Create"
  totalTabPermission: number = 0;
  projectDetails:any;
  initGridViewValues: { initialTabName: any; initialTabIndex: any; initialListOfColumnDetails: any; } | null = null;
  get exportRowsMaxLimit(): number {
    return 20000;
  }
  get exportErrorMessage(): string {
    return `Select less than ${this.exportRowsMaxLimit} records to export data in .csv or Please contact support@globalpricing.com for more information.`;
  }

  // COT Calculator Preview Export Varaibles
  private readonly cotCalPrvExpColName: string = 'Pulse Comments';
  private readonly cotCalPrvExpColMsgForCreated: string =
    'Created with cost of treatment calculator';
  // COT Calculator Preview Export Varaibles END
  private readonly cotCorridorExpColMsgForCreated: string =
  'Pack used for Cost of Treatment visualization';
  private readonly cotCorridorApprovedMsgForCreated: string =
  'Approved availability status refers to a Regulatory Approved But Commercially Not Available Product';
  projectDraft: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private tawkService: TawkChatService,
    @Inject(MobileDrawer) public drawer: any,
    private multipleExportImage: MultipleImageExport,
    private multipleExportCsv: MultipleCsvExport,
    private exportCsv: ExportCsv,
    private exportCsvObj: ExportCsvObject,
    public appInsightsService: AppInsightsService,
    public filters: FiltersValueService,
    public accessPermissions: CachedAccessPermissions,
    public sharedService: SharedService,
    public asyncLoadService: GeneralInfoAsyncLoadService,
    private cacheExportService: CacheExportService,
    private fetchGridViewColumnsCustomPipe: FetchGridViewColumnsCustomPipe,
    private fetchGridViewCustomPipe: FetchGridViewCustomPipe,
    public gridViewCustomAPIService: GridViewCustomAPIService,
    private fb: FormBuilder,
    private packDetailsPipe: PackDetailsPipe,
    private packDetailsFormattingPipe: PackDetailsFormattingPipe,
    private brandsAvailabilityService: BrandsAvailabilityService,
    private userPreferences: UserPreferences,
    private featureFlagService: FeatureFlagService,
    public dialog: MatDialog,
    public cotCacheService: CotCorridorCacheService,
    private filterPresetService: FilterPresetService,
  ) {
    // enable typecheck
    this.accessBarrierService = accessPermissions;
    this.sharedService?.gridToggleValue$.subscribe((isGridMode: boolean) => {
      
      if (this.flag === 'grid') {
        this.onUseTable(this.sharedService.datastoreTabSelectedIndex$.value);
      } else {
        this.checkRoutingToGridModeFeatureFlag();
      }
    });

    // export type
    this.formgroup = this.fb.group({
      showAllColumns: ['false'],
    });
    this.brandsAvailabilityService.initService(filters, this.destroying$);
  }

  ngOnInit(): void {
    this.filters.castCotIdList.subscribe(res=>{
      this.cotIdList = res;
    })
    this.sharedService.datastoreShowHistory$.next(false);

    this.filters.disabledForMaxBrandLimit$
      .pipe(takeUntil(this.destroying$))
      .subscribe((data) => {
        this.isTabDisabled = data;
        if (this.isSectionActive('journey') && this.isTabDisabled) {
          this.onSection('general');
        }
      });

    this.sharedService.gridmodetotRecortCount$
      .pipe(takeUntil(this.destroying$))
      .subscribe((rowsCount: number) => {
        this.exportCurrentPageRowsCount = 0;
        this.addAndValidateExportCurrentPage(rowsCount);
      });

    this.checkPermissions();

    // this.subscribeDatastoreShowHistory();
    //this.activeSection = this.route.snapshot.params.section;
    //this.sharedService.activeSectionName$.next(this.activeSection || '');
    this.tawkService.load();
    this.GeneralInfo = this.GeneralInfo || this.fetchGeneralInfoComponent();
    this.IndicationMatrix =
      this.IndicationMatrix || this.fetchIndicationMatrixComponent();
    this.CoTComparison =
      this.CoTComparison || this.fetchCoTComparisonComponent();
    this.CoTCorridor = this.CoTCorridor || this.fetchCoTCorridorComponent();
    this.CoTHistory = this.CoTHistory || this.fetchCoTHistoryComponent();
    this.FormulationAndPackDetails =
      this.FormulationAndPackDetails ||
      this.fetchFormulationAndPackDetailsComponent();
    this.PackPrice = this.PackPrice || this.fetchPackPrice();
    this.AvailabilityMap = this.AvailabilityMap || this.fetchAvailabilityMap();
    this.PageTitle = this.PageTitle || this.fetchPageTitle();
    this.IndicationTabs = this.IndicationTabs || this.fetchIndicationTabs();
    this.ReimbursementMap =
      this.ReimbursementMap || this.fetchReimbursementMap();
    this.ReimbursementHta =
      this.ReimbursementHta || this.fetchReimbursementHta();
    this.ReimbursementTime =
      this.ReimbursementTime || this.fetchReimbursementTime();
    this.ReimbursementComparator =
      this.ReimbursementComparator || this.fetchReimbursementComparator();
    this.JourneyDetails = this.JourneyDetails || this.fetchJourneyDetails();
    this.JourneyHistory = this.JourneyHistory || this.fetchJourneyHistory();

    this.appInsightsService.logPageView('Data Store Page');
    this.subscribeAllCountriesScopeSelected();
    this.subscribeMaxCotWidgetModeBrandsExceeded();
    this.infoButtonText = InfoButtonText.GeneralInfo;
   
    if (this.sharedService.datastoreLoadingScreenVisibility$.value)
      this.sharedService.setShowLoadingScreen(false);

    this.subscribeHideColumn();
    this.subscribeShowHistory();
    this.subscribeQueryParams();
    this.subscribePrelaunchNotes();
  }

  private getInitialGridViewValues(): { initialTabName: any; initialTabIndex: any; initialListOfColumnDetails: any; } | null {
    if(this.isPermissionActive(AccessPagesEnum.gridViewPnR)) {
      return {
        initialTabName: GridViewTypeEnum.packprice,
        initialTabIndex: RequestTypeEnum.PnR,
        initialListOfColumnDetails: this.sharedService.datastoreShowHistory$.value
          ? gridViewCustomColumnPnRHistory
          : gridViewCustomColumnPnR
      };
    }
    else if(this.isPermissionActive(AccessPagesEnum.gridViewCOT)) {
      return {
        initialTabName: GridViewTypeEnum.indicationcot,
        initialTabIndex: RequestTypeEnum.CoT,
        initialListOfColumnDetails: gridViewCustomColumnCoT
      };
    }
    else if(this.isPermissionActive(AccessPagesEnum.gridViewHTA)) {
      return {
        initialTabName: GridViewTypeEnum.hta,
        initialTabIndex: RequestTypeEnum.HTA,
        initialListOfColumnDetails: gridViewCustomColumnHTA
      };
    }

    return null;
  }

  async checkPermissions() {
    const accessibleRoute = await this.accessPermissions
    .run({ payload: null })
    .toPromise();
    this.permissionList = accessibleRoute?.payload as [];

    this.initGridViewValues = this.getInitialGridViewValues();
    if(this.initGridViewValues)
    {
      this.sharedService.datastoreTabName$.next(this.initGridViewValues.initialTabName);
      this.sharedService.datastoreTabSelectedIndex$.next(this.initGridViewValues.initialTabIndex);
      this.listOfAllColumnDetails = this.initGridViewValues.initialListOfColumnDetails;
      this.tabname = this.initGridViewValues.initialTabName;
    }

    const downloads = this.isPermissionActive(AccessPagesEnum.downloads);
    if (downloads) {
      this.permission = true;
    }

    if (this.isPermissionActive(AccessPagesEnum.generalInformation)) {
      this.onSection('general');
    }
    else if (this.isPermissionActive(AccessPagesEnum.cot)) {
      this.onSection('cot');
    }
    else if (this.isPermissionActive(AccessPagesEnum.price)) {
      this.onSection('price');
    }
    else if (this.isPermissionActive(AccessPagesEnum.reimAndHTA)) {
      this.onSection('reimbursement');
    }
    else if (this.isPermissionActive(AccessPagesEnum.journeyDetails)) {
      this.onSection('journey');
    }

    if (this.isPermissionActive(AccessPagesEnum.irp)) {
      this.IrpPrice = this.IrpPrice || this.fetchIrpPrice();
    }

    if (!this.isPermissionActive(AccessPagesEnum.gridViewCOT) &&
      !this.isPermissionActive(AccessPagesEnum.gridViewHTA) &&
      !this.isPermissionActive(AccessPagesEnum.gridViewPnR)) {
        this.isGridModeDisabled = true
    }
    if(!this.isPermissionActive(AccessPagesEnum.generalInformation) &&
      !this.isPermissionActive(AccessPagesEnum.cot) &&
      !this.isPermissionActive(AccessPagesEnum.price) &&
      !this.isPermissionActive(AccessPagesEnum.reimAndHTA) &&
      !this.isPermissionActive(AccessPagesEnum.journeyDetails))
    {
      this.noWidgetModePagesAccess = true;
      this.checkWidgetModeDisabled()
      if(!this.isGridModeDisabled)
        this.onUseTable(this.initGridViewValues ? this.initGridViewValues.initialTabIndex : this.sharedService.datastoreTabSelectedIndex$.value);
    }

    this.totalTabPermission = this.permissionList.filter(item  => [AccessPagesEnum.gridViewCOT.toLowerCase(), AccessPagesEnum.gridViewPnR.toLowerCase(), AccessPagesEnum.gridViewHTA.toLowerCase()].includes(item.code.toLowerCase())).length;

  }

  subscribeQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.destroying$)).subscribe((params) => {
      this.queryParams = params
    })
    this.filterPresetService.initService(this.destroying$)
    if(this.queryParams.t===FilterBrandPresetTypes.Brand)
    {
      this.sharedService.selectedSearchValue$.next(this.queryParams.s);
      this.sharedService.IsBrandPrefSelectedInMainSearch$.next(true);
    }
    else
    {
      this.sharedService.selectedSearchValue$.next('');
      this.sharedService.IsBrandPrefSelectedInMainSearch$.next(false);
    }
   
  }

  isPermissionActive(data:string): boolean {
    return !!this.permissionList.find(
      (item) => item.code.toLowerCase() === data.toLowerCase()
    );
  }

  onDeclineNewProject() : void {
    this.projectDraft = undefined
  }

  onSubmitNewProject() : void {
      this.projectDraft = undefined
      this.showButtons = false;
      this.CotCalculatorData = {
        preview: false,
        save: false,
        data:[],
        selectedCountries: [],
        selectedBrands: [],
      }

  }

  saveProject(): void {
    this.header=this.projectDetails ?"Save COT Project Changes":"Please name your COT project"
    this.buttonText=this.projectDetails ?"Save":"Create"

    this.projectDraft = {
      content: JSON.stringify({
        routing: {
          queryParams: this.queryParams
        },
        filters: this.filters.filterValues$.value
      }),
      id:this.projectDetails ?this.projectDetails.id:null ,
      description:this.projectDetails ?this.projectDetails.description:'',
      name:this.projectDetails ?this.projectDetails.name:'' ,
      type: ProjectTypes.Insights
    };
  }

  save(): void {
    this.multipleExportImage.saveImage();
    this.multipleExportCsv.saveAllCSV();
    this.exportCsvObj.exportToCsv();
    if (this.maxCotWidgetModeBrandsExceeded) {
      this.cacheExportService.exportToCsv();
    }
  }

  saveCsv(): void {
    this.sharedService.datastoreExportAll$.next(false);
    this.sharedService.gridmodePageNumber$.next(-1);
    this.saveIndividualCsv();
  }

  saveIndividualCsv(): void {
    this.userPreferences
      .run({
        payload: Object.keys(GridViewTypeEnum).map(
          (k) => k + UserPreferenceTypes.ColumnOrder
        ),
      })
      .pipe(takeUntil(this.destroying$))
      .subscribe((userPreferenceResponse) => {
        if (
          userPreferenceResponse.type != 'done' ||
          userPreferenceResponse.error ||
          !userPreferenceResponse.payload
        )
          return;

        this.userPreferencesData = userPreferenceResponse.payload as any;

        if (this.sharedService.datastoreTabSelectedIndex$.value == 0) {
          this.sharedService.datastoreExcelSheet$.next(0);
          this.packDetailsPipe
            .transform(
              this.filters.filterValues$.value,
              this.sharedService.datastoreShowHistory$.value
            )
            .pipe(takeUntil(this.destroying$))
            .subscribe((value: any) => {
              if (value?.type === 'done') {
                this.datasetpack = value.payload;
                this.datasetpack = this.packDetailsFormattingPipe.transform(
                  this.datasetpack,
                  this.sharedService.datastoreExcelSheet$.value
                );
                this.datasetpack = this.sharedService.pnrDataload(
                  this.datasetpack,
                  true
                );

                let sortedGridViewCustomColumnPnRHistory = JSON.parse(
                  JSON.stringify(gridViewCustomColumnPnRHistory)
                ) as typeof gridViewCustomColumnPnRHistory;
                this.userPreferencesData
                  .filter(
                    (p: any) =>
                      p.userPreference.type ===
                      GridViewTypeEnum.packprice +
                        UserPreferenceTypes.ColumnOrder
                  )
                  .forEach((element: any) => {
                    let columnDef = sortedGridViewCustomColumnPnRHistory.filter(
                      (c) =>
                        c.field.toLowerCase() ===
                        element.userPreference.preference.toLowerCase()
                    );

                    if (columnDef.length > 0) columnDef[0].index = element.idx;
                  });
                sortedGridViewCustomColumnPnRHistory =
                  sortedGridViewCustomColumnPnRHistory.sort(
                    (n1, n2) => n1.index - n2.index
                  );

                this.selectedColumnList(
                  GridViewTypeEnum.packprice,
                  this.datasetpack,
                  sortedGridViewCustomColumnPnRHistory
                )
                  .pipe(takeUntil(this.destroying$))
                  .subscribe(() => {
                    this.datasetpack = this.rowDataFormat;
                    this.exportCsv.saveSingleCSV(
                      this.datasetpack,
                      0,
                      this.sharedService.datastoreSignPostValPnR$.value,
                      this.sharedService.datastoreSignPostValPnRGermany$.value,
                      this.sharedService.datastoreSignPostValHTAChina$.value,
                      this.sharedService.datastoreSignPostValHTA$.value,
                      this.pnrReimbColumnExist,
                      this.htaReimbColumnExist,
                      this.sharedService.isGermanyExist$.value,
                      this.sharedService.isChinaExist$.value
                    );
                  });
              }
            });
        } else if (this.sharedService.datastoreTabSelectedIndex$.value == 1) {
          this.sharedService.datastoreExcelSheet$.next(1);
          this.packDetailsPipe
            .transform(
              this.filters.filterValues$.value,
              this.sharedService.datastoreShowHistory$.value
            )
            .pipe(takeUntil(this.destroying$))
            .subscribe((valueCOT: any) => {
              if (valueCOT?.type === 'done') {
                this.datasetcot = valueCOT.payload;
                this.datasetcot = this.packDetailsFormattingPipe.transform(
                  this.datasetcot,
                  this.sharedService.datastoreExcelSheet$.value
                );
                this.datasetcot = this.sharedService.cotdataload(
                  this.datasetcot,
                  true
                );

                let sortedGridViewCustomColumnCoT = JSON.parse(
                  JSON.stringify(gridViewCustomColumnCoT)
                ) as typeof gridViewCustomColumnCoT;
                this.userPreferencesData
                  .filter(
                    (p: any) =>
                      p.userPreference.type ===
                      GridViewTypeEnum.indicationcot +
                        UserPreferenceTypes.ColumnOrder
                  )
                  .forEach((element: any) => {
                    let columnDef = sortedGridViewCustomColumnCoT.filter(
                      (c) =>
                        c.field.toLowerCase() ===
                        element.userPreference.preference.toLowerCase()
                    );
                    if (columnDef.length > 0) columnDef[0].index = element.idx;
                  });
                sortedGridViewCustomColumnCoT =
                  sortedGridViewCustomColumnCoT.sort(
                    (n1, n2) => n1.index - n2.index
                  );

                this.selectedColumnList(
                  GridViewTypeEnum.indicationcot,
                  this.datasetcot,
                  sortedGridViewCustomColumnCoT
                )
                  .pipe(takeUntil(this.destroying$))
                  .subscribe((rowDataFormat: any[]) => {
                    this.datasetcot = rowDataFormat;
                    this.exportCsv.saveSingleCSV(
                      this.datasetcot,
                      1,
                      this.sharedService.datastoreSignPostValPnR$.value,
                      this.sharedService.datastoreSignPostValPnRGermany$.value,
                      this.sharedService.datastoreSignPostValHTAChina$.value,
                      this.sharedService.datastoreSignPostValHTA$.value,
                      this.pnrReimbColumnExist,
                      this.htaReimbColumnExist,
                      this.sharedService.isGermanyExist$.value,
                      this.sharedService.isChinaExist$.value
                    );
                  });
              }
            });
        } else if (this.sharedService.datastoreTabSelectedIndex$.value == 2) {
          //hta
          this.sharedService.datastoreExcelSheet$.next(2);
          this.packDetailsPipe
            .transform(
              this.filters.filterValues$.value,
              this.sharedService.datastoreShowHistory$.value
            )
            .pipe(takeUntil(this.destroying$))
            .subscribe((valueHTA: any) => {
              if (valueHTA?.type === 'done') {
                this.datasethta = valueHTA.payload;
                this.datasethta = this.packDetailsFormattingPipe.transform(
                  this.datasethta,
                  this.sharedService.datastoreExcelSheet$.value
                );
                this.datasethta = this.sharedService.Htadataload(
                  this.datasethta,
                  true
                );

                let sortedGridViewCustomColumnHTA = JSON.parse(
                  JSON.stringify(gridViewCustomColumnHTA)
                ) as typeof gridViewCustomColumnHTA;
                this.userPreferencesData
                  .filter(
                    (p: any) =>
                      p.userPreference.type ===
                      GridViewTypeEnum.hta + UserPreferenceTypes.ColumnOrder
                  )
                  .forEach((element: any) => {
                    let columnDef = sortedGridViewCustomColumnHTA.filter(
                      (c) =>
                        c.field.toLowerCase() ===
                        element.userPreference.preference.toLowerCase()
                    );
                    if (columnDef.length > 0) columnDef[0].index = element.idx;
                  });
                sortedGridViewCustomColumnHTA =
                  sortedGridViewCustomColumnHTA.sort(
                    (n1, n2) => n1.index - n2.index
                  );

                this.selectedColumnList(
                  GridViewTypeEnum.hta,
                  this.datasethta,
                  sortedGridViewCustomColumnHTA
                )
                  .pipe(takeUntil(this.destroying$))
                  .subscribe(() => {
                    this.datasethta = this.rowDataFormat;
                    this.exportCsv.saveSingleCSV(
                      this.datasethta,
                      2,
                      this.sharedService.datastoreSignPostValPnR$.value,
                      this.sharedService.datastoreSignPostValPnRGermany$.value,
                      this.sharedService.datastoreSignPostValHTAChina$.value,
                      this.sharedService.datastoreSignPostValHTA$.value,
                      this.pnrReimbColumnExist,
                      this.htaReimbColumnExist,
                      this.sharedService.isGermanyExist$.value,
                      this.sharedService.isChinaExist$.value
                    );
                  });
              }
            });
        }
      });
  }

  rowDataFormat: any[] = [];
  selCols: any[] = [];
  selectedColumnList(
    tabIndex: string,
    alldata: any[],
    columnDetails: any[]
  ): Observable<any[]> {
    this.rowDataFormat = [];
    return this.fetchGridViewCustomPipe.transform(tabIndex).pipe(
      takeUntil(this.destroying$),
      filter((value) => value.type === 'done'),
      map((value: CustomColumnListOutput) => {
        if (tabIndex == GridViewTypeEnum.packprice) {
          this.pnrReimbColumnExist = value.selectedColumns.includes(
            HTAColumnNameEnum.ReimbursedClassification
          );
        } else if (tabIndex == GridViewTypeEnum.hta) {
          this.htaReimbColumnExist = value.selectedColumns.includes(
            HTAColumnNameEnum.ReimbursedClassification
          );
        }

        this.selCols = (
          value.selectedColumns +
          (tabIndex === GridViewTypeEnum.indicationcot ||  GridViewTypeEnum.packprice ? ',PulseComments' : '')
        ).split(',');
        //removing price start and end date columns from PNR default export
        if (!this.sharedService.datastoreShowHistory$.value) {
          this.selCols = this.selCols.filter(
            (item) => item !== 'PriceStartDate' && item !== 'PriceEndDate'
          );
        }
        let pulseCommentAdded: boolean = false;
        alldata.forEach((row) => {
          const keys = Object.keys(row);
          const newRow: { [key: string]: string } = {};
          columnDetails.forEach((colConfig) => {
            if (this.selCols.indexOf(colConfig.field) !== -1) {
              if (colConfig.field.toLowerCase() === 'pulsecomments') {
                if (row['isCotPreview']) {
                  newRow[this.cotCalPrvExpColName] =
                    this.cotCalPrvExpColMsgForCreated;
                  pulseCommentAdded = true;
                }
                else if (row['isCotCorridor']) {
                  newRow[this.cotCalPrvExpColName] =
                    this.cotCorridorExpColMsgForCreated;
                  pulseCommentAdded = true;
                }else if(row['packDescription'] === null){
                  newRow[this.cotCalPrvExpColName] =
                  this.cotCorridorApprovedMsgForCreated;
                pulseCommentAdded = true;
                }
                return;
              }
              for (const key of keys) {
                if (key.toLowerCase() === colConfig.field.toLowerCase()) {
                  if (key === 'country') {
                    const countryHoverTitle = row['countryHoverTitle'];
                    newRow[colConfig.header] =
                      row[key] + (countryHoverTitle ? countryHoverTitle : '');
                    break;
                  }
                  newRow[colConfig.header] = row[key];
                  break;
                }
              }
            }
          });
          this.rowDataFormat.push(newRow);
        });
        if (this.rowDataFormat.length > 0 && pulseCommentAdded && !(this.rowDataFormat[0][this.cotCalPrvExpColName])) {
          this.rowDataFormat[0][this.cotCalPrvExpColName] = "";
        }

        return this.rowDataFormat;
      })
    );
  }

  saveAllCsv(isGridPageLoad? : boolean ): void {
    this.fetchGridViewCustomPipe
      .transform(GridViewTypeEnum.packprice)
      .pipe(takeUntil(this.destroying$))
      .subscribe((value: CustomColumnListOutput) => {
        if (value.type === 'done') {
          this.sharedService.datastoreSelectedColumnPnR$.next(
            value.selectedColumns.split(',')
          );

          this.fetchGridViewCustomPipe
            .transform(GridViewTypeEnum.indicationcot)
            .pipe(takeUntil(this.destroying$))
            .subscribe((value: CustomColumnListOutput) => {
              if (value.type === 'done') {
                this.sharedService.datastoreSelectedColumnCOT$.next(
                  value.selectedColumns.split(',')
                );

                this.fetchGridViewCustomPipe
                  .transform(GridViewTypeEnum.hta)
                  .pipe(takeUntil(this.destroying$))
                  .subscribe((value: CustomColumnListOutput) => {
                    if (value.type === 'done') {
                      this.sharedService.datastoreSelectedColumnHTA$.next(
                        value.selectedColumns.split(',')
                      );

                      const isRegIndExist = value.selectedColumns
                      .split(',')
                      .includes(HTAColumnNameEnum.RegulatoryIndication)
                      ? true
                      : false;
                    this.sharedService.datastoreRegulatoryIndicationFlag$.next(
                      isRegIndExist
                    );

                      this.ExportAllCsv(isGridPageLoad);
                    }
                  });
              }
            });
        }
      });
  }

  
  ExportAllCsv(isPageLoad?: boolean ): void {
    this.sharedService.gridmodePageNumber$.next(-1);
    this.gridmodesortColumn = this.sharedService.gridmodesortColumn$.value;
    this.gridmodesortType = this.sharedService.gridmodesortType$.value;

    this.exportAllPagesRowsCount = 0;
    this.isExportAllPagesDownloading = true;
    this.userPreferences
      .run({
        payload: Object.keys(GridViewTypeEnum).map(
          (k) => k + UserPreferenceTypes.ColumnOrder
        ),
      })
      .pipe(takeUntil(this.destroying$))
      .subscribe((userPreferenceResponse) => {
        if (
          userPreferenceResponse.type != 'done' ||
          userPreferenceResponse.error ||
          !userPreferenceResponse.payload
        )
          return;

        this.userPreferencesData = userPreferenceResponse.payload as any;
        // this.sharedService.gridmodePageNumber$.next(0); //to fetch all records from backend
        this.sharedService.datastoreExportAll$.next(true); //

        if (this.sharedService.datastoreTabSelectedIndex$.value == 0) {
          this.sharedService.gridmodesortColumn$.next(this.gridmodesortColumn);
          this.sharedService.gridmodesortType$.next(this.gridmodesortType);
        } else {
          this.sharedService.gridmodesortColumn$.next('');
          this.sharedService.gridmodesortType$.next(0);
        }
        this.sharedService.datastoreExcelSheet$.next(0);

        this.packDetailsPipe
          .transform(
            this.filters.filterValues$.value,
            this.sharedService.datastoreShowHistory$.value
          )
          .pipe(takeUntil(this.destroying$))
          .subscribe((value: any) => {
            if (value?.type === 'done') {
              this.datasetpack = value.payload;
              this.gridmodetotRecortCount = this.datasetpack.length;
              //sorting current tab data if any
              //    if (this.sharedService.datastoreTabSelectedIndex$.value == RequestTypeEnum.PnR && this.sharedService.datagridData$.value.length > 0)
              //    this.datasetpack = this.sharedService.datagridData$.value;
              //  else {
              this.datasetpack = this.packDetailsFormattingPipe.transform(
                this.datasetpack,
                this.sharedService.datastoreExcelSheet$.value
              );
              this.datasetpack = this.sharedService.pnrDataload(
                this.datasetpack,
                true
              );
              //  }

              let sortedGridViewCustomColumnPnRHistory = JSON.parse(
                JSON.stringify(gridViewCustomColumnPnRHistory)
              ) as typeof gridViewCustomColumnPnRHistory;
              this.userPreferencesData
                .filter(
                  (p: any) =>
                    p.userPreference.type ===
                    GridViewTypeEnum.packprice + UserPreferenceTypes.ColumnOrder
                )
                .forEach((element: any) => {
                  let columnDef = sortedGridViewCustomColumnPnRHistory.filter(
                    (c) =>
                      c.field.toLowerCase() ===
                      element.userPreference.preference.toLowerCase()
                  );

                  if (columnDef.length > 0) columnDef[0].index = element.idx;
                });
              sortedGridViewCustomColumnPnRHistory =
                sortedGridViewCustomColumnPnRHistory.sort(
                  (n1, n2) => n1.index - n2.index
                );

              this.selectedColumnList(
                GridViewTypeEnum.packprice,
                this.datasetpack,
                sortedGridViewCustomColumnPnRHistory
              )
                .pipe(takeUntil(this.destroying$))
                .subscribe(() => {
                  this.datasetpack = this.rowDataFormat;
                  if (
                    !this.addAndValidateExportAllPages(this.datasetpack.length, this.isPermissionActive(AccessPagesEnum.gridViewPnR))
                  ) {
                    if(isPageLoad)
                      {
                      this.sharedService.gridmodePageNumber$.next(1);
                      this.callGridViewColumnDetails();
                      }
                    return;
                  }

                  //cot
                  this.sharedService.datastoreExcelSheet$.next(1);
                  if (
                    this.sharedService.datastoreTabSelectedIndex$.value == 1
                  ) {
                    this.sharedService.gridmodesortColumn$.next(
                      this.gridmodesortColumn
                    );
                    this.sharedService.gridmodesortType$.next(
                      this.gridmodesortType
                    );
                  } else {
                    this.sharedService.gridmodesortColumn$.next('');
                    this.sharedService.gridmodesortType$.next(0);
                  }

                  this.packDetailsPipe
                    .transform(
                      this.filters.filterValues$.value,
                      this.sharedService.datastoreShowHistory$.value
                    )
                    .pipe(takeUntil(this.destroying$))
                    .subscribe((valueCOT: any) => {
                      if (valueCOT?.type === 'done') {
                        this.datasetcot = valueCOT.payload;
                        //sorting if any
                        //  if (this.sharedService.datastoreTabSelectedIndex$.value == RequestTypeEnum.CoT && this.sharedService.datagridData$.value.length > 0)
                        //  this.datasetcot = this.sharedService.datagridData$.value;
                        //   else {
                        this.datasetcot =
                          this.packDetailsFormattingPipe.transform(
                            this.datasetcot,
                            this.sharedService.datastoreExcelSheet$.value
                          );
                        this.datasetcot = this.sharedService.cotdataload(
                          this.datasetcot,
                          true
                        );
                        // }
                        let sortedGridViewCustomColumnCoT = JSON.parse(
                          JSON.stringify(gridViewCustomColumnCoT)
                        ) as typeof gridViewCustomColumnCoT;
                        this.userPreferencesData
                          .filter(
                            (p: any) =>
                              p.userPreference.type ===
                              GridViewTypeEnum.indicationcot +
                                UserPreferenceTypes.ColumnOrder
                          )
                          .forEach((element: any) => {
                            let columnDef =
                              sortedGridViewCustomColumnCoT.filter(
                                (c) =>
                                  c.field.toLowerCase() ===
                                  element.userPreference.preference.toLowerCase()
                              );
                            if (columnDef.length > 0)
                              columnDef[0].index = element.idx;
                          });
                        sortedGridViewCustomColumnCoT =
                          sortedGridViewCustomColumnCoT.sort(
                            (n1, n2) => n1.index - n2.index
                          );

                        this.selectedColumnList(
                          GridViewTypeEnum.indicationcot,
                          this.datasetcot,
                          sortedGridViewCustomColumnCoT
                        )
                          .pipe(takeUntil(this.destroying$))
                          .subscribe(() => {
                            this.datasetcot = this.rowDataFormat;
                            if (
                              !this.addAndValidateExportAllPages(
                                this.datasetcot.length, this.isPermissionActive(AccessPagesEnum.gridViewCOT)
                              )
                            ) {
                              if(isPageLoad)
                                {
                                this.sharedService.gridmodePageNumber$.next(1);
                                this.callGridViewColumnDetails();
                                }
                              return;
                            }

                            //hta
                            this.sharedService.datastoreExcelSheet$.next(2);
                            if (
                              this.sharedService.datastoreTabSelectedIndex$
                                .value == 2
                            ) {
                              this.sharedService.gridmodesortColumn$.next(
                                this.gridmodesortColumn
                              );
                              this.sharedService.gridmodesortType$.next(
                                this.gridmodesortType
                              );
                            } else {
                              this.sharedService.gridmodesortColumn$.next('');
                              this.sharedService.gridmodesortType$.next(0);
                            }

                            this.packDetailsPipe
                              .transform(
                                this.filters.filterValues$.value,
                                this.sharedService.datastoreShowHistory$.value
                              )
                              .pipe(takeUntil(this.destroying$))
                              .subscribe((valueHTA: any) => {
                                if (valueHTA?.type === 'done') {
                                  this.datasethta = valueHTA.payload;
                                  //sorting if any
                                  //  if (this.sharedService.datastoreTabSelectedIndex$.value == RequestTypeEnum.HTA && this.sharedService.datagridData$.value.length > 0)
                                  //  this.datasethta = this.sharedService.datagridData$.value;
                                  //   else {
                                  this.datasethta =
                                    this.packDetailsFormattingPipe.transform(
                                      this.datasethta,
                                      this.sharedService.datastoreExcelSheet$
                                        .value
                                    );
                                  this.datasethta =
                                    this.sharedService.Htadataload(
                                      this.datasethta,
                                      true
                                    );
                                  // }

                                  let sortedGridViewCustomColumnHTA =
                                    JSON.parse(
                                      JSON.stringify(gridViewCustomColumnHTA)
                                    ) as typeof gridViewCustomColumnHTA;
                                  this.userPreferencesData
                                    .filter(
                                      (p: any) =>
                                        p.userPreference.type ===
                                        GridViewTypeEnum.hta +
                                          UserPreferenceTypes.ColumnOrder
                                    )
                                    .forEach((element: any) => {
                                      let columnDef =
                                        sortedGridViewCustomColumnHTA.filter(
                                          (c) =>
                                            c.field.toLowerCase() ===
                                            element.userPreference.preference.toLowerCase()
                                        );
                                      if (columnDef.length > 0)
                                        columnDef[0].index = element.idx;
                                    });
                                  sortedGridViewCustomColumnHTA =
                                    sortedGridViewCustomColumnHTA.sort(
                                      (n1, n2) => n1.index - n2.index
                                    );

                                  this.selectedColumnList(
                                    GridViewTypeEnum.hta,
                                    this.datasethta,
                                    sortedGridViewCustomColumnHTA
                                  )
                                    .pipe(takeUntil(this.destroying$))
                                    .subscribe(() => {
                                      this.datasethta = this.rowDataFormat;
                                      if (
                                        !this.addAndValidateExportAllPages(
                                          this.datasethta.length, this.isPermissionActive(AccessPagesEnum.gridViewHTA)
                                        )
                                      ) {
                                        if(isPageLoad)
                                        {
                                        this.sharedService.gridmodePageNumber$.next(1);
                                        this.callGridViewColumnDetails();
                                        }
                                      return;
                                      }
                                      if(isPageLoad)
                                      {
                                        this.sharedService.datastoreExcelSheet$.next(0);
                                        this.sharedService.gridmodePageNumber$.next(1);
                                        this.callGridViewColumnDetails();
                                        this.sharedService.datastoreExportAll$.next(false);
                                      }
                                      else
                                      {
                                        this.ExportData(true);
                                      }
                                    });
                                }
                              });
                          });
                      }
                    });
                });
            }
          });
      });
  }

  ExportData<T>(isAllExport: boolean): any {
    this.isExportAllPagesDownloading = false;
    if (isAllExport) {
      this.exportAllPagesRowsCount = 0;

      this.exportCsv.saveAllCsv(
        this.datasetpack,
        this.datasetcot,
        this.datasethta,
        this.sharedService.datastoreSignPostValPnR$.value,
        this.sharedService.datastoreSignPostValPnRGermany$.value,
        this.sharedService.datastoreSignPostValHTAChina$.value,
        this.sharedService.datastoreSignPostValHTA$.value,
        this.pnrReimbColumnExist,
        this.htaReimbColumnExist,
        this.sharedService.isGermanyExist$.value,
        this.sharedService.isChinaExist$.value
      );
      this.sharedService.gridmodesortColumn$.next(this.gridmodesortColumn);
      this.sharedService.gridmodesortType$.next(this.gridmodesortType);
      // this.sharedService.gridmodetotRecortCount$.next(
      //   this.gridmodetotRecortCount
      // );
    } else
      this.exportCsv.saveSingleCSV(
        this.datasethta,
        2,
        this.sharedService.datastoreSignPostValPnR$.value,
        this.sharedService.datastoreSignPostValPnRGermany$.value,
        this.sharedService.datastoreSignPostValHTAChina$.value,
        this.sharedService.datastoreSignPostValHTA$.value,
        this.pnrReimbColumnExist,
        this.htaReimbColumnExist,
        this.sharedService.isGermanyExist$.value,
        this.sharedService.isChinaExist$.value
      );
    this.sharedService.datastoreExportAll$.next(false);
  }

  findHTAColName(fiels: string[]): string[] {
    return gridViewCustomColumnHTA
      .filter((obj) => fiels.includes(obj.field))
      .sort((n1, n2) => n1.index - n2.index)
      .map((obj) => obj.header);
  }

  onToggleFilterVisibility(): void {
    this.filterPanel = {
      visibility: !this.filterPanel.visibility,
    };
    this.sharedService.datastoreEastPanelVisibility$.next(
      this.filterPanel.visibility
    );
  }

  onAskQuestion(): void {
    this.tawkService.ActivateChat();
  }
  openCotCalculator(): void {
        if (this.cotIdList !== undefined) {
          this.dialog
            .open(CotCalculatorComponent, {
              width: '95%',
              height: '602px',
              autoFocus:false,
              restoreFocus:false,
              data: {
                dataSource: this.CotCalculatorData,
                brands: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.brands
                ).filterValue,
                countries: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.countries
                ).filterValue,
                tli: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.indications
                ).filterValue,
                currency: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.currency
                ).filterValue,
                priceType: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.priceType
                ).filterValue,
                cotIdList: this.cotIdList,
                editedList: this.filters.findItemInFilters(
                  this.filters.filterValues$.value,
                  FilterNameEnum.cotCalculator
                ).filterValue[0].type,
              },
            })
            .afterClosed()
            .pipe(takeUntil(this.destroying$))
            .subscribe((res) => {
              if (res != undefined) {
                if (res?.data?.length >= 0) {
                  this.CotCalculatorData = JSON.parse(JSON.stringify(res));
                  this.showButtons = true;
                  this.filters.mapCotData(this.CotCalculatorData.data);
                  this.projectDetails = this.filters.findItemInFilters(
                    this.filters.filterValues$.value,
                    FilterNameEnum.searchType
                  ).filterValue[0].type.InsightsProject;
                  if(res.save){
                    this.saveProject();
                  }
                }
              }
            });
        }
  }

  async isRouteToGridMode(): Promise<boolean> {
    let isRouteToGrid: boolean = false;

    if (!this.featureFlagRoutingToGrid) {
      return isRouteToGrid;
    }

    if (!this.checkForMaxFiveHundredFlag) {
      return isRouteToGrid;
    }

    this.filters.disabledForMaxFiveHundredBrandLimit$
      .pipe(takeUntil(this.destroying$))
      .subscribe((limitExceeded: boolean) => {
        isRouteToGrid = limitExceeded;

        if (!isRouteToGrid && this.sharedService.isInsightCompanySearch) {
          isRouteToGrid = true;
        }

        if (this.checkForMaxFiveHundredFlag && isRouteToGrid) {
          this.checkForMaxFiveHundredFlag = false;
          this.onUseTable(RequestTypeEnum.PnR);
        }
      });

    return isRouteToGrid;
  }

  checkRoutingToGridModeFeatureFlag() {
    this.featureFlagService
      .getRoutingToGridModeFlag(this.destroying$)
      .then((flagValue: boolean) => {
        this.featureFlagRoutingToGrid = flagValue;

        if (this.featureFlagRoutingToGrid && this.checkForMaxFiveHundredFlag) {
          this.isRouteToGridMode().then((isRouteFound: boolean) => {
            if (!isRouteFound) {
              this.onUseChart();
            }
          });
          return;
        }

        this.onUseChart();
      });
  }

  onUseChart(isButtonClick: boolean = false): void {
    if (this.isWidgetModeDisabled) {
      return;
    }
    this.flag = 'chart';
    this.sharedService.chartmodeflag$.next(true);

    this.mode = this.modes.chart;
    this.sharedService.gridModeValue$.next(this.mode);
    if (!isButtonClick) {
      this.sharedService.datastoreOnLoadCompleted$.next(true);
    }
  }

  onUseTable(
    datastoreTabSelectedIndex: number,
    isButtonClick: boolean = false
  ): void {
    if(isButtonClick)
    {
      datastoreTabSelectedIndex = this.initGridViewValues?.initialTabIndex ?? 0
    }
    this.flag = 'grid';
    this.sharedService.chartmodeflag$.next(false);
    this.mode = this.modes.table;
    this.sharedService.gridModeValue$.next(this.mode);
    this.sharedService.datastoreTabSelectedIndex$.next(
      datastoreTabSelectedIndex
    );
    this.sharedService.datastoreTabName$.next(this.getSelectedTabName());   
    this.saveAllCsv(true);
    if (!isButtonClick) {
      this.sharedService.datastoreOnLoadCompleted$.next(true);
    }
   
  }

  private getSelectedTabName() {
    switch (this.sharedService.datastoreTabSelectedIndex$.value) {
      case Number(RequestTypeEnum.PnR):
        return GridViewTypeEnum.packprice;
      case Number(RequestTypeEnum.CoT):
        return GridViewTypeEnum.indicationcot;
      case Number(RequestTypeEnum.HTA):
        return GridViewTypeEnum.hta;
      default:
        return GridViewTypeEnum.packprice;
    }
  }

  checkWidgetModeDisabled(): void {
    this.isWidgetModeDisabled = this.noWidgetModePagesAccess;
    this.refreshWidgetModeTooltipText()
  }

  refreshWidgetModeTooltipText(message?:string): void {
    const messages: string[] = [];

    if(message)
      messages.push(message)

    if (this.isCountryScopeSelected) {
      messages.push('all countries view is ticked');
    }

    const lastMessage = messages.pop();
    const startMessages = messages.join(', ');
    const messageStr =
      startMessages.length > 0
        ? `${startMessages} or ${lastMessage}`
        : lastMessage;

    this.widgetModeTooltip = `Whilst ${messageStr}, widget mode is disabled`;

    if (this.noWidgetModePagesAccess) {
      this.widgetModeTooltip=this.tabDisabledTooltip;
    }
  }

  subscribeAllCountriesScopeSelected(): void {
    this.filters.countryScopeSelected$
      .pipe(takeUntil(this.destroying$))
      .subscribe((value) => {
        this.isCountryScopeSelected = value;
        this.checkWidgetModeDisabled();
        this.isExportAllPagesDisabled=false;
        this.saveAllCsv(true);
      });
  }

  private subscribeMaxCotWidgetModeBrandsExceeded() {
    this.filters.disabledForMaxBrandLimit$
      .pipe(takeUntil(this.destroying$))
      .subscribe((value) => {
        this.maxCotWidgetModeBrandsExceeded = value;
      });
  }

  private subscribeHideColumn() {
    this.sharedService.datastoreHideColumn$
      .pipe(takeUntil(this.destroying$))
      .subscribe((value) => {
        if (value) {
          var columns = this.getGridViewCustomColumns().filter(
            (col) => col.field.toLowerCase() === value.colId?.toLowerCase()
          );
          if (columns?.length > 0) {
            this.onClickGridCustomButton(columns[0]);
            this.onClickSave();
          }
        }
      });
  }

  subscribeShowHistory() {
    this.sharedService.datastoreShowHistory$
      .pipe(takeUntil(this.destroying$), skip(1), distinctUntilChanged())
      .subscribe((value) => {
        this.listOfAllColumnDetails = value
          ? gridViewCustomColumnPnRHistory
          : gridViewCustomColumnPnR;
        this.callGridViewColumnDetails(); //change grid custom details
      });
  }

  subscribePrelaunchNotes(){
    this.sharedService.isPrelaunchDataExistInCOT$
    .pipe(takeUntil(this.destroying$))
    .subscribe((value) => {
      this.isPrelaunchdataExistinCOT = value;
    });

    this.sharedService.isPrelaunchDataExistInPNR$
    .pipe(takeUntil(this.destroying$))
    .subscribe((value) => {
      this.isPrelaunchdataExistinPNR = value;
    });

  }

  getGridViewCustomColumns(): GridViewCustomColumn[] {
    switch (this.sharedService.datastoreTabSelectedIndex$.value) {
      case RequestTypeEnum.CoT:
        return gridViewCustomColumnCoT;
      case RequestTypeEnum.HTA:
        return gridViewCustomColumnHTA;
      case RequestTypeEnum.PnR:
      default:
        return this.sharedService.datastoreShowHistory$.value
          ? gridViewCustomColumnPnRHistory
          : gridViewCustomColumnPnR;
    }
  }

  fetchGeneralInfoComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'general-info' */ './general-info/general-info.component'
    ).then((m) => m.GeneralInfoComponent);
  }

  fetchIndicationMatrixComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'idication-matrix' */ './indication-matrix/indication-matrix.component'
    ).then((m) => m.IndicationMatrixComponent);
  }

  fetchCoTComparisonComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'cot-comparison' */ './cot-comparison/cot-comparison.component'
    ).then((m) => m.CotComparisonComponent);
  }

  fetchCoTCorridorComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'cot-corridor' */ './cot-corridor/cot-corridor.component'
    ).then((m) => m.CotCorridorComponent);
  }

  fetchCoTHistoryComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'cot-history' */ './cot-history/cot-history.component'
    ).then((m) => m.CotHistoryComponent);
  }

  fetchFormulationAndPackDetailsComponent(): Promise<any> {
    return import(
      /* webpackChunkName: 'cot-history' */ './formulation-and-pack-details/formulation-and-pack-details.component'
    ).then((m) => m.FormulationAndPackDetailsComponent);
  }

  fetchPackPrice(): Promise<any> {
    return import(
      /* webpackChunkName: 'reimbursement-map' */ './pack-price/pack-price.component'
    ).then((m) => m.PackPriceComponent);
  }

  fetchAvailabilityMap(): Promise<any> {
    return import(
      /* webpackChunkName: 'brands-availiability' */ './brands-availiability/brands-availiability.component'
    ).then((m) => m.BrandsAvailiabilityComponent);
  }

  fetchPageTitle(): Promise<any> {
    return import(
      /* webpackChunkName: 'page-title' */ './page-title/page-title.component'
    ).then((m) => m.PageTitleComponent);
  }

  fetchIndicationTabs(): Promise<any> {
    return import(
      /* webpackChunkName: 'indication-tabs' */ './indication-tabs/indication-tabs.component'
    ).then((m) => m.IndicationTabsComponent);
  }

  fetchReimbursementMap(): Promise<any> {
    return import(
      /* webpackChunkName: 'reimbursement-map' */ './reimbursement-map/reimbursement-map.component'
    ).then((m) => m.ReimbursementMapComponent);
  }

  fetchReimbursementHta(): Promise<any> {
    return import(
      /* webpackChunkName: 'reimbursement-hta' */ './reimbursement-hta/reimbursement-hta.component'
    ).then((m) => m.ReimbursementHtaComponent);
  }

  fetchReimbursementTime(): Promise<any> {
    return import(
      /* webpackChunkName: 'reimbursement-time' */ './reimbursement-time/reimbursement-time.component'
    ).then((m) => m.ReimbursementTimeComponent);
  }

  fetchReimbursementComparator(): Promise<any> {
    return import(
      './reimbursement-comparator/reimbursement-comparator.component'
    ).then((m) => m.ReimbursementComparatorComponent);
  }

  fetchIrpPrice(): Promise<any> {
    return import(
      /* webpackChunkName: 'irp-price' */ './irp-price/irp-price.component'
    ).then((m) => m.IrpPriceComponent);
  }

  fetchPackDetails(): Promise<any> {
    this.isPackDetailsComponentLoaded = false;
    return import(
      /* webpackChunkName: 'pack-details' */ './pack-details/pack-details.component'
    ).then((m) => {
      this.isPackDetailsComponentLoaded = true;
      return m.PackDetailsComponent;
    });
  }

  fetchJourneyDetails(): Promise<any> {
    return import(
      /* webpackChunkName: 'journey-details' */ './journey-details/journey-details.component'
    ).then((m) => m.JourneyDetailsComponent);
  }

  fetchJourneyHistory(): Promise<any> {
    return import(
      /* webpackChunkName: 'journey-history' */ './journey-history/journey-history.component'
    ).then((m) => m.JourneyHistoryComponent);
  }

  onSection(sectionName: SectionName): void {
    this.sharedService.updateSelectedBrand('');
    this.activeSection = sectionName;
    this.sharedService.activeSectionName$.next(this.activeSection || '');
    this.router.navigate([sectionName], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'preserve',
    });
  }

  isSectionActive(sectionName: string): boolean {
    return sectionName === this.activeSection;
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  onTabChange(tabId: number): void {
    this.sharedService.gridmodesortType$.next(0);
    this.sharedService.gridmodePageNumber$.next(1);
    this.sharedService.datastoreTabSelectedIndex$.next(tabId);
    this.sharedService.datastoreSignPostValHTA$.next('');
    this.sharedService.datastoreSignPostValPnR$.next('');
    this.sharedService.gridmodesortColumn$.next('');
    this.sharedService.gridmodesortType$.next(0);
    this.isSavedisable = true;

    if (tabId == RequestTypeEnum.PnR) {
      // this.sharedService.datastoreShowHistory$.next(false);
      this.tabname = GridViewTypeEnum.packprice;
      this.listOfAllColumnDetails = this.sharedService.datastoreShowHistory$
        .value
        ? gridViewCustomColumnPnRHistory
        : gridViewCustomColumnPnR;
    } else if (tabId == RequestTypeEnum.CoT) {
      this.tabname = GridViewTypeEnum.indicationcot;
      this.listOfAllColumnDetails = gridViewCustomColumnCoT;
    } else if (tabId == RequestTypeEnum.HTA) {
      this.tabname = GridViewTypeEnum.hta;
      this.listOfAllColumnDetails = gridViewCustomColumnHTA;
    }

    this.sharedService.datastoreTabName$.next(this.tabname);
    this.callGridViewColumnDetails(); //change grid custom details
  }

  hospitalReimnCountryVal: string = '';
  isGermanyExist: boolean = false;
  isChinaExist: boolean = false;
  signPostValPNRHospital: string = '';
  signPostValPNRGermany: string = '';
  signPostValHTAChina: string = '';
  signPostValHTA: string = '';
  onshowDiv(val: boolean) {
    if (this.sharedService.datastoreTabSelectedIndex$.value === 0) {
      this.pnrReimbColumnExist = val;
    } else if (this.sharedService.datastoreTabSelectedIndex$.value === 2) {
      this.htaReimbColumnExist = val;
    }

    this.sharedService.showSingPostDivflag$.next(val);
    //  this.sharedService.datastoreShowHistory$.next(false);

    if (this.sharedService.datastoreTabSelectedIndex$.value != 1) {
      this.filters.countryNames$
        .pipe(takeUntil(this.destroying$))
        .subscribe((data) => {
          if (data && data.length > 0) {
            this.sharedService.datastoreSignPostValPnR$.next('');
            this.sharedService.datastoreSignPostValHTA$.next('');
            this.signPostValHTA = '';
            this.signPostValPNRHospital = '';
            this.signPostValPNRGermany = '';
            this.signPostValHTAChina = '';
            this.sharedService.isGermanyExist$.next(false);
            this.sharedService.isChinaExist$.next(false);
            // this.sharedService.showSingPostDivflag$.next(false);
            this.sharedService.hospitalReimnCountryList$.next(
              data.filter((country: any) => country in HospitalReimbCountries)
            );
            if (this.sharedService.hospitalReimnCountryList$.value.length > 0) {
              this.hospitalReimnCountryVal =
                this.sharedService.hospitalReimnCountryList$.value.join(', ');
              this.sharedService.hospitalReimnCountryVal$.next(
                this.hospitalReimnCountryVal
              );
            }

            this.isGermanyExist = data.includes(CountryNames.Germany);
            this.sharedService.isGermanyExist$.next(this.isGermanyExist);
            this.isChinaExist = data.includes(CountryNames.China);
            this.sharedService.isChinaExist$.next(this.isChinaExist);
            if (this.sharedService.hospitalReimnCountryList$.value.length > 0) {
              // if(this.sharedService.datastoreTabSelectedIndex$.value==0)
              // this.sharedService.showSingPostDivflag$.next(this.selectcolumnList.includes(HTAColumnNameEnum.ReimbursedClassification));
              // else
              // this.sharedService.showSingPostDivflag$.next(false);

              this.signPostValPNRHospital =
                'In ' +
                this.hospitalReimnCountryVal +
                ', hospital-only medicines may be accessed and reimbursed as part of the national health insurance system; however they do not appear in the reimbursement list and thus cannot be formally reimbursed in the same way as retail/pharmacy drugs and might be displayed as not reimbursed. ';
              this.signPostValHTA = this.signPostValPNRHospital;
              this.sharedService.datastoreSignPostValHTA$.next(
                this.signPostValHTA
              );
              this.sharedService.datastoreSignPostValPnR$.next(
                this.signPostValPNRHospital
              );
            } else {
              this.sharedService.showSingPostDivflag$.next(false);
            }

            if (this.isGermanyExist) {
              this.signPostValPNRGermany =
                'Germany has two separate rows of data reflecting the list prices (Manufacturer Price; PpU) as well as the "Post-AMNOG" ex-factory price after negotiated discounts (APU/HAP) where applicable. ';
              this.sharedService.datastoreSignPostValPnRGermany$.next(
                this.signPostValPNRGermany
              );
            }
            if (this.isChinaExist) {
              this.signPostValHTAChina =
                'China does not follow a standard published HTA framework. Reimbursement details have been collected from the NRDL access pathway. ';
              this.sharedService.datastoreSignPostValHTAChina$.next(
                this.signPostValHTAChina
              );
            }
          } else {
            this.sharedService.isGermanyExist$.next(false);
            this.sharedService.isChinaExist$.next(false);
            this.sharedService.showSingPostDivflag$.next(false);
          }
        });
    } else {
      this.sharedService.isGermanyExist$.next(false);
      this.sharedService.isChinaExist$.next(false);
      this.sharedService.showSingPostDivflag$.next(false);
    }
  }

  onClickGridCustomButton(item: GridViewCustomColumn, event?: MouseEvent) {
    this.sharedService.gridmodesortType$.next(0);
    //stop closing mat-menu when clicking butoons
    event?.stopPropagation();
    //enable save button whenever changes on column selection
    this.isSavedisable = false;
    const toggle = event ? !item.isSelected : false;
    //change column visibility in UI
    this.listOfAllColumnDetails.forEach((col) => {
      if (col.field === item.field || col.activatedByHeader === item.field) {
        if (col.isSelected === toggle) return;

        col.isSelected = toggle;
        //reimbursement classification formulary status column &  Pack description column selection
        if (this.sharedService.datastoreTabSelectedIndex$.value == 2) {
          if (
            col.field == HTAColumnNameEnum.ReimbursedClassification ||
            col.field == HTAColumnNameEnum.PackDescription
          ) {
            if (this.isPackDescriptionSelected == false) {
              if (item.field == HTAColumnNameEnum.ReimbursedClassification) {
                const objToUpdate = this.listOfAllColumnDetails.find(
                  (obj) => obj.field === HTAColumnNameEnum.PackDescription
                );
                if (objToUpdate) {
                  objToUpdate.isSelected = col.isSelected;
                }
              } else if (
                item.field == HTAColumnNameEnum.PackDescription &&
                col.isSelected
              ) {
                this.isPackDescriptionSelected = true;
              }
            } else {
              if (
                item.field == HTAColumnNameEnum.PackDescription &&
                !col.isSelected
              ) {
                this.isPackDescriptionSelected = false;
              }
            }
            if (item.field == HTAColumnNameEnum.PackDescription) {
              //if packdesc get change, reg indication selection also will get change
              const objToUpdate = this.listOfAllColumnDetails.find(
                (obj) =>
                  obj.field === HTAColumnNameEnum.ReimbursedClassification
              );
              if (objToUpdate) {
                objToUpdate.isSelected = col.isSelected;
              }
            }
          } else if (item.field == HTAColumnNameEnum.RegulatoryIndication) {
            this.sharedService.datastoreRegulatoryIndicationFlag$.next(
              col.isSelected
            );
          }
        }

        if (
          this.sharedService.datastoreTabSelectedIndex$.value == 0 ||
          this.sharedService.datastoreTabSelectedIndex$.value == 2
        ) {
          if (
            item.field == HTAColumnNameEnum.ReimbursedClassification &&
            item.isSelected
          )
            this.onshowDiv(true);
          else this.onshowDiv(false);
        } else {
          this.onshowDiv(false);
        }
      }
    });
    //during column select/unselect, reflecting changes over the grid
    const custcolumn = this.listOfAllColumnDetails.filter(
      (col) => col.isSelected === true
    );
    this.selectcolumnList = custcolumn.map((item) => item.field);
    this.assignSelectedColumnList(this.selectcolumnList);
    this.PackDetails = this.fetchPackDetails();
  }
  assignSelectedColumnList(selectcolumnList: string[]) {
    if (
      this.sharedService.datastoreTabSelectedIndex$.value ==
      Number(RequestTypeEnum.PnR)
    ) {
      this.sharedService.datastoreSelectedColumnPnR$.next(selectcolumnList);
    } else if (
      this.sharedService.datastoreTabSelectedIndex$.value ==
      Number(RequestTypeEnum.CoT)
    ) {
      this.sharedService.datastoreSelectedColumnCOT$.next(selectcolumnList);
    } else if (
      this.sharedService.datastoreTabSelectedIndex$.value ==
      Number(RequestTypeEnum.HTA)
    ) {
      this.sharedService.datastoreSelectedColumnHTA$.next(selectcolumnList);
    }
    this.sharedService.datastoreSelectedColumn$.next(selectcolumnList);
  }

  onClickSave() {
    this.fetchGridViewColumnsCustomPipe
      .transform(
        this.listOfAllColumnDetails,
        this.sharedService.datastoreTabName$.value
      )
      .pipe(takeUntil(this.destroying$))
      .subscribe(() => {
        this.selectcolumnList = this.listOfAllColumnDetails
          .filter((cols) => cols.isSelected === true)
          .map((col) => col.field);
        this.assignSelectedColumnList(this.selectcolumnList);
        if (this.selectcolumnList.length > 0)
          this.PackDetails = this.fetchPackDetails();
        else this.onClickDefault();
      });

    this.isSavedisable = true;
  }

  callGridViewColumnDetails() {
    if (this.flag === 'grid') {
      this.sharedService.datastoreSelectedColumnPnR$.next([]);
      this.sharedService.datastoreSelectedColumnCOT$.next([]);
      this.sharedService.datastoreSelectedColumnHTA$.next([]);
      this.sharedService.datastoreSelectedColumn$.next([]);
      this.fetchGridViewCustomPipe
        .transform(this.sharedService.datastoreTabName$.value)
        .pipe(takeUntil(this.destroying$))
        .subscribe((value: CustomColumnListOutput) => {
          if (value.type === 'done') {
            if (
              value.gridViewType != this.sharedService.datastoreTabName$.value
            ) {
              return;
            }

            this.selectcolumnList = value.selectedColumns.split(',');
            this.unselectcolumnList = value.unSelectedColumns.split(',');

            if (this.selectcolumnList.length > 0) {
              this.assignSelectedColumnList(this.selectcolumnList);
              this.arrayComparision(this.selectcolumnList, true);
              this.arrayComparision(this.unselectcolumnList, false);

              //check if Pack description selected or not
              if (
                this.sharedService.datastoreTabName$.value ==
                GridViewTypeEnum.hta
              ) {
                const isPackDscSelected = [
                  HTAColumnNameEnum.PackDescription,
                  HTAColumnNameEnum.ReimbursedClassification,
                ].every((col) =>
                  this.selectcolumnList.some((obj) => obj === col)
                );
                this.isPackDescriptionSelected = isPackDscSelected
                  ? false
                  : true;

                //check RegulatoryIndication selected or not
                const isRegIndExist = value.selectedColumns
                  .split(',')
                  .includes(HTAColumnNameEnum.RegulatoryIndication)
                  ? true
                  : false;
                this.sharedService.datastoreRegulatoryIndicationFlag$.next(
                  isRegIndExist
                );
              }

              //showing Signposting when Reimbursement Classification (Formulary Status column is toggled on)
              this.onshowDiv(
                this.selectcolumnList.includes(
                  HTAColumnNameEnum.ReimbursedClassification
                )
              );
            } else {
              this.onClickDefault();
            }

            this.PackDetails = this.PackDetails || this.fetchPackDetails();
          }
        });
    }
  }

  arrayComparision(listofColumns: string[], isSelected: boolean) {
    for (const item of this.listOfAllColumnDetails) {
      const columnExist = listofColumns.find(
        (i) => i.toLowerCase() === item.field.toString().toLowerCase()
      );
      if (columnExist) item.isSelected = isSelected;
    }
  }

  onClickDefault() {
    //fetch defaultcolumns list
    this.isSavedisable = true;
    this.isPackDescriptionSelected = true;
    // this.customColumnsList.run({tabname: this.sharedService.datastoreTabName$.value})
    this.fetchGridViewColumnsCustomPipe
      .transformdefault(this.sharedService.datastoreTabName$.value)
      .pipe(takeUntil(this.destroying$))
      .subscribe((value) => {
        if( this.sharedService.datastoreTabName$.value === 'packprice' || 'indicationcot'){
          value.selectedColumns += ',availabilityStatus'
        }
        this.selectcolumnList = value.selectedColumns.split(',');
        this.unselectcolumnList = value.unSelectedColumns.split(',');
        this.arrayComparision(this.selectcolumnList, true);
        this.arrayComparision(this.unselectcolumnList, false);
        this.onClickSave();
        this.onshowDiv(
          this.selectcolumnList.includes(
            HTAColumnNameEnum.ReimbursedClassification
          )
        );
        this.sharedService.datastoreRegulatoryIndicationFlag$.next(false);
      });
  }

  addAndValidateExportCurrentPage(rowsCount: number): boolean {
   this.exportCurrentPageRowsCount = rowsCount;
    this.isExportCurrentPageDownloading = false;

    if (this.exportCurrentPageRowsCount > this.exportRowsMaxLimit) {
      this.isExportCurrentPageDisabled = true;
      // As if current page is exhausting limit then export all must be kept as disabled only
      this.isExportAllPagesDisabled = true;
      return false;
    }

    this.isExportCurrentPageDisabled = false;
    return true;
  }

  addAndValidateExportAllPages(rowsCount: number, pagePermission: boolean): boolean {
 if(pagePermission)
  {
    this.exportAllPagesRowsCount = rowsCount;
  }

    this.isExportAllPagesDownloading = false;

    if (this.exportAllPagesRowsCount > this.exportRowsMaxLimit) {
      this.isExportAllPagesDisabled = true;
      this.sharedService.datastoreExportAll$.next(false);
      return false;
    }
    return true;
  }
}
