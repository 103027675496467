import { CalcCOTFilter } from '../../app/common/constants';

export function getFilterValues(measures: any): any {
  let filters: any;
  const calcPer = measures.calcPer;
  let calcPerValue;
  const includeWastage = measures.includeWastage;
  let includeWastageValue;

  // check for includeWaste value
  if (measures.hasOwnProperty('includeWastage')){
    if (includeWastage){
      includeWastageValue = CalcCOTFilter.includeWastage;
    }else{
      includeWastageValue = CalcCOTFilter.excludeWastage;
    }
  }

  // check for calcPer value
  if (measures.hasOwnProperty('calcPer')){
    switch (calcPer) {
      case 'month': {
        calcPerValue = CalcCOTFilter.month;
        break;
      }
      case 'annum': {
        calcPerValue = CalcCOTFilter.annum;
        break;
      }
      case 'day': {
        calcPerValue = CalcCOTFilter.day;
        break;
      }
      case 'cycle': {
        calcPerValue = CalcCOTFilter.cycle;
        break;
      }
      case 'ddd': {
        calcPerValue = CalcCOTFilter.ddd;
        break;
      }
      case 'pack': {
        calcPerValue = CalcCOTFilter.pack;
        break;
      }
      case 'mg': {
        calcPerValue = CalcCOTFilter.mg;
        break;
      }
      case 'unit': {
        calcPerValue = CalcCOTFilter.unit;
        break;
      }
      case 'pfs': {
        calcPerValue = CalcCOTFilter.pfs;
        break;
      }
      default: {
        calcPerValue = CalcCOTFilter.month;
        break;
      }
    }
  }

  return filters = {
    includeWastageVal: includeWastageValue,
    calcPerVal: calcPerValue
  };

}

export function getCalcPerValue(filters: any, currency: string): string {
  let label: string;
  switch (filters.calcPerVal){
    case CalcCOTFilter.mg: {
      label = 'Price per Mg - ' + currency;
      break;
    }
    case CalcCOTFilter.unit: {
      label = 'Price per unit - ' + currency;
      break;
    }
    case CalcCOTFilter.pack: {
      label = 'Price per Pack - ' + currency;
      break;
    }
    case CalcCOTFilter.pfs: {
      label = 'Per PFS - ' + currency;
      break;
    }
    default: {
      label = filters.calcPerVal + ' COT ' + '(' + filters.includeWastageVal + ') - ' + currency;
      break;
    }
  }
  return label;
}
