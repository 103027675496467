import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterIncludeExcludeEnum, FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';

@Component({
  selector: 'he-filter-product-params-parallel-import',
  templateUrl: './filter-product-params-parallel-import.component.html',
  styleUrls: ['./filter-product-params-parallel-import.component.scss']
})
export class FilterProductParamsParallelImportComponent {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  items: FilterItemValueInterface[] = [
    {name: 'Exclude PI', type: FilterIncludeExcludeEnum.exclude},
    {name: 'Include PI', type: FilterIncludeExcludeEnum.include},
  ];
  title = 'Parallel Import';

  constructor(private filtersValueService: FiltersValueService) {
  }

  // get selectedItem(): FilterItemValueInterface {
  //   // return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.piStatus).filterValue[0] || this.items[0];
  // }

  itemSelectEvent(item: FilterItemValueInterface): void {
    // this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.piStatus, [item]));
  }
}
