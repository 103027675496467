import { Injectable } from '@angular/core';
import { colorCodeEnum } from './color.enum';

@Injectable({
  providedIn: 'root',
})
export class ColorSetService {
  public colorcodedAnalogues: { brand: string; color: string }[] = [];

  public setColor(name: string): string {
    return this.colorcodedAnalogues.filter(
      (x) => x.brand.toLowerCase() === name.toLowerCase()
    )[0].color;
  }

  public setAnalogueColor(analogues: any[]): void {
    let newAnaloues: any = [];
    analogues.forEach((element) => {
      if (!newAnaloues.some((x: any) => x.brand === element.brand)) {
        newAnaloues.push(element);
      }
    });
    this.colorcodedAnalogues = newAnaloues.map((x: any, index: number) => {
      let newSequence = index % 64;
      return {
        brand: x.brand,
        color: colorCodeEnum[newSequence],
        sequenceNo: x.sequenceNo,
      };
    });
  }
}
