export enum PageModes {
  chart = 'chart',
  table = 'table'
}

export enum ResponseStatus {
  pending = 'pending',
  done = 'done',
  fail = 'fail'
}

export enum AtlasTabIds {
  glance,
  facts,
  pricing,
  irp,
  sources
}

export enum AccessPagesEnum {
  comparator = 'Comparator',
  moa = 'Moa Search',
  profiles = 'Country Profiles',
  irp = 'IRP Data',
  downloads = 'Downloads',
  priceCategoryFilter = 'PriceCategory Filter',
  cot = 'Cost Of Treatment',
  price = 'Price',
  reimAndHTA = 'Reimbursement & HTA',
  journeyDetails = 'Journey Details',
  projects = 'projects',
  dataquery = 'dataquery',
  allowed = 'allowed', // Use to always allow/display an element in he-access-barrier
  support='Support',
  generalInformation = 'General Information',
  gridViewPnR = 'Gridview P&R',
  gridViewCOT = 'Gridview IndicationCOT',
  gridViewHTA = 'Gridview HTA',
  mainSearchBar = 'Main Search Bar'
}

export enum DataStoreBaseEnums {
  countriesData = 'countriesData',
  discontinued = 'discontinued'
}

export enum HomePageLinks {
  irp = 'IRP',
  projects = 'Projects',
  horizon = 'GPI Horizon',
  importExport='Import Export Tool'
}




export enum SessionEnums {
  brands = 'brands',
  indications = "indications",
  indicationsAllowed = "indicationsAllowed"
}
