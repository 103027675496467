import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Subject, startWith, take } from 'rxjs';
import {
  AnalogueListInterface,
  AnalogueTableInterface,
  LegendEnum,
} from '../../models/criteria.interface';
import { ProjectService } from '../../services/project.service';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';

@Component({
  selector: 'he-analoguei-heat-map',
  templateUrl: './analoguei-heat-map.component.html',
  styleUrls: ['./analoguei-heat-map.component.scss'],
})
export class AnalogueiHeatMapComponent implements OnInit, OnDestroy {
  @Output() public emitAnalogueList = new EventEmitter<
    AnalogueListInterface[]
  >();
  @Output() public emitRemovedAnalogues =
    new EventEmitter<AnalogueListInterface>();
    @Output() public removeCountryFilter =
    new EventEmitter<string>();
  @Input() public minlaunchDate = 2019;
  @Input() public maxlaunchDate = 2021;
  @Input() public loading = false;
  @Input() public dataSource: AnalogueTableInterface[] = [];
  @Input() public defaultdataSource: AnalogueTableInterface[] = [];
  @Input() public selectedAnalogue: AnalogueListInterface[] = [];
  @Input() public sortedAnalogues: any[] = [];
  public searchTable = new FormControl('');
  public sort: any;
  public checked = false;
  public disabled = false;
  public marketBrandCount: any = [];

  public legends = [
    {
      name: 'Meets criteria',
      color: LegendEnum[1],
    },
    {
      name: 'Partially meets criteria',
      color: LegendEnum[2],
    },
    {
      name: 'Does not meet criteria',
      color: LegendEnum[3],
    },
    {
      name: 'No criteria included',
      color: LegendEnum[0],
    },
  ];

  public displayedColumns: string[] = [
    'toggle',
    'Brand',
    'INN',
    'Market',
    'Orphan',
    'Oneofftherapy',
    'Population',
    'Eligiblepatientnumber',
    'Indication',
    'Routeofadministration',
    'Mechanismofaction',
    // 'TherapyArea',
    // 'IndicationCategory',
  ];

  private unsubscriber$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private criteriaSelectionService: CriteriaSelectionService
  ) {}

  ngOnInit(): void {
    this.searchTable.valueChanges
      .pipe(startWith(null))
      .subscribe((analogue: string | null) => {        
        this.removeCountryFilter.emit('');
        this.searchTableData(analogue);
      });
      this.criteriaSelectionService.brandCountData.pipe(take(1)).subscribe((res)=>{
        this.marketBrandCount = res
      })
    this.getMarkets();
  }

  public searchTableData(search:string | null):void{
    search? (this.dataSource = this._filter(search))
    : (this.dataSource = this.defaultdataSource.slice());
    if (this.sort !== undefined) {
    this.sortData(this.sort);
    }
  }

  public getMarkets() {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          if(!this.marketBrandCount.some((x:any)=> x.country === market)) {
            this.marketBrandCount.push({
              country: market,
              brandCount: 0,
            });
          }
        });
        this.marketBrandCount.forEach((market: any,index:number) => {
          if(!data?.selectedMarkets.some((x:any) => x === market.country)){
            this.marketBrandCount.splice(index,1)
          }
        })
      });
      this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);
      
      // this.updateMarketsBrandCount();

  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    let filteredList = [];
    filteredList = this.defaultdataSource.filter((analogue) => {
      return JSON.stringify(Object.values(analogue))
        .toLowerCase()
        .includes(filterValue);
    });
    // if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.Brand.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Brand.toLowerCase().includes(filterValue)
    //   );
    // } else if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.INN.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.INN.toLowerCase().includes(filterValue)
    //   );
    // } else if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.Indication.name.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Indication.name.toLowerCase().includes(filterValue)
    //   );
    // } else {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Brand.toLowerCase().includes(filterValue)
    //   );
    // }
    return filteredList;
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'Brand':
          return compare(a.Brand, b.Brand, isAsc);
        case 'INN':
          return compare(a.INN, b.INN, isAsc);
        case 'Orphan':
          return compare(a.Orphan.name, b.Orphan.name, isAsc);
        case 'Oneofftherapy':
          return compare(a.Oneofftherapy.name, b.Oneofftherapy.name, isAsc);
        case 'Population':
          return compare(a.Population.name, b.Population.name, isAsc);
        case 'Eligiblepatientnumber':
          return compare(
            a.Eligiblepatientnumber.name,
            b.Eligiblepatientnumber.name,
            isAsc
          );
        case 'Indication':
          return compare(a.Indication.name, b.Indication.name, isAsc);
        case 'Routeofadministration':
          return compare(
            a.Routeofadministration.name,
            b.Routeofadministration.name,
            isAsc
          );
        case 'Mechanismofaction':
          return compare(
            a.Mechanismofaction.name,
            b.Mechanismofaction.name,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  public selectBrand(event: boolean, analogue: any): void {
    if (event) {
      if (
        this.selectedAnalogue.filter(
          (x) =>
          x.name.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
            x.indication.toLowerCase().trim() ===
              analogue.Indication.name.toLowerCase().trim() &&
            x.inn.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        ).length == 0
      )
        this.selectedAnalogue.push({
          name: analogue.Brand,
          indication: analogue.Indication.name,
          mechanismOfAction: analogue.Mechanismofaction.name,
          inn: analogue.INN,
          sequenceNo: this.selectedAnalogue.length + 1,
          available: true,
          market: this.setmarketForselectedAnalogues(analogue),
        });
      this.dataSource
        .filter(
          (res) =>
          res.Brand.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
            res.Indication.name.toLowerCase().trim() ===
              analogue.Indication.name.toLowerCase().trim() &&
            res.INN.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle = event;
        });
      this.defaultdataSource
        .filter(
          (res) =>
          res.Brand.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
            res.Indication.name.toLowerCase().trim() ===
              analogue.Indication.name.toLowerCase().trim() &&
            res.INN.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle = event;
        });
      this.updateMarketsBrandCount()
      this.emitAnalogueList.emit(this.selectedAnalogue);
    } else {
      // let removeAnalogue = this.selectedAnalogue.filter((data:any)=>{
      //   return (data.name === analogue.Brand && data.indication === analogue.Indication.name)
      // })[0]
      this.dataSource
        .filter(
          (res) =>
          res.Brand.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
            res.Indication.name.toLowerCase().trim() ===
              analogue.Indication.name.toLowerCase().trim() &&
            res.INN.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        )
        .forEach((res,index) => {
          res.toggle = event;
        })
      this.defaultdataSource
        .filter(
          (res) =>
          res.Brand.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
            res.Indication.name.toLowerCase().trim() ===
              analogue.Indication.name.toLowerCase().trim() &&
            res.INN.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle = event;
        });
      this.selectedAnalogue.forEach((data: any, index: number) => {
        if (
          data.name.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
          data.indication.toLowerCase().trim() ===
            analogue.Indication.name.toLowerCase().trim() &&
          data.inn.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
        ) {
          this.emitRemovedAnalogues.emit(data);
          // this.selectedAnalogue.splice(index, 1);
        }
      });
      this.sortedAnalogues.forEach((data: any, index: number) => {
        if (
          data.name.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
          data.indication.trim().toLowerCase() ===
            analogue.Indication.name.trim().toLowerCase() &&
          data.inn.trim().toLowerCase() === analogue.INN.trim().toLowerCase()
        ) {
          this.sortedAnalogues.splice(index, 1);
        }
      });
      // this.emitRemovedAnalogues.emit(removeAnalogue)
      this.updateMarketsBrandCount()
    }
    // this.emitAnalogueList.emit(this.selectedAnalogue);
  }


  public setmarketForselectedAnalogues(analogue:any):any[]{
    let Markets:any[]=[]
    this.defaultdataSource
    .filter(
      (res) =>
      res.Brand.toLowerCase().trim() ===  analogue.Brand.toLowerCase().trim() &&
        res.Indication.name.toLowerCase().trim() ===
          analogue.Indication.name.toLowerCase().trim() &&
        res.INN.toLowerCase().trim() === analogue.INN.toLowerCase().trim()
    ).forEach(x=> {
      Markets = [...Markets,...x.Market];
    })
    return Markets;
  }


  updateMarketsBrandCount(){
    const enabledBrands = JSON.parse(JSON.stringify(this.defaultdataSource.filter((res:any)=>{
      return res.toggle === true
    })));
    let testBrands:any[] = []
    enabledBrands.forEach((x:any)=>{
      let index = testBrands.findIndex(y=> y.Brand.toLowerCase().trim() ===  x.Brand.toLowerCase().trim() &&
      y.Indication.name.toLowerCase().trim() ===
        x.Indication.name.toLowerCase().trim() &&
      y.INN.toLowerCase().trim() === x.INN.toLowerCase().trim())
      if(index === -1 ){
        testBrands.push(x)
      }else{
        x.Market.forEach((z:any)=>{
          let countryIndex = testBrands[index].Market.findIndex((a:any)=> a.name === z.name)
          if(countryIndex === -1){
            testBrands[index].Market.push(z)
          }
        })
        
      }
    })
    this.marketBrandCount.forEach((countryData:any) => {
      countryData.brandCount = testBrands.filter(brandData=>brandData.Market.
        filter((m:any)=>m.name.toLowerCase().trim() === countryData.country.toLowerCase().trim()).length>0).length
    });
    this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
