<he-layout [leftEdgePanel]="drawer | async" [rightEdgePanel]="filterPanel"
  [mode]="mode"
  [showLoadingOverlay]="sharedService.datastoreLoadingScreenVisibility$ | async">
  <he-dialog *ngIf="projectDraft" (terminate)="onDeclineNewProject()">
    <he-new-project [project]="projectDraft" [header]="header"
      [buttonText]="buttonText"
      (reject)="onDeclineNewProject()" (projectCreated)="onSubmitNewProject()"></he-new-project>
    <!-- (confirm)="onSubmitNewProject()" -->
  </he-dialog>

  <div class="p-2 west-toolbar-slot" *ngIf="mode === 'chart'">
    <span class="subtitle">PULSE INSIGHTS</span>
  </div>

  <div class="west-slot" *ngIf="route.params | async as pageRef">
    <ng-container>
      <div class="p-2 west-slot section-item"
        [class.disabled]="!isPermissionActive(GeneralInformationTab)"
        [matTooltip]="
          !isPermissionActive(GeneralInformationTab) ? tabDisabledTooltip : ''
        " matTooltipClass="he-tooltip" (click)="
          !isPermissionActive(GeneralInformationTab)
            ? null
            : onSection('general')
        " [ngClass]="{ 'section-item-selected': isSectionActive('general') }"
        *ngIf="mode === 'chart'">
        <span>General information</span>
      </div>
    </ng-container>
    <ng-container>
      <div class="p-2 west-slot section-item"
        [class.disabled]="!isPermissionActive(CotTab)"
        [matTooltip]="!isPermissionActive(CotTab) ? tabDisabledTooltip : ''"
        matTooltipClass="he-tooltip"
        (click)="!isPermissionActive(CotTab) ? null : onSection('cot')"
        [ngClass]="{ 'section-item-selected': isSectionActive('cot') }"
        *ngIf="mode === 'chart'">
        <span>Cost of treatment</span>
      </div>
    </ng-container>
    <ng-container>
      <div class="p-2 west-slot section-item"
        [class.disabled]="!isPermissionActive(PriceTab)"
        [matTooltip]="!isPermissionActive(PriceTab) ? tabDisabledTooltip : ''"
        matTooltipClass="he-tooltip"
        (click)="!isPermissionActive(PriceTab) ? null : onSection('price')"
        [ngClass]="{ 'section-item-selected': isSectionActive('price') }"
        *ngIf="mode === 'chart'">
        <span>Price</span>
      </div>
    </ng-container>
    <ng-container>
      <div class="p-2 west-slot section-item"
        [class.disabled]="!isPermissionActive(ReimAndHTATab)" [matTooltip]="
          !isPermissionActive(ReimAndHTATab) ? tabDisabledTooltip : ''
        " matTooltipClass="he-tooltip" (click)="
          !isPermissionActive(ReimAndHTATab) ? null : onSection('reimbursement')
        " [ngClass]="{
          'section-item-selected': isSectionActive('reimbursement')
        }" *ngIf="mode === 'chart'">
        <span>Reimbursement &amp; HTA</span>
      </div>
    </ng-container>
    <ng-container>
      <div class="p-2 west-slot section-item" [class.disabled]="
          isTabDisabled || !isPermissionActive(JourneyDetailsTab)
        " matTooltipClass="he-tooltip" (click)="
          isPermissionActive(JourneyDetailsTab) && !isTabDisabled
            ? onSection('journey')
            : ''
        " [ngClass]="{
          'section-item-selected': isSectionActive('journey'),
          sidebar: isTabDisabled
        }" [matTooltip]="
          isTabDisabled
            ? 'Journey details disabled for more than 100 brands in selection'
            : !isPermissionActive(JourneyDetailsTab)
            ? tabDisabledTooltip
            : ''
        " *ngIf="mode === 'chart'">
        <span>Journey details</span>
      </div>
    </ng-container>
  </div>

  <div
    class="d-flex flex-row align-items-center pt-4 pl-3 pr-3 pb-2 middle-toolbar-slot flex-wrap flex-sm-nowrap"
    [ngClass]="showButtons ? 'incPadding' : ''">
    <div class="d-flex">
      <ng-container *ngIf="PageTitle | async as component">
        <ng-template [ngComponentOutlet]="component"></ng-template>
      </ng-container>
    </div>
    <div class="m-auto"></div>
    <div class="actions-wrap">
      <ng-container>
        <he-info-button [infoButtonText]="infoButtonText"></he-info-button>
      </ng-container>
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="modes.table">
          <!-- ------------Export option -->
          <button mat-icon-button [matMenuTriggerFor]="groupByexport"
            class="he-icon-button he-button"
            title="Export grid to CSV" [disabled]="!permission">
            <he-icon [id]="'download-csv'"></he-icon>
          </button>
          <mat-menu #groupByexport="matMenu">
            <button mat-menu-item class="he-button-export" (click)="saveCsv()"
              [disabled]="isExportCurrentPageDisabled || isExportCurrentPageDownloading"
                 [title]="isExportCurrentPageDownloading? 'Fetching records for downloading!': isExportCurrentPageDisabled? exportErrorMessage: ''">
              Export Current Page`
            </button>
            <button  mat-menu-item class="he-button-export" 
              (click)="saveAllCsv()"
              *ngIf="!this.sharedService.datastoreShowHistory$.value && totalTabPermission >1 "
              [disabled]="
                isExportAllPagesDisabled || isExportAllPagesDownloading
              " [title]="
                isExportAllPagesDownloading
                  ? 'Fetching records for downloading!'
                  : isExportAllPagesDisabled
                  ? exportErrorMessage
                  : ''
              "> Export All Pages
            </button>
          </mat-menu>
          <!-- end export -->
        </ng-container>
        <ng-container *ngSwitchDefault>
          <button mat-icon-button class="he-icon-button he-button-m-size"
            title="Export visible elements"
            (click)="save()" [disabled]="!permission">
            <he-icon [id]="'download-element'"></he-icon>
          </button>
          <!-- <button mat-icon-button class="he-icon-button he-button-m-size" title="Save project"
            (click)="saveProject()">
            <he-icon [id]="'editor-action-save'"></he-icon>
          </button> -->
        </ng-container>
      </ng-container>

      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="modes.table">
          <button mat-icon-button
            class="he-icon-button he-custom-button-m-size-icon"
            [matMenuTriggerFor]="menu"
            title="Grid View Customization"
            *hePulseFeatureFlag="GRID_MODE_EXPANSION">
            <he-icon [id]="'gridview-custom'"></he-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div class="menu-scrollable" mat-menu-content>
              <div *ngFor="let item of listOfAllColumnDetails">
                <div *ngIf="item.isVisible">
                  <button mat-menu-item
                    (click)="onClickGridCustomButton(item, $event)"
                    [ngClass]="{
                      'he-default-button': item.isDefault,
                      'button-select': !item.isDefault && item.isSelected
                    }">
                    <div [ngClass]="{
                        'he-button-non-clickable': item.isDefault,
                        'button-grid-custom': item.isSelected,
                        'unselect-button-grid-custom': !item.isSelected
                      }">
                      <div>{{ item.header }}</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="menu-static button-allignment">
              <button [disabled]="isSavedisable" class="he-button-save"
                (click)="onClickSave()">
                Save
              </button>
              <button class="he-button-save" (click)="onClickDefault()">
                Default
              </button>
            </div>
          </mat-menu>
        </ng-container>
      </ng-container>
      <ng-container *hePulseFeatureFlag="COT_CAL_BUTTON">
        <div class="tooltippp"
          *ngIf="isSectionActive('cot') && mode === 'chart'">
          <!-- condition to show in grid view -->
          <!-- <div class="tooltippp"   *ngIf="
        (isSectionActive('cot') && mode === 'chart') ||
        (mode === 'table' &&
          sharedService.datastoreTabSelectedIndex$.value === 1)
      "> -->
          <button mat-icon-button
            class="he-icon-button he-custom-button-m-size ml-2 cot-cal-button"
            title="Cost of Treatment Calculator Customization"
            (click)="openCotCalculator()">
            <he-icon [id]="'cot-calculator'"></he-icon>
          </button>
          <div class="tooltiptext" *ngIf="showButtons">
            <div class="actions-wrap">
              <button class="he-button-blue-green cot-button"
                (click)="openCotCalculator()">
                Edit
              </button>
              <button class="he-button-blue-green cot-button"
                (click)="saveProject()">
                Save
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <button mat-icon-button
        class="he-icon-button he-custom-button-m-size ml-2"
        title="Chat with support"
        (click)="onAskQuestion()">
        <he-icon [id]="'user-support'"></he-icon>
      </button>
      <div class="separator d-inline-flex ml-3 mr-3"></div>

      <mat-button-toggle-group appearance="legacy" [value]="mode"
        class="he-button-toggle-group">
        <mat-button-toggle [value]="modes.chart"
          [title]="isWidgetModeDisabled ? '' : 'Widget mode'"
          (click)="onUseChart(true)"
          [disabled]="isWidgetModeDisabled ? true : null"
          class="he-button-toggle he-button-toggle-white he-button-m-size"
          [class.he-widget-mode-disabled]="isWidgetModeDisabled"
          [matTooltip]="widgetModeTooltip"
          matTooltipClass="he-tooltip"
          [matTooltipDisabled]="!isWidgetModeDisabled">
          <he-icon [id]="'pie-chart'"></he-icon>
        </mat-button-toggle>
        <mat-button-toggle [value]="modes.table" title="Grid mode"
          (click)="!isGridModeDisabled ? onUseTable(0, true) : null"
          class="he-button-toggle he-button-toggle-white he-button-m-size"
          [disabled]="isGridModeDisabled ? true : null"
          [class.he-widget-mode-disabled]="isGridModeDisabled"
          [matTooltip]="tabDisabledTooltip"
          matTooltipClass="he-tooltip"
          [matTooltipDisabled]="!isGridModeDisabled">
          <he-icon [id]="'Grid-Mode'"></he-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button mat-icon-button class="he-icon-button he-button-m-size ml-2"
        [class.active]="filterPanel.visibility"
        title="Show/hide filters" (click)="onToggleFilterVisibility()">
        <he-icon [id]="'filter-show-hide'"></he-icon>
      </button>
    </div>
  </div>
  <he-loading-spinner [diameter]="70" [height]="'350px'" *ngIf="
      mode === modes.loading ||
      (mode === modes.table && !isPackDetailsComponentLoaded)
    ">
  </he-loading-spinner>

  <ng-container [ngSwitch]="mode" style="display: block">
    <ng-container *ngSwitchCase="modes.table">
      <div class="tab-header tab-header-background"
        *hePulseFeatureFlag="GRID_MODE_EXPANSION">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
          (selectedIndexChange)="onTabChange($event)"
          [selectedIndex]="sharedService.datastoreTabSelectedIndex$ | async"
          *ngIf="isPackDetailsComponentLoaded">
          <mat-tab [disabled]="!isPermissionActive(AccessPagesEnum.gridViewPnR)"
            label="Price & Reimbursement">
            <ng-template mat-tab-label>
              <label role="button" class="mb-0"
                matTooltip="Please contact support@globalpricing.com for more information"
                matTooltipClass="he-tooltip"
                [matTooltipDisabled]="
                  isPermissionActive(AccessPagesEnum.gridViewPnR)
                ">
                Price & Reimbursement
              </label>
            </ng-template>
          </mat-tab>
          <mat-tab [disabled]="!isPermissionActive(AccessPagesEnum.gridViewCOT)"
            label="Indication/Cost of Treatment">
            <ng-template mat-tab-label>
              <label role="button" class="mb-0"
                matTooltip="Please contact support@globalpricing.com for more information"
                matTooltipClass="he-tooltip"
                [matTooltipDisabled]="
                  isPermissionActive(AccessPagesEnum.gridViewCOT)
                ">
                Indication/Cost of Treatment
              </label>
            </ng-template>
          </mat-tab>
          <mat-tab [disabled]="!isPermissionActive(AccessPagesEnum.gridViewHTA)"
            label="HTA">
            <ng-template mat-tab-label>
              <label role="button" class="mb-0"
                matTooltip="Please contact support@globalpricing.com for more information"
                matTooltipClass="he-tooltip"
                [matTooltipDisabled]="
                  isPermissionActive(AccessPagesEnum.gridViewHTA)
                ">
                HTA
              </label>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <ng-template
        *ngIf="(this.sharedService.datastoreTabSelectedIndex$ | async) === 0"
        [ngTemplateOutlet]="gridModeTable"></ng-template>
      <ng-template
        *ngIf="(this.sharedService.datastoreTabSelectedIndex$ | async) === 1"
        [ngTemplateOutlet]="gridModeTable"></ng-template>
      <ng-template
        *ngIf="(this.sharedService.datastoreTabSelectedIndex$ | async) === 2"
        [ngTemplateOutlet]="gridModeTable"></ng-template>

      <ng-template #gridModeTable>
        <div class="pl-2 pr-2 pb-2 middle-slot"
          *ngIf="PackDetails | async as component">
          <mat-card>
            <mat-card-content>
              <ng-container *ngIf="this.initGridViewValues">
                <ng-template [ngComponentOutlet]="component"></ng-template>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="modes.chart">
      <div class="pt-0 pl-3 pr-3 pb-2 middle-toolbar-slot">
        <ng-container *ngIf="IndicationTabs | async as component">
          <ng-template [ngComponentOutlet]="component"></ng-template>
        </ng-container>
      </div>
      <he-command *ngIf="isSectionActive('general')"
        (asyncFire)="asyncLoadService.reset()"></he-command>
      <ng-container *ngIf="isSectionActive('general')">
        <div class="p-2 middle-slot" *ngIf="GeneralInfo | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('general')">
        <ng-container *ngIf="filters.brandValues$ | async as brands">
          <ng-container *ngIf="brands.length > 1">
            <div class="p-2 middle-slot"
              *ngIf="IndicationMatrix | async as component">
              <mat-card>
                <mat-card-content>
                  <ng-template [ngComponentOutlet]="component"></ng-template>
                </mat-card-content>
              </mat-card>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isSectionActive('general')">
        <div class="p-2 middle-slot"
          *ngIf="AvailabilityMap | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('general')">
        <div class="p-2 middle-slot"
          *ngIf="FormulationAndPackDetails | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('cot')">
        <div class="p-2 middle-slot" *ngIf="CoTCorridor | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="
          isSectionActive('cot') && !(filters.disabledForMaxBrandLimit$ | async)
        ">
        <div class="p-2 middle-slot" *ngIf="CoTComparison | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('cot')">
        <div class="p-2 middle-slot" *ngIf="CoTHistory | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('reimbursement')">
        <div class="p-2 middle-slot"
          *ngIf="ReimbursementMap | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('reimbursement')">
        <div class="p-2 middle-slot"
          *ngIf="ReimbursementTime | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('reimbursement')">
        <div class="p-2 middle-slot"
          *ngIf="ReimbursementHta | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *hePulseFeatureFlag="SOC_INDEX">
        <ng-container *ngIf="isSectionActive('reimbursement')">
          <div class="p-2 middle-slot"
            *ngIf="ReimbursementComparator | async as component">
            <mat-card>
              <mat-card-content>
                <ng-template [ngComponentOutlet]="component"></ng-template>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isSectionActive('price')">
        <div class="p-2 middle-slot" *ngIf="IrpPrice | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('price')">
        <div class="p-2 middle-slot" *ngIf="PackPrice | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('journey')">
        <div class="p-2 middle-slot" *ngIf="JourneyDetails | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-container *ngIf="isSectionActive('journey')">
        <div class="p-2 middle-slot" *ngIf="JourneyHistory | async as component">
          <mat-card>
            <mat-card-content>
              <ng-template [ngComponentOutlet]="component"></ng-template>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="pt-3 pl-3 pr-3 pb-0 east-toolbar-slot">
    <span class="subtitle">Filters:</span>
  </div>
  <div class="east-slot">
    <he-filters></he-filters>
  </div>

  <div class="p-2 footer-slot">
    <mat-card>
      <mat-card-content>
        <he-footer></he-footer>
      </mat-card-content>
    </mat-card>
  </div>  
</he-layout>