import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ProjectForecastComponent } from './components/project-forecast/project-forecast.component';
import { ProjectForecastValuePriceComponent } from './component-pieces/project-forecast-value-price/project-forecast-value-price.component.';
import {
  CorridorChartModule,
  CorridorGridModule,
  DosageInfoModule,
} from '../../../../uikit/corridor-widget';
import { NgxEchartsModule } from 'ngx-echarts';
import { ProjectForecastValueAssessmentComponent } from './component-pieces/project-forecast-value-assessment/project-forecast-value-assessment.component';
import { LoadingSpinnerModule } from '../../../../uikit/loading-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ProjectForecastComponent, ProjectForecastValuePriceComponent, ProjectForecastValueAssessmentComponent],
  exports: [ProjectForecastComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    FormsModule,
    SharedModule,
    // CorridorChartModule,
    LoadingSpinnerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
})
export class ForecastModule {}
