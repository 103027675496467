import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { AssetDynamicDataService } from '../../../../horizon-project/services/asset-dynamic-data.service';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { AssetTppService } from '../../services/project-asset-tpp.service';
import { HeaderService } from '../../../../shared/services/header.service';
import { MasterService } from '../../../../horizon-project/services/master.service';
import { ValueAttributeScoresService } from '../../../../horizon-project/services/value-attribute-scores.service';
import { StringPropertyComparison } from '../../../../horizon-project/utility-functions/SortComparison';
import { Subject, takeUntil } from 'rxjs';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';

@Component({
  selector: 'he-project-asset-tpp-new',
  templateUrl: './project-asset-tpp-new.component.html',
  styleUrls: ['./project-asset-tpp-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectAssetTppNewComponent
  implements OnInit, AfterContentChecked,OnDestroy
{
  assetData = {
    id: '',
    name: '',
  };
  scenario = '';
  countries: string[] = [];
  valueDomains: any = [];
  updatedAssetScore: any = [];
  innName = '';
  scoreModel: any = {};
  descriptionModal: any = {};
  domainModel: any = {};

  projectId = '';
  private unsubscriber$ = new Subject<void>();

  constructor(
    private countryFlagService: CountryFlagService,
    private assetTppService: AssetTppService,
    private assetDynamicService: AssetDynamicDataService,
    private activatedRouter: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private headerService: HeaderService,
    private masterService: MasterService,
    private updateFrameworkService: UpdateFrameworkService,
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.projectId = p.project;
        if (this.projectId) {
          // this.loadData();
        }
      });

    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.scenario = data;
        if (this.scenario && this.projectId) {
          this.loadData();
        }
      });
    this.assetDynamicService.emptyData();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private async loadData() {
    const projectData = await this.masterService.getProjectDetails(
      this.projectId
    );
    if (projectData && this.scenario) {
      this.updateFrameworkService.GetValueScoreDisplayType(projectData.valueFrameworkId).subscribe(async res=>{
        this.updateFrameworkService.isDropDown =  res[0].isDropdown;
        this.assetData.id = projectData.assetTLIAndCountrys[0].assetId;
        this.assetData.name = projectData.assetTLIAndCountrys[0].assetName;
        this.innName = projectData.assetTLIAndCountrys[0].inn;
        this.scenario = this.headerService.getScenario();
        const requestBody = {
          projectId: this.projectId,
          innName: this.innName,
          scenario: this.scenario,
          isSummaryData:this.updateFrameworkService.isDropDown
        };
        if (this.scenario != null) {
          await this.assetTppService
            .getAssetTppData(requestBody)
            .then((assetTppData) => {
              return assetTppData ;//this.OrderValueDomains(assetTppData);
            })
            .then((assetTppData) => {
              this.valueDomains = assetTppData.assetTLIs[0].valueDomains;
              this.Sort();
              this.findAllCountries();
              this.assetDynamicService.emptyData();
              this.formatAnalogueToFlatData();
              this.sortValueAttributeScores();
            });
        }
      })

    }
  }

  private OrderValueDomains(assetTppData: any): any {
    let specificOrder = [
      'Burden',
      'Product Characteristics',
      'Trial Design',
      'Clinical Benefit',
      'Other',
    ];
    let nAssetTppData = JSON.parse(JSON.stringify(assetTppData));
    assetTppData.assetTLIs[0].valueDomains = [];
    specificOrder.forEach((domain: string) => {
      let domainData = nAssetTppData.assetTLIs[0].valueDomains.filter(
        (ele: any) => ele.domainName.toLowerCase() === domain.toLowerCase()
      );
      if (domainData.length > 0) {
        domainData[0].valueAttributes.sort(
          StringPropertyComparison('valueAttributeName')
        );
        assetTppData.assetTLIs[0].valueDomains.push(domainData[0]);
      }
    });
    return assetTppData;
  }

  private findAllCountries() {
    this.valueDomains.forEach((domain: any) => {
      this.domainModel[domain.domainName] = {};
      const countryObj: any = {};
      domain.valueAttributes.forEach((CountryWiseValAttribute: any) => {
        const scorewithDesc: any = {};
        const country = CountryWiseValAttribute.country;
        countryObj[country] = {};
        if (
          country.toLowerCase().trim() !== 'all' &&
          !this.countries.includes(country.toLowerCase().trim())
        ) {
          this.countries.push(country.toLowerCase().trim());
        }
        CountryWiseValAttribute.value.forEach((ele: any, index: any) => {
          scorewithDesc[index] = {};
          scorewithDesc[index]['defaultScore'] = ele.score;
          let resp: any = {};
          if (ele.score >= 1) {
            resp = ele.questionScores.find((s: any) => s.score === ele.score);
          } else {
            resp = ele.questionScores.find(
              (s: any) => s.score === ele.score || s.score === '-10 to 10'
            );
          }
          scorewithDesc[index]['defaultDescription'] = resp?.description ?? '';
        });
        countryObj[country] = { ...scorewithDesc };
      });
      // domain.valueAttributes.sort((a: any, b: any) => a.country - b.country)
      this.domainModel[domain.domainName] = { ...countryObj };
    });
  }

  private formatAnalogueToFlatData() {
    this.valueDomains.forEach((domain: any) => {
      domain.valueAttributes.forEach((CountryWiseValAttribute: any) => {
        CountryWiseValAttribute.value.forEach((valueAttribute: any) => {
          //silsrang
          // remove variable "number = 1000" and replace number it with valueAttribute.score
          let number=valueAttribute.score;
          valueAttribute.formType = valueAttribute.valueAttributeName.includes('Patient number')?this.updateFrameworkService.isDropDown?'dropdown':number.includes('-')?'range':'value':'dropdown';
          if(valueAttribute.formType === 'value'){
            valueAttribute.value=number;
            valueAttribute.error='';

          }else if(valueAttribute.formType === 'range'){
            let range = number.split('-');
            valueAttribute.rangeStart=range[0];
            valueAttribute.rangeEnd=range[1];
            valueAttribute.rangeStarterror='';
            valueAttribute.rangeEnderror='';
            

          }
          if (valueAttribute.country.toLowerCase() === 'all') {
            this.countries.forEach((country) => {
              const assetScore = {
                AssetId: this.assetData.id,
                Name: this.assetData.name,
                ValueDomainId: domain.id,
                ValueAttributeId: valueAttribute.id,
                Score: valueAttribute.score,
                Value: valueAttribute.value,
                Country: Capitalise(country),
              };
              this.assetDynamicService.addToDefaultAssetData(assetScore);
            });
          } else {
            const assetScore = {
              AssetId: this.assetData.id,
              Name: this.assetData.name,
              ValueDomainId: domain.id,
              ValueAttributeId: valueAttribute.id,
              Score: valueAttribute.score,
              Value: valueAttribute.value,
              Country: Capitalise(valueAttribute.country),
            };
            this.assetDynamicService.addToDefaultAssetData(assetScore);
          }
        });
      });
    });
    // this.assetDynamicService.copyDefaultToUpdatedAssetScore();
  }

  scoreChanged(score: any, questionScores: any, attribute: any, domain: any) {
    if (score) {
      let selectedDescription = '';
      if (Array.isArray(questionScores)) {
        selectedDescription = questionScores.find(
          (q) => q.score === score
        )?.description;
      }

      if (attribute.valueAttributeName === 'Patient number' && !this.updateFrameworkService.isDropDown) {
        selectedDescription = `e^${score} = ${Math.exp(score).toFixed(0)}`;
      }

      if (this.assetDynamicService.getUpdatedAssetData().length === 0) {
        this.assetDynamicService.copyDefaultToUpdatedAssetScore();
      }
      if (attribute.country.toLowerCase() === 'all') {
        this.countries.forEach((country) => {
          const assetScore = {
            AssetId: this.assetData.id,
            Name: this.assetData.name,
            ValueDomainId: domain.id,
            ValueAttributeId: attribute.id,
            Score: score,
            Value: attribute.value,
            Country: Capitalise(country),
          };
          this.assetDynamicService.addOrUpdateAssetData(
            domain,
            attribute,
            country,
            assetScore
          );
        });
      } else {
        const assetScore = {
          AssetId: this.assetData.id,
          Name: this.assetData.name,
          ValueDomainId: domain.id,
          ValueAttributeId: attribute.id,
          Score: score,
          Value: attribute.value,
          Country: Capitalise(attribute.country),
        };
        this.assetDynamicService.addOrUpdateAssetData(
          domain,
          attribute,
          attribute.country,
          assetScore
        );
      }

      return selectedDescription;
    }
    return '';
  }

  spanScoreChanged(score: any, questionScores: any, attribute: any) {
    const selectedDescription = '';

    if (attribute !== null && attribute !== undefined) {
      if (Array.isArray(questionScores)) {
        const selectedQuestionScore = questionScores.find((q) => {
          if (q.score === score) {
            return q;
          } else if (q.score.includes('to')) {
            const arrScore = q.score.split('to');
            if (arrScore.length === 2) {
              const min = arrScore[0];
              const max = arrScore[1];
              if (+score >= +min && +score <= +max) {
                return q;
              } else {
                return undefined;
              }
            } else {
              return undefined;
            }
          } else {
            return undefined;
          }
        });

        if (selectedQuestionScore) {
          if (attribute.score !== selectedQuestionScore.score) {
            attribute.score = selectedQuestionScore.score;
          }
        }
        return selectedQuestionScore ? selectedQuestionScore.description : '';
      }
    }
  }

  splitValue(value: any) {
    const valueArray = value.split(' ');
    return valueArray;
  }

  private Sort() {
    for (let i = 0; i < this.valueDomains.length; i++) {
      this.valueDomains[i].valueAttributes = this.groupBy(
        this.valueDomains[i].valueAttributes,
        'country'
      );
      const sortedObject: any[] = [];
      for (const [key, value] of Object.entries(
        this.valueDomains[i].valueAttributes
      )) {
        sortedObject.push({
          country: key,
          value,
        });
      }
      this.shiftAllUp(sortedObject);

      this.valueDomains[i].valueAttributes = JSON.parse(
        JSON.stringify([...sortedObject])
      );
    }

  }

  public sortValueAttributeScores(): void {
    this.valueDomains.forEach((valueDomain: any) => {
      valueDomain.valueAttributes.sort((a: any, b: any) => {
        if (a.country > b.country) {
          return 1;
        }
        if (a.country < b.country) {
          return -1;
        }
        return 0;
      });
      valueDomain.valueAttributes.forEach((valueAttribute: any) => {
        valueAttribute.value.forEach((value: any) => {
          value.questionScores.sort((a: any, b: any) => a.score - b.score);
        });
      });
    });
  }

  private shiftAllUp(sortedObject: any[]) {
    const fromIndex = sortedObject.findIndex(
      (element) => element.country === 'All'
    );

    if (fromIndex > 0) {
      const toIndex = 0;
      const allElement = sortedObject.splice(fromIndex, 1)[0];

      sortedObject.splice(toIndex, 0, allElement);
    }
  }

  private groupBy(objectArray: any, property: string) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  getFlagId(country: string) {
    return this.countryFlagService.getFlagId(country);
  }

  private normalisedInput(assetTppData: any) {
    if (assetTppData) {
      const projectId = assetTppData.projectId;
      const assetTLIs = assetTppData.assetTLIs as [];
      assetTLIs.forEach((e) => {
        const assetId = e['id'];
        const assetName = e['brandName'];

        const valueDomains = e['valueDomains'] as [];
      });
    }
  }

  getDefaultScore(attribute: any, domain: any) {
    const country =
      attribute.country.toLowerCase() === 'all'
        ? this.countries[0]
        : attribute.country;
    const defaultScoreIndex = this.assetDynamicService
      .getDefaultAssetData()
      .findIndex(
        (f) =>
          f.ValueDomainId === domain.id &&
          f.ValueAttributeId === attribute.id &&
          f.Country.toLowerCase().trim() === country.toLowerCase().trim()
      );
    const score =
      this.assetDynamicService.getDefaultAssetData()[defaultScoreIndex].Score;
    return score;
  }

  getExpValue(input: string) {
    return `e^${input} = ${Math.exp(parseInt(input, 10)).toFixed(0)}`;
  }
  onScoreChange(
    ind: number,
    score: any,
    questionScores: any,
    attribute: any,
    domain: any,
    formType?:any,
    Altrange?: any,
    rangeType?: any,
  ) {
    const resp = questionScores.find((x: any) => x.score === score);
    this.domainModel[domain.domainName][attribute.country][
      ind
    ].defaultDescription = resp?.description;
    let error = false;
    if (this.assetDynamicService.getUpdatedAssetData().length === 0) {
      this.assetDynamicService.copyDefaultToUpdatedAssetScore();
    }
    if (attribute.country.toLowerCase() === 'all') {
      this.countries.forEach((country) => {
        const assetScore = {
          AssetId: this.assetData.id,
          Name: this.assetData.name,
          ValueDomainId: domain.id,
          ValueAttributeId: attribute.id,
          Score: score,
          Value: attribute.value,
          Country: Capitalise(country),
        };
        this.assetDynamicService.addOrUpdateAssetData(
          domain,
          attribute,
          country,
          assetScore
        );
      });
    } else {
      const assetScore = {
        AssetId: this.assetData.id,
        Name: this.assetData.name,
        ValueDomainId: domain.id,
        ValueAttributeId: attribute.id,
        Score: '',
        Value: attribute.value,
        Country: Capitalise(attribute.country),
      };
      switch (formType) {
        case 'dropdown':
          assetScore.Score =score;
          break;
          case 'value':
            assetScore.Score =score.value;
              attribute.error = this.setValueError(score.value,attribute.valueAttributeName);
              error = attribute.error === ''?false:true;
          break;
          case 'range':
            if(rangeType ==='start'){
              assetScore.Score = score.value + '-' + Altrange;
              attribute.rangeStart = score.value;
              attribute.rangeStarterror = this.setRangeError(score.value,Altrange,rangeType,attribute.valueAttributeName);
              attribute.rangeEnderror = this.setRangeError(score.value,Altrange,'end',attribute.valueAttributeName);
              error = attribute.rangeStarterror === ''?false:true;

            }else{
              assetScore.Score = Altrange + '-' + score.value;
              attribute.rangeEnd = score.value;
              attribute.rangeStarterror = this.setRangeError(Altrange,score.value,'start',attribute.valueAttributeName);
              attribute.rangeEnderror = this.setRangeError(Altrange,score.value,rangeType,attribute.valueAttributeName);
              error = attribute.rangeEnderror === ''?false:true;

            }
          break;
        default:
          break;
      }
      if(!error){
        this.assetDynamicService.addOrUpdateAssetData(
          domain,
          attribute,
          attribute.country,
          assetScore
        );
      }

    }
    if(!error){
      this.headerService.valueScoreChanged.next(true);
    }

  }

  private setValueError(value:any,attributeNAme:string):string{
    let error:string='';
    let maxValue = 0;
    if(attributeNAme.includes('USA')){
      maxValue = 200000
    }else{
      maxValue = 20000
    }
    if(value === ''){
      error= attributeNAme + ' is required';
    }else if(JSON.parse(value) <=0){
      error= attributeNAme + ' must be greater than 0';
    }else if(JSON.parse(value) > maxValue){
      error= attributeNAme + ' cannot be more than ' + maxValue;
    } 
    return error;
  } 

  private setRangeError(start:any,end:any,rangeType:string,attributeNAme:string):string{
    let error:string='';
    let maxValue = 0;
    if(attributeNAme.includes('USA')){
      maxValue = 200000
    }else{
      maxValue = 20000
    }
    if(rangeType==='start'){
      if(start === ''){
        error= attributeNAme + ' is required';
      }else if(JSON.parse(start) <=0){
        error= attributeNAme + ' "from" must be greater than 0';
      }else if(JSON.parse(start) > maxValue){
        error= attributeNAme + ' "from" value cannot be more than ' + maxValue;
      } else if(end===''){
        error= attributeNAme + ' "to" value is also required'
    } else if(parseFloat(start) === parseFloat(end)){
        error= attributeNAme + ' "from" and "to"  value  cannot be the same';
      }else if(parseFloat(start) > parseFloat(end)){
        error= attributeNAme +' "from" value cannot be more than the "to" value';
      }
    }else{
      if(end === ''){
        error= attributeNAme + ' is required';
      }else if(JSON.parse(end) <=0){
        error= attributeNAme + ' "to" must be greater than 0';
      } else if(JSON.parse(end) > maxValue){
        error= attributeNAme + ' "to" value cannot be more than '+ maxValue;
      }else if(start===''){
        error=  attributeNAme +' "from" value is also required'
      } else if(parseFloat(start) === parseFloat(end)){
        error= attributeNAme + ' "from" and "to"  value  cannot be the same';
      }else if(parseFloat(end) < (parseFloat(start))){
        error= attributeNAme + ' "to" value cannot be less than the "from" value';
      }
    }
    return error;
  } 

  onDescriptionChanged(
    ind: number,
    description: any,
    questionScores: any,
    attribute: any,
    domain: any
  ) {
    const resp = questionScores.find((x: any) => x.description === description);
    this.domainModel[domain.domainName][attribute.country][ind].defaultScore =
      resp.score;

    if (this.assetDynamicService.getUpdatedAssetData().length === 0) {
      this.assetDynamicService.copyDefaultToUpdatedAssetScore();
    }
    if (attribute.country.toLowerCase() === 'all') {
      this.countries.forEach((country) => {
        const assetScore = {
          AssetId: this.assetData.id,
          Name: this.assetData.name,
          ValueDomainId: domain.id,
          ValueAttributeId: attribute.id,
          description,
          Score: resp.score,
          Value: attribute.value,
          Country: Capitalise(country),
        };
        this.assetDynamicService.addOrUpdateAssetData(
          domain,
          attribute,
          country,
          assetScore
        );
      });
    } else {
      const assetScore = {
        AssetId: this.assetData.id,
        Name: this.assetData.name,
        ValueDomainId: domain.id,
        ValueAttributeId: attribute.id,
        description,
        Score: resp.score,
        Value: attribute.value,
        Country: Capitalise(attribute.country),
      };

      this.assetDynamicService.addOrUpdateAssetData(
        domain,
        attribute,
        attribute.country,
        assetScore
      );
    }
    this.headerService.valueScoreChanged.next(true);

  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
