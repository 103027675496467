<div *ngIf="valueDomains.length !== 0">
  <div class="card-wrapper" id="tpp-card" *ngFor="let domain of valueDomains">
    <mat-card class="asset-card mat-card">
      <mat-card-header class="mat-card-header">
        <span class="heading">{{ domain.domainName }} </span>
      </mat-card-header>
      <mat-card-content class="mat-card-content">
        <div *ngFor="let valueAttribute of domain?.valueAttributes">
          <div *ngIf="valueAttribute.country !== 'All'" class="country-flag">
            <img
              class="flag"
              src="{{
                'assets/flags/small/' +
                  getFlagId(valueAttribute?.country) +
                  '.svg'
              }}"
              width="20px"
            />
            {{ valueAttribute.country }}
          </div>
          <div
            class="asset-tpp-row"
            *ngFor="let attribute of valueAttribute?.value; let i = index"
          >
            <div class="asset-tpp-row-label">
              <p>
                <span class="attribute-name"
                  >{{ attribute.valueAttributeName }}
                </span>
                <!-- <span
                *ngIf="attribute.country && attribute.country !== 'All'"
                class="country"
                >({{ attribute.country }})</span
              > -->
              </p>
              <div>
                <mat-icon
                  *heFeatureFlag="{ Name: 'asset-tpp-info', Page: 'Asset-Tpp' }"
                  #tooltip="matTooltip"
                  matTooltip="{{ attribute.valueAttributeInfoIconData }}"
                  matTooltipClass="horizon-tooltip"
                  >info</mat-icon
                >
              </div>
            </div>
            <!-- <div>
              <mat-form-field appearance="fill">
                <input matInput placeholder="Placeholder">
              </mat-form-field>
            </div> -->
            <div
              class="asset-tpp-row-value"
              *ngIf="attribute.formType === 'dropdown'"
            >
              <div class="form-score">
                  <mat-form-field appearance="fill" class="width-100">
                    <mat-select
                    name="questionScore"
                    class=""
                    [(ngModel)]="
                      domainModel[domain.domainName][valueAttribute.country][
                        i
                      ].defaultScore
                    "
                    (selectionChange)="
                      onScoreChange(
                        i,
                        $event.value,
                        attribute.questionScores,
                        attribute,
                        domain,
                        attribute.formType
                      )
                    "
                  >
                    <mat-option
                      class=""
                      *ngFor="let questionScore of attribute.questionScores"
                      [value]="questionScore.score"
                    >
                      {{ questionScore.score }}
                    </mat-option>
                  </mat-select>
                  </mat-form-field>
              </div>
              <div class="form-decsrpt">
                  <mat-form-field appearance="fill" class="width-100">
                    <mat-select
                      name="questionScore1"
                      [(ngModel)]="
                        domainModel[domain?.domainName][
                          valueAttribute?.country
                        ][i].defaultDescription
                      "
                      (selectionChange)="
                        onDescriptionChanged(
                          i,
                          $event.value,
                          attribute.questionScores,
                          attribute,
                          domain
                        )
                      "
                    >
                      <mat-option
                        *ngFor="let questionScore1 of attribute.questionScores"
                        [value]="questionScore1.description"
                      >
                        {{ questionScore1.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>
            <div
              class="asset-tpp-row-value"
              *ngIf="attribute.formType === 'value'"
            >
              <div class="form-value">
                    <mat-form-field  appearance="fill" class="width-100">
                      <input
                        name="patientNumber2"
                        type="number"
                        matInput
                        heBlockDecimal
                        heOnlyNumber
                        [value]="attribute.value"
                        (keyup)="
                        onScoreChange(
                          i,
                          $event.target,
                          attribute.questionScores,
                          attribute,
                          domain,
                          attribute.formType
                        )"
                      />
                    </mat-form-field>
      <mat-error>{{attribute.error}}</mat-error>
                    
              </div>
            </div>
            <div
            class="asset-tpp-row-value"
            *ngIf="attribute.formType === 'range'"
          >
          <div class="form-range">
            <div>
            <div style="display: flex;align-items: center;">
              <p class="from">From</p>
              <mat-form-field  appearance="fill" class="width-100">
                <input
                  name="patientNumber2"
                  type="number"
                  matInput
                  heBlockDecimal
                  heOnlyNumber
                  [value]="attribute.rangeStart"
                  (keyup)="
                  onScoreChange(
                    i,
                    $event.target,
                    attribute.questionScores,
                    attribute,
                    domain,
                    attribute.formType,
                    attribute.rangeEnd,
                    'start'
                  )"
                />
              </mat-form-field>
            </div>
      <mat-error class="fromerror">{{attribute.rangeStarterror}}</mat-error>

          </div>

           <div>
            <div style="display: flex;align-items: center;">
              <p class="to">To</p>
              <mat-form-field  appearance="fill" class="width-100">
                <input
                  name="patientNumber2"
                  type="number"
                  matInput
                  heBlockDecimal
                  heOnlyNumber
                  [value]="attribute.rangeEnd"
                  (keyup)="
                  onScoreChange(
                    i,
                    $event.target,
                    attribute.questionScores,
                    attribute,
                    domain,
                    attribute.formType,
                    attribute.rangeStart,
                    'end'
                  )"
                />
              </mat-form-field>
            </div>
      <mat-error class="toerror">{{attribute.rangeEnderror}}</mat-error>

          </div>


      </div>
          </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
