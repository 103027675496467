import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'he-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent {

  constructor(private headerService: HeaderService) {}

  tabClick(tab: any) {
    this.headerService.setActiveTabName(tab.tab.textLabel);
  }

}
