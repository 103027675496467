<div *ngIf="!showSection">
  <he-loading-spinner
    [diameter]="70"
    [height]="'350px'"
  ></he-loading-spinner>
</div>
<div *ngIf="showSection" class="card-he">
  <mat-card>
    <div class="heading">
      Analogues
    </div>
    <mat-card-content>
      <div class="analog-table table-responsive">
        <mat-table [dataSource]="dataSource.data">

          <ng-container matColumnDef="sequenceNo" class="hideCls">
            <mat-header-cell *matHeaderCellDef class="sequenceNo hideCls">
              Id
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="sequenceNoRow hideCls">
              {{ element.sequenceNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="brandName">
            <mat-header-cell *matHeaderCellDef class="brandName">
              Brand Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="brandNameRow">
              {{ element.brandName }}
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="indication">
            <mat-header-cell *matHeaderCellDef class="indication">
              Indication
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="indicationRow">
              {{ element.indication }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mechanism">
            <mat-header-cell *matHeaderCellDef class="mechanism">
              Mechanism of Action
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mechanismRow">
              {{ element.mechanism }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            [ngClass]="{ highlight: row?.highlight === true }"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>