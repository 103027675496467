import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../../shared/shared.module';
import { ProjectAnalogueComponent } from './components/project-analogue/project-analogue.component';
import { ProjectAnalogueCotGridComponent } from './component-pieces/project-analogue-cot-grid/project-analogue-cot-grid.component';
import { ProjectAnalogueSelectionCotChartComponent } from './component-pieces/project-analogue-selection-cot-chart/project-analogue-selection-cot-chart.component';
import { CorridorChartModule } from '../../../../uikit/corridor-widget';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '../../../../uikit/loading-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProjectAnalogSubGridComponent } from './component-pieces/project-analog-sub-grid/project-analog-sub-grid.component';
import { IconModule } from 'projects/helios-gui/src/uikit/helios-icons';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProjectAnaloguePricePerUnitChartComponent } from './component-pieces/project-analogue-price-per-unit-chart/project-analogue-price-per-unit-chart.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AngalogueTimelineComponent } from './component-pieces/angalogue-timeline/angalogue-timeline.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    ProjectAnalogueComponent,
    ProjectAnalogueCotGridComponent,
    ProjectAnalogueSelectionCotChartComponent,
    ProjectAnalogSubGridComponent,
    ProjectAnaloguePricePerUnitChartComponent,
    AngalogueTimelineComponent,
  ],
  providers:[CurrencyPipe,DatePipe],
  exports: [ProjectAnalogueComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    SharedModule,
    // CorridorChartModule,
    FormsModule,
    LoadingSpinnerModule,
    MatTooltipModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }), MatButtonToggleModule, MatTableExporterModule, IconModule,MatMenuModule,
  ]
})
export class AnalogueModule { }
