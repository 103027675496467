import {
  Component,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import {   Observable,  Subject } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { TLI } from '../../../../horizon-project/models/TLI';
import { MasterService } from '../../../../horizon-project/services/master.service';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  takeUntil,
  take,
  tap,
} from 'rxjs/operators';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ActivatedRoute } from '@angular/router';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { ProjectService } from '../../services/project.service';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { ProjectDraftService } from '../../services/project-draft.service';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { CollaboratorComponent } from '../collaborator/collaborator.component';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { NetPriceService } from '../../services/net-price.service';
import {
  marketAllCountries,
  marketCountries,
  marketEU4Countries,
} from '../enums';
import { noSpecialCharactersValidator } from '../../../../shared/custom-validators/noSpecialCharactersValidator';
import { ProjectNameValidator } from '../../../../shared/custom-validators/projectNameValidator';
import { minLengthValidator } from '../../../../shared/custom-validators/minLengthValidator';
import { maxLengthValidator } from '../../../../shared/custom-validators/maxLengthValidator';
import { AnalogueMasterData } from '../../../../horizon-project/models/AnalogueMaseterData';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';
import { formatDate } from '@angular/common';
export interface ValueFrameworks {
  valueFrameworkId: string;
  valueFrameworkName: string;
  createdOn?: any;
  isDeleted?: any;
}
@Component({
  selector: 'he-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  // ---------------Create Project Methods----------------
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('fileDropRef') public fileDropRef: any;
  @ViewChild(CollaboratorComponent)
  public collaboratorComp!: CollaboratorComponent;

  public ValueFrameworks: ValueFrameworks[] = [];
  public ValueFrameworksView: ValueFrameworks[] = [];
  public Countries: any = [];
  public selectedCountry: any = 'Country';
  public selectedMarket: any = [];
  public selectedFramework: any = {};
  public editProjectId: any = '';
  public urlProjectId: any = '';

  public TLIData: TLI[] = [];
  public Indications: string[] = [];
  public MOA: string[] = [];
  public IndicationsAndMoas: string[] = [];


  files: any[] = [];
  analogueColumns: string[] = [
    'brandName',
    'indication',
    'mechanismOfAction',
    'remove',
  ];
  fileName: string[] = ['doc', 'docx', 'ppt', 'pptx', 'pdf'];
  public btnDisabled = true;
  public loading = true;

  EU4Countries: string[] = ['France', 'Germany', 'Italy', 'Spain'];

  filteredTLIs: Observable<any[]> = new Observable<any[]>();
  filteredMarkets: any[] = [];
  filteredMarketsTemp: any[] = [];

  projectForm = new FormGroup({
    projectName: new FormControl('', {
      validators: [
        Validators.required,
        minLengthValidator(5),
        //Validators.minLength(5),
        maxLengthValidator(200),
        //Validators.maxLength(200),
        noSpecialCharactersValidator(),
      ],
      asyncValidators: [
        //ProjectNameValidator.createValidator(this.projectService),
      ],
      //updateOn: 'blur',
    }),
    assetName: new FormControl('', [
      Validators.required,
      minLengthValidator(5),
      //Validators.minLength(5),
      maxLengthValidator(200),
      //Validators.maxLength(200),
      noSpecialCharactersValidator(),
    ]),
    topLevelIndication: new FormControl('', [
      Validators.required,
      minLengthValidator(5),
      //Validators.minLength(5),
      noSpecialCharactersValidator(),
    ]),
    moa: new FormControl('', [
      //Validators.required,
      minLengthValidator(5),
      //Validators.minLength(5),
      noSpecialCharactersValidator(),
    ]),
    framework: new FormControl('', [Validators.required]),
    projectDetails: new FormControl('', [
      //Validators.required,
      maxLengthValidator(200),
      //Validators.maxLength(200),
      noSpecialCharactersValidator(),
    ]),
    markets: new FormControl(''),
    selectedMarkets: new FormControl([], [Validators.required]),
    innName: new FormControl(''),
  });
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public projectId = '';
  fileListData: any = [];
  NewFileList: any = [];
  public selectedTLIvalue: any;
  public selectedValueFrameworks: any;
  public SelectedValueFrameworkId: any;
  public FeatureFlagsData: any = [];
  public createProjectTLI = '';
  public isDraftProjectExist: boolean = false;
  private unsubscriber$ = new Subject<void>();
  public MasterAnalogueData: AnalogueMasterData[] = [];
  public isProjectNameValid: boolean = true;

  constructor(
    public activatedRouter: ActivatedRoute,
    private projectService: ProjectService,
    private snackBar: MatSnackBar,
    private masterService: MasterService,
    private analogueService: AnalogueService,
    private toastNotificationService: ToastNotificationService,
    private projectDraftService: ProjectDraftService,
    private criteriaSelectionService: CriteriaSelectionService,
    private featureFlagService: FeatureFlagService,
    private countryFlagService: CountryFlagService,
    private netPriceService: NetPriceService,
    private currencyConversionService: CurrencyConversionService,
    public updateFrameworkService: UpdateFrameworkService,
  ) {
    this.projectService.getFeatureFlags().then((data) => {
      this.FeatureFlagsData = data;
    });

    //this.projectForm.controls.assetName.

    this.projectForm.controls.markets.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => this._filterMarkets(value || ''))
        // map(filteredMarkets => [{ id:'all', name: 'All' }, { id:'eu4', name: 'EU4' }, ...filteredMarkets]),
      )
      .subscribe((result) => {
        this.filteredMarkets = result;
        this.filteredMarketsTemp = this.filteredMarkets.filter(
          (x) => !this.selectedMarket.some((y: string) => x.name === y)
        );
      });

    this.projectService.castEditProjectId
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        if (data != undefined) {
          this.projectId = data;
        }
      });
    // this.filteredTLIs =
    //   this.projectForm.controls.topLevelIndication.valueChanges.pipe(
    //     startWith(''),
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     map((value) => this._filterTLIs(value || ''))
    //   );
  }

  ngOnInit(): void {
    this.getValueFrameworks();
if(!this.netPriceService.netErrorActive && !this.updateFrameworkService.frameWorkErrorActive){
  this.updateFrameworkService.setUpdateError()
}
    this.getMasterAnalogues();
    this.projectService.castEditProjectId
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.editProjectId = data;
      });
    this.featureFlagService.getFeatureFlagData().then((data) => {
      const isDraftFeature = data.find(
        (x: any) => x.name === 'project-save-as-draft' && x.page === 'common'
      );

      if (isDraftFeature?.isActive) {
        this.checkDraftProject();
      }

    });


    this.analogueService
      .getAnalogueMasterTLIs()
      .then((res) => {
        this.Indications = res;
        this.IndicationsAndMoas.push(...this.Indications);
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch analogue data',
          'info',
          10000
        );
      });
    this.analogueService
      .getAnalogueMasterMOAs()
      .then((res) => {
        this.MOA = res;
        this.IndicationsAndMoas.push(...this.MOA);
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch Mechanism of Action Data',
          'info',
          10000
        );
      });

    this.projectForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.projectService.createProjectFormData.next(data);
        this.projectService.isdetailsValid = this.projectForm.valid;
        if (this.editProjectId === '') {
          this.projectService.newValuechanged.next(true);
        }
        if (this.projectForm.valid) {
          this.projectService.projectFormStatus.next(true);
        }
      });



    this.projectService.castUploadFileList.pipe(take(1)).subscribe((res) => {
      this.fileListData = res;
    });

    this.projectService.validateProjectForm.subscribe((res) => {
      if (res) {
        this.validateForm();
      }
    });
  }

  public getMasterAnalogues(): void {
    const TLi = '';
    const MOA = '';
    this.criteriaSelectionService
      .getAnalogueMaster(TLi, MOA)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.MasterAnalogueData = res;
      });
  }


  /**
   * projectNameChanged
e   */
  public projectNameChanged(e: any) {
    if(this.projectId === ''){
      const projectName = e.target.value;
      if (projectName) {
        if (projectName.length >= 5) {
          this.projectService
            .verifyProjectName(projectName)
            .subscribe((result) => {
              if (result.data) {
                this.projectForm.controls.projectName.setErrors({
                  projectNameExists: true,
                });
                this.projectService.isdetailsValid = !result.data;
              }
            });
        }
      }
    }
  }

  get projectName(): AbstractControl<any, any> | null {
    return this.projectForm.get('projectName');
  }
  get moa(): AbstractControl<any, any> | null {
    return this.projectForm.get('moa');
  }
  get assetName(): AbstractControl<any, any> | null {
    return this.projectForm.get('assetName');
  }
  get topLevelIndication(): AbstractControl<any, any> | null {
    return this.projectForm.get('topLevelIndication');
  }
  get projectDetails(): AbstractControl<any, any> | null {
    return this.projectForm.get('projectDetails');
  }

  public validateForm() {
    if (this.projectForm.invalid) {
      this.projectForm.markAllAsTouched();
      return;
    }
  }
  public checkDraftProject() {
    this.projectDraftService.checkDraftProjectExist().then((res: any) => {
      if (res.data && !this.urlProjectId) {
        this.isDraftProjectExist = true;
        this.projectService.valueChangeStatus.next(true);
        //silrang
        this.projectService.checkScenarioStatus(false, false);
      }
    });
  }

  public getDraftProject() {
    if (
      !this.projectService.isDraftProjectFetched.value &&
      this.editProjectId === '' &&
      this.isDraftProjectExist
    ) {
      this.getDraftProjectData();
      this.projectService.isDraftProjectFetched.next(true);
    } else {
      this.activatedRouter.params.pipe(take(1)).subscribe((params) => {
        this.urlProjectId = params['id'];
      });
    if (this.urlProjectId) {
      if (!this.projectService.isProjectLoaded) {
        this.getProjectDetails();
      } else {
        this.getCreateProjectDetails();
        this.loading = false;
      }
    }else{
      this.getCreateProjectDetails();
      this.loading = false;
    }

    }
  }

  public getDraftProjectData() {
    this.projectDraftService.getDraftedProject().then((data: any) => {
      const draftData = JSON.parse(data.data);
      this.projectService.createProjectFormData.next(draftData);
      this.getCreateProjectDetails();

      draftData.projectCollaborators.forEach((data: any) => {
        this.projectService.ProjectCollaborators.push(data);
      });
      this.projectService.projectCollaboratorsDraftDataAvailable.next(true);

      this.criteriaSelectionService.criteriaList.next(
        draftData.analogueSelectionCriteria
      );

      // let analogueList: any = [];
      // draftData.projectAnalogues.forEach((item: any) => {
      //   if (
      //     draftData.projectAnalogues.filter((element: any) => {
      //       element.sequence === item.sequence;
      //     }).length === 0
      //   ) {
      //     analogueList.push({
      //       name: item.brandName,
      //       indication: item.indication,
      //       inn: item.inn,
      //       Market: item.Market,
      //       mechanismOfAction: item.mechanismOfAction,
      //       sequenceNo: item.sequenceNo,
      //       available: true,
      //     });
      //   }
      // });
      this.criteriaSelectionService.savedSelectedAnalogues.next(
        draftData.savedSelectedAnalogues
      );

      draftData.projectAnalogues.forEach((item: any) => {
        if (
          draftData.projectAnalogues.filter((element: any) => {
            element.sequence === item.sequence;
          }).length === 0
        ) {
          this.projectService.selectedAnalogues.push(item);
        }
      });
      if (draftData.projectScenarios.length != 0) {
        this.projectService.isScenariosValid = true;
      }
      draftData.projectScenarios.forEach((scenario1: any, index: number) => {
        if (scenario1?.scenarioDetails?.scenarioName !== '') {
          if (
            draftData.projectScenarios
              .filter((scenario2: any) => scenario2?.id !== scenario1?.id)
              .filter(
                (scenario2: any) =>
                  scenario2?.scenarioDetails?.scenarioName.toLowerCase() ===
                  scenario1?.scenarioDetails?.scenarioName.toLowerCase()
              ).length > 0
          ) {
            this.projectService.isScenariosNamesValid = false;
          } else {
            this.projectService.isScenariosNamesValid = true;
          }
        } else {
          this.projectService.isScenariosNamesValid = false;
        }
      });
      this.criteriaSelectionService.brandCountData.next(draftData.brandCOunt);
      this.criteriaSelectionService.isAnaloguesValid = true;
      this.netPriceService.selectedAnalogueNetprice =
        draftData.selectedAnalogueNetprice;
      this.netPriceService.ProjectNetPrice.IsNetPriceIncluded =
        draftData.projectNetPrice.IsNetPriceIncluded;
      this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded =
        draftData.projectNetPrice.isAnnualCOTInlcuded;
      this.netPriceService.ProjectNetPrice.OverallNetPrice =
        draftData.projectNetPrice.OverallNetPrice;
      this.netPriceService.ProjectNetPrice.AnalogueNetPrice =
        draftData.projectNetPrice.AnalogueNetPrice;
      // this.setNetPriceAssumtionDetails(draftData,true);
      this.projectDraftService.addDraftScenario(draftData.projectScenarios);
      //silrang
      this.projectService.checkScenarioStatus(true, false);
      this.loading=false;
    });
  }

  private async getValueFrameworks() {
    await this.projectService
      .getValueFrameworks()
      .then((data: ValueFrameworks[]) => {
        this.ValueFrameworks = data;
        //orphan changes for QA
        const filteredFrameworks = this.ValueFrameworks.filter(
          (option) =>
            option.valueFrameworkName.toLowerCase() === 'Orphan'.toLowerCase()
        ).sort((a,b)=> new Date(b.createdOn).getTime() -  new Date(a.createdOn).getTime());
        this.ValueFrameworks = filteredFrameworks;
//oncology changes for dev
        // const filteredFrameworks = data.filter((option)=> option.isDeleted === false).sort((a,b)=> new Date(b.createdOn).getTime() -  new Date(a.createdOn).getTime());
        //oncology changes
        filteredFrameworks.forEach(res=>{
          if(!this.ValueFrameworksView.some((x)=> x.valueFrameworkName === res.valueFrameworkName)){
            this.ValueFrameworksView.push(res);
          }
        })
        if(this.projectId  != ''){
          this.ValueFrameworksView = this.ValueFrameworks;
        }
        this.getTLI();
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch Value Frameworks Data',
          'info',
          10000
        );
      });
  }

  private getTLI() {
    this.masterService
      .getTli()
      .pipe(take(1))
      .subscribe(
        (data: TLI[]) => {
          this.TLIData = this.toSentanceCase(data);

          this.getDraftProject();
          // this.getCreateProjectDetails();
        },
        (err) => {
          console.log(err);
          this.toastNotificationService.errorMessage(
            'Unable to fetch TLI Data',
            'info',
            10000
          );
        }
      );
  }

  private _filter(value: string, array: []): string[] {
    const filterValue = value.toLowerCase();
    return array.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  countriesDataAll: any[] = [];
  countriesData: any[] = [];
  _filterMarkets(val: string): Observable<any[]> {
    return this.projectService.getCountriesData().pipe(
      tap((response) => {
        this.countriesData = response;

        // Include 'All' and 'EU4' items in the countriesData variable
        this.countriesDataAll = [
          { id: 'all', name: 'All' },
          { id: 'eu4', name: 'EU4' },
          ...response,
        ];
      }),
      map(() =>
        this.countriesDataAll.filter((option: any) => {
          const USAFramework = 'united states';
          if (!this.FeatureFlagsData.isActive) {
            return (
              option.name.toLowerCase().includes(val.toLowerCase()) &&
              option.name.toLowerCase() !== USAFramework
            );
          } else {
            return option.name.toLowerCase().includes(val.toLowerCase());
          }
        })
      )
    );
  }
  _filterTLIs(val: string): any[] {
    const filterVal = val.toLowerCase();
    return this.TLIData.filter((x) => x.name.toLowerCase().includes(filterVal));
  }

  displayTliFn(data: string): string {
    return data;
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler($event?: any) {
    this.prepareFilesList($event.target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileListData.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   * @param Bytes (Bytes in KB, MB, GB)
   * @param decimals (Decimals point in size)
   */
  prepareFilesList(files: Array<any>) {
    const maxFIleSize = 5000000;
    if (this.projectId !== '') {
      this.projectService.valueChangeStatus.next(true);
    }
    for (const item of files) {
      if (!this.checkDuplicateFile(item)) {
        if (this.fileName.includes(item.name.split('.').pop())) {
          if (item.size <= maxFIleSize) {
            item.progress = 0;
            this.files.push(item);
            this.fileListData.push(item);
          } else {
            this.toastNotificationService.errorMessage(
              'File size needs to be less than 5mb'
            );
          }
        } else {
          this.toastNotificationService.errorMessage('File type not supported');
        }
      } else {
        this.toastNotificationService.errorMessage(
          'The selected file already exsist in the project'
        );
      }
    }
    this.projectService.uploadFileList.next(this.fileListData);
    //silrang
    this.projectService.checkScenarioStatus(false, false);
    this.fileDropRef.nativeElement.value = '';
  }

  public checkDuplicateFile(file: any): boolean {
    let duplicate = false;
    this.NewFileList.forEach((element: any) => {
      if (element?.name === file.name) {
        duplicate = true;
      }
    });
    this.fileListData.forEach((element: any) => {
      if (element?.name === file.name) {
        duplicate = true;
      }
    });
    return duplicate;
  }

  openSnackBar(resp: any) {
    this.snackBar.openFromComponent(NotificationsComponent, {
      data: resp,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
      panelClass: ['blue-snackbarBG'],
    });
  }

  downloadFileFunction(event: any, fname: any) {
    this.masterService
      .downloadFileServie(this.projectId, fname)
      .subscribe((response) => {
        const blob: Blob = response.body as Blob;
        var a = document.createElement('a');
        let fileURL = URL.createObjectURL(blob);
        a.href = fileURL;
        a.download = fname;
        a.click();
      });
  }

  downloadFile(event: any, file: any) {
    const blob: Blob = file as Blob;
    var a = document.createElement('a');
    let fileURL = URL.createObjectURL(blob);
    a.href = fileURL;
    a.download = file.name;
    a.click();
  }

  openFile(event: any, file: any) {
    const blob: Blob = file as Blob;
    let newfile = new Blob([blob], { type: 'application/pdf' });
    let fileURL = URL.createObjectURL(blob);
    if (file.name.includes('pdf')) {
      window.open(fileURL);
    } else {
      var a = document.createElement('a');
      a.href = fileURL;
      a.download = file.name;
      a.click();
    }
  }

  openFileFunction(event: any, fname: any) {
    this.masterService
      .downloadFileServie(this.projectId, fname)
      .subscribe((response) => {
        const blob: Blob = response.body as Blob;
        let file = new Blob([blob], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        if (fname.includes('pdf')) {
          window.open(fileURL);
        } else {
          var a = document.createElement('a');
          a.href = fileURL;
          a.download = fname;
          a.click();
        }
      });
  }

  getFileList() {
    if (this.urlProjectId) {
      this.masterService.getFileListServie(this.urlProjectId).then((data) => {
        this.NewFileList = data;
      });
    }
  }

  formatBytes(bytes?: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onMarketsRemoved(market: string) {
    if (this.projectId === '') {
      this.removeFirst(this.selectedMarket, market);
      this.projectForm.controls.selectedMarkets.setValue(this.selectedMarket);
      let defaultList: any[] =
        this.projectService.createProjectFormData.value.selectedMarkets;
      this.netPriceService.selectedMarkets = this.selectedMarket;
      if (defaultList.length === this.selectedMarket.length) {
        this.projectService.valueChangeStatus.next(true);
      }

      //
      //include removed items into ddl item
      if (this.filteredMarketsTemp !== null) {
        const includesAll = this.filteredMarketsTemp.some(
          (item) => item.name === 'All'
        );
        const includesMarket = this.filteredMarketsTemp.some(
          (item) => item.name === market
        );

        if (!includesAll) {
          // Add 'All' to the array
          this.filteredMarketsTemp.push({ name: 'All' });
        }

        if (!includesMarket) {
          // Add the market to the array
          this.filteredMarketsTemp.push({ name: market });
        }
      }

      //

      //remove 'ALL' from ddl , if all countries selected

      if (this.filteredMarketsTemp !== null) {
        const allCountries = Object.values(marketAllCountries);
        const includesAll = this.filteredMarketsTemp.some(
          (item) => item.name === marketCountries.All
        );

        if (includesAll) {
          // Check if all other countries are present
          const allCountriesPresent = allCountries.every((country) =>
            this.selectedMarket.some((item: string) => item === country)
          );

          if (allCountriesPresent) {
            // Remove 'All' from the array
            const index = this.filteredMarketsTemp.findIndex(
              (item) => item.name === marketCountries.All
            );
            if (index !== -1) {
              this.filteredMarketsTemp.splice(index, 1);
            }
          }
        }
      }

      //

      //remove eu4 from ddl, if all france, germany,italy,spain countries removed from selected list

      if (this.filteredMarketsTemp !== null) {
        const eu4Countries = Object.values(marketEU4Countries);
        const includesEU4 = this.filteredMarketsTemp.some(
          (item) => item.name === marketCountries.EU4
        );

        if (includesEU4) {
          // Check if EU4 countries are present
          const eu4CountriesPresent = eu4Countries.every((country) =>
            this.filteredMarketsTemp.some((item: string) => item === country)
          );

          if (eu4CountriesPresent) {
            // Remove 'EU4' from the array
            const index = this.filteredMarketsTemp.findIndex(
              (item) => item.name === marketCountries.EU4
            );
            if (index !== -1) {
              this.filteredMarketsTemp.splice(index, 1);
            }
          }
        } else {
          //include eu4 in ddl if all eu4 countries removed
          const iseu4CountriesPresent = eu4Countries.some((country) =>
            this.filteredMarketsTemp.some((item: any) => item.name === country)
          );

          if (iseu4CountriesPresent) {
            this.filteredMarketsTemp.push({ name: marketCountries.EU4 });
          }
        }
      }
      this.filteredMarketsTemp.sort((item1, item2) => {
        return (
          this.filteredMarkets.findIndex((item) => item.name === item1.name) -
          this.filteredMarkets.findIndex((item) => item.name === item2.name)
        );
      });

      //
    }
    if (this.selectedMarket.length === 0) {
      this.projectService.isdetailsValid = false;
      this.projectService.selectedAnalogues = [];
      this.criteriaSelectionService.isAnaloguesValid = false;
      this.criteriaSelectionService.savedSelectedAnalogues.next([]);
      this.criteriaSelectionService.savedTableData.next([]);
      this.projectService.newValuechanged.next(true);
    }
    //silrang
    this.projectService.checkScenarioStatus(true, false);
  }

  onMarketSelect() {
    if (this.selectedMarket.includes(this.projectForm.controls.markets.value)) {
      this.projectForm.controls.markets.setValue('');
      return;
    }
    if (this.projectForm.controls.markets.value === marketCountries.All) {
      this.selectedMarket.push(
        ...this.countriesData
          .map((country) => country.name)
          .filter((countryName) => !this.selectedMarket.includes(countryName))
      );
      this.filteredMarketsTemp = []; //if 'All' selected , remove all items from ddl
    } else if (
      this.projectForm.controls.markets.value === marketCountries.EU4
    ) {
      this.selectedMarket.push(
        ...this.EU4Countries.map((country) => country).filter(
          (countryName) => !this.selectedMarket.includes(countryName)
        )
      );
      //if eu4 selected, show all, uk, us

      // Remove enum countries from the array
      this.filteredMarketsTemp = this.filteredMarketsTemp.filter(
        (item) => !Object.values(marketEU4Countries).includes(item.name)
      );

      this.filteredMarketsTemp = this.filteredMarketsTemp.filter(
        (item) => !marketCountries.EU4.includes(item.name)
      );

      const allEnumValuesExist = Object.values(marketAllCountries).every(
        (value) => this.selectedMarket.includes(value)
      );
      // If all values exist, make it an empty array
      if (allEnumValuesExist) {
        this.filteredMarketsTemp = [];
      }
    } else {
      this.selectedMarket.push(this.projectForm.controls.markets.value);

      if (this.filteredMarketsTemp !== null) {
        const marketsToRemove = this.projectForm.controls.markets.value;

        //remove selected country from ddl item
        this.filteredMarketsTemp = this.filteredMarketsTemp.map((item) => ({
          ...item,
          included: !marketsToRemove?.includes(item.name),
        }));
        // If you want to filter out items based on the 'included' property
        this.filteredMarketsTemp = this.filteredMarketsTemp.filter(
          (item) => item.included
        );
        ////

        //if all eu4 countries included, remove eu4 item as well
        const eu4Countries = Object.values(marketEU4Countries);
        const eu4CountriesPresent = eu4Countries.every((country) =>
          this.selectedMarket.some((item: string) => item === country)
        );
        if (eu4CountriesPresent) {
          const index = this.filteredMarketsTemp.findIndex(
            (item) => item.name === marketCountries.EU4
          );
          if (index !== -1) {
            this.filteredMarketsTemp.splice(index, 1);
          }
        }
        ///

        //if all  countries included, remove All item from ddl
        const allountries = Object.values(marketAllCountries);
        const allCountriesPresent = allountries.every((country) =>
          this.selectedMarket.some((item: string) => item === country)
        );
        if (allCountriesPresent) {
          const index = this.filteredMarketsTemp.findIndex(
            (item) => item.name === marketCountries.All
          );
          if (index !== -1) {
            this.filteredMarketsTemp.splice(index, 1);
          }
        }
        ///
      }
      this.filteredMarketsTemp.sort((item1, item2) => {
        return (
          this.filteredMarkets.findIndex((item) => item.name === item1.name) -
          this.filteredMarkets.findIndex((item) => item.name === item2.name)
        );
      });
    }
    this.netPriceService.selectedMarkets = this.selectedMarket;
    this.projectForm.controls.selectedMarkets.setValue(this.selectedMarket);
    this.projectForm.controls.markets.setValue('');
    this.projectService.valueChangeStatus.next(false);
    //silrang
    this.projectService.checkScenarioStatus(true, false);
    this.projectService.isdetailsValid = this.projectForm.valid;
    this.projectService.isScenariosValid = false;
    this.projectService.isScenariosNamesValid = false;
    this.projectService.selectedAnalogues = [];
    this.netPriceService.selectedAnalogueNetprice = [];
    this.netPriceService.ProjectNetPrice.AnalogueNetPrice = [];
    this.criteriaSelectionService.isAnaloguesValid = false;
    this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded=false;
    this.netPriceService.ProjectNetPrice.IsNetPriceIncluded=false;
    this.criteriaSelectionService.savedSelectedAnalogues.next([]);
    this.projectService.newValuechanged.next(true);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }

  }

  selectFramework(value: any) {
    this.selectedValueFrameworks = value;
    this.projectForm.controls.framework.patchValue(
      this.selectedValueFrameworks
    );
    this.projectService.selectedValueFramework =
      this.projectForm.controls.framework.value;
  }

  // ---------------Edit Project Methods----------------
  getProjectDetails() {
    this.selectedMarket = [];
    if (this.urlProjectId) {
      this.projectService.editProjectId.next(this.urlProjectId);
      this.loading = true;
      this.masterService
        .getProjectDetails(this.urlProjectId)
        .then((data: any) => {
          let analogues:any[]=[];
          data.brandTLIAndCountrys.forEach((element:any) => {
            if(!analogues.some(x=>x.brandName === element.brand)){
              let allMoaData = this.MasterAnalogueData.filter(
                (res) =>
                  res.indication.trim().toLowerCase() ===
                element.tli.trim().toLowerCase() &&
                  res.inn.trim().toLowerCase() === element.inn.trim().toLowerCase()
              );
              allMoaData.map((moaData) => {
                const analogueWithMoa = {
                  brandName: element.brand,
                    indication: element.tli,
                  mechanismOfAction: moaData.mechanismOfAction,
                  therapyArea: moaData.therapyArea,
                  inn: moaData.inn,
                  sequenceNo: element.sequenceNo,
                };
                analogues.push(analogueWithMoa);
              });
              // analogues.push({
              //   brandName: element.brand,
              //   indication: element.tli,
              //   mechanismOfAction: element.moa,
              //   sequenceNo: element.sequenceNo,
              //   inn: element.inn,
              //   therapyArea: element.brand
              // })
            }
          });
          this.criteriaSelectionService.savedSelectedAnalogues.next(
            analogues
          );
          this.projectService.selectedAnalogues = analogues;
          this.projectForm.controls.projectName.patchValue(data.name);
          this.projectForm.controls.projectDetails.patchValue(data.description);
          this.projectForm.controls.assetName.patchValue(
            data.assetTLIAndCountrys[0].assetName
          );
          this.selectedTLIvalue = this.TLIData
            ? this.TLIData.find(
                (tli) =>
                  tli.name ===
                  data.assetTLIAndCountrys[0].topLevelIndication.name
              )
            : data.assetTLIAndCountrys[0].topLevelIndication.name;
          this.projectForm.controls.topLevelIndication.patchValue(
            this.selectedTLIvalue
              ? this.selectedTLIvalue
              : data.assetTLIAndCountrys[0].topLevelIndication
          );
          this.projectForm.controls.innName.patchValue(
            data.assetTLIAndCountrys[0].inn
          );
          this.projectService.innName.next(data.assetTLIAndCountrys[0].inn);
          this.projectForm.controls.moa.patchValue(
            data.assetTLIAndCountrys[0].moa
          );
          if(this.updateFrameworkService.isUpdateFrameWork.value){
            this.SelectedValueFrameworkId = this.updateFrameworkService.updateFrameWorkId.value;
            this.selectedValueFrameworks = this.ValueFrameworks.filter(x=> x.valueFrameworkId === this.updateFrameworkService.updateFrameWorkId.value)[0];
            this.projectForm.controls.framework.patchValue(
              this.selectedValueFrameworks
            );
          }else{
            if(this.urlProjectId != ''){
              this.ValueFrameworksView = this.ValueFrameworks;
            }
            this.SelectedValueFrameworkId = data.valueFrameworkId;
            this.selectedValueFrameworks = this.ValueFrameworks.filter(x=> x.valueFrameworkId === data.valueFrameworkId)[0];
            this.projectForm.controls.framework.patchValue(
              this.selectedValueFrameworks
            );
  
          }
 
          this.selectedMarket = data.marketAreas.map((market: any) => {
            return market.countryName;
          });

          this.projectForm.controls.selectedMarkets.patchValue(
            this.selectedMarket
          );
          this.netPriceService.selectedMarkets = this.selectedMarket;
          this.projectService.addEditScenario(data.projectScenarios);
          this.projectService.ProjectCollaborators = data.collaborators;
          this.setNetPriceAssumtionDetails(data, false);
          this.projectService.scenarioStatus.next(false);
          this.projectService.duplicateScenarioName.next(false);
          this.projectService.isScenariosNamesValid = true;
          this.projectService.isScenariosValid = true;
          this.criteriaSelectionService.isAnaloguesValid = true;
          setTimeout(() => {
            this.loading = false;
            this.projectService.isdetailsValid = this.projectForm.valid;
            this.projectService.newValuechanged.next(true);
          }, 2000);
        });
      this.getFileList();
      this.projectService.isProjectLoaded = true;
    } else {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  setNetPriceAssumtionDetails(data: any, isDraft: boolean): void {
    let netpriceDetails: any = {
      IsNetPriceIncluded: data.projectNetPrice.isNetPriceIncluded,
      isAnnualCOTInlcuded: data.projectNetPrice.isAnnualCOTInlcuded,
    };
    if (data.projectNetPrice.isNetPriceIncluded) {
      netpriceDetails = {
        IsNetPriceIncluded: data.projectNetPrice.isNetPriceIncluded,
        isAnnualCOTInlcuded: data.projectNetPrice.isAnnualCOTInlcuded,
        OverallNetPrice: this.setOverallNetPrice(
          data.projectNetPrice.overallNetPrice
        ),
        AnalogueNetPrice: this.setAnalogueNetPrice(
          data.projectNetPrice.analogueNetPrice,
          data.brandTLIAndCountrys
        ),
        AnaglueChanged: false,
      };
    } else {
      netpriceDetails = {
        IsNetPriceIncluded: data.projectNetPrice.isNetPriceIncluded,
        isAnnualCOTInlcuded: data.projectNetPrice.isAnnualCOTInlcuded,
        OverallNetPrice: this.setOverallNetPrice(
          data.projectNetPrice.overallNetPrice
        ),
        AnalogueNetPrice: this.setAnalogueNetPrice(data.brandTLIAndCountrys),
        AnaglueChanged: false,
      };
    }
    this.netPriceService.ProjectNetPrice = netpriceDetails;
  }

  setOverallNetPrice(OverallNetPrice: any[]): any[] {
    let newOverallNetPrice: any[] = [];
    if (OverallNetPrice != null) {
      OverallNetPrice.forEach(async (element) => {
        let currency =
          await this.currencyConversionService.getCountryCurrencyData(
            element.country
          );
        currency = this.currencyConversionService.convertSymbol(
          currency.symbol
        );
        newOverallNetPrice.push({
          country: element.country,
          id: this.countryFlagService.getFlagId(element.country),
          NetPriceDiscount: element.netPriceDiscount,
          info: '',
          currency: currency,
        });
      });
    } else {
      this.selectedMarket.forEach(async (element: string) => {
        let currency =
          await this.currencyConversionService.getCountryCurrencyData(element);
        currency = this.currencyConversionService.convertSymbol(
          currency.symbol
        );
        newOverallNetPrice.push({
          country: element,
          id: this.countryFlagService.getFlagId(element),
          NetPriceDiscount: element,
          info: '',
          currency: currency,
        });
      });
    }

    return newOverallNetPrice;
  }

  setAnalogueNetPrice(AnalogueNetPrice: any[], brandTLIAndCountrys?: any[]) {
    let newAnalogueNetPrice: any[] = [];
    AnalogueNetPrice.forEach((x) => {
      let index = newAnalogueNetPrice.findIndex(
        (y) => x.brand === y.Brand && x.inn === y.INN && x.tli === y.TLI
      );
      if (index <= -1) {
        newAnalogueNetPrice.push({
          Brand: x.brand,
          INN: x.inn,
          TLI: x.tli,
          sqNo:
            x.sequenceNo === undefined
              ? brandTLIAndCountrys?.filter((y) => y.brand === x.brand)[0]
                  .sequenceNo
              : x.sequenceNo,
          [x.country]: {
            NetPriceDiscount:
              x?.netPriceDiscount !== undefined ? x.netPriceDiscount : 0,
            AnalogueCOT: x?.analogueCOT !== undefined ? x.analogueCOT : 0,
          },
        });
      } else {
        newAnalogueNetPrice[index][x.country] = {
          NetPriceDiscount:
            x?.netPriceDiscount !== undefined ? x.netPriceDiscount : 0,
          AnalogueCOT: x?.analogueCOT !== undefined ? x.analogueCOT : 0,
        };
      }
    });
    this.selectedMarket.forEach((country: string) => {
      newAnalogueNetPrice.forEach((x) => {
        if (x[country] === undefined) {
          x[country] = {
            NetPriceDiscount: 'NA',
            AnalogueCOT: 'NA',
          };
        }
      });
    });
    this.netPriceService.selectedAnalogueNetprice = newAnalogueNetPrice;
    return newAnalogueNetPrice;
  }

  getCreateProjectDetails() {
    this.selectedMarket = [];
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        // if (!this.projectForm.controls.projectName.value && data.projectName) {
        this.projectForm.patchValue(data);
        this.selectedTLIvalue = this.TLIData
          ? this.TLIData.find((tli) => tli.name === data?.topLevelIndication)
              ?.name
          : data?.topLevelIndication;
          //orphan changes for QA
          this.selectedValueFrameworks = this.ValueFrameworks[0];
          //oncology changes for dev
        // this.selectedValueFrameworks = this.ValueFrameworks.filter(x=> x.valueFrameworkId === data.framework.valueFrameworkId)[0];
        this.projectForm.controls.framework.patchValue(
          this.selectedValueFrameworks
        );
        this.projectForm.controls.topLevelIndication.patchValue(
          this.selectedTLIvalue
            ? this.selectedTLIvalue
            : data?.topLevelIndication
        );
        this.selectedMarket = data?.selectedMarkets
          ? data?.selectedMarkets
          : [];
        this.netPriceService.selectedMarkets = this.selectedMarket;
        // data?.selectedMarkets?.forEach((market: any) => {
        //   this.selectedMarket.push(market);
        // })
        // }
      });
  }

  toSentanceCase(arr: any) {
    arr.forEach((element: any) => {
      element.name = Capitalise(
        element.name.charAt(0).toUpperCase() + element.name.slice(1)
      );
    });
    return arr;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
