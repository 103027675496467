<mat-table [dataSource]="dataSource" class="main-table">
  <!-- Branded INN Name Column -->
  <ng-container matColumnDef="brandedInnName">
    <mat-header-cell *matHeaderCellDef>
      <div class="label-div">Brand Name</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.brandedInnName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="inn">
    <mat-header-cell *matHeaderCellDef>
      <div class="label-div" style="padding-left: 5px;">INN</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.inn }}
    </mat-cell>
  </ng-container>
  <!-- Total Value Score -->
  <ng-container matColumnDef="totalValueScore">
    <mat-header-cell *matHeaderCellDef>
      <div class="label-div">Total Value Score</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.totalValueScore  !== undefined ?  element.totalValueScore : "NA" }}
    </mat-cell>
  </ng-container>
  <!-- Burden Domain Value Score -->
  <ng-container matColumnDef="burdonDomainValueScore">
    <mat-header-cell *matHeaderCellDef>
      <div class="dual-header">
        <div
          (click)="filterColumns(1)"
          *ngIf="selectedIndex === 1 || selectedIndex === 0"
        >
          <span class="arrow-span" *ngIf="selectedIndex === 1"><</span>
          <span>Burden</span>
          <span class="arrow-span" *ngIf="selectedIndex !== 1">></span>
        </div>
        <div class="domain-label-div">
          <span>Domain Value Score </span>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{
        element.burdonDomainValueScore  !== undefined ?  element.burdonDomainValueScore : "NA"
      }}
    </mat-cell>
  </ng-container>
  <!-- Product Domain Value Score -->
  <ng-container matColumnDef="productDomainValueScore">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 1.1 !important">
      <div class="dual-header">
        <div
          (click)="filterColumns(2)"
          *ngIf="selectedIndex === 2 || selectedIndex === 0"
        >
          <span class="arrow-span" *ngIf="selectedIndex === 2"><</span>
          <span>Product Characteristics</span>
          <span class="arrow-span" *ngIf="selectedIndex !== 2">></span>
        </div>
        <div class="domain-label-div">
          <span>Domain Value Score </span>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{
        element.productDomainValueScore  !== undefined ?  element.productDomainValueScore : "NA"
      }}
    </mat-cell>
  </ng-container>
  <!-- Trial Domain Value Score -->
  <ng-container matColumnDef="trialDomainValueScore">
    <mat-header-cell *matHeaderCellDef>
      <div class="dual-header">
        <div
          (click)="filterColumns(3)"
          *ngIf="selectedIndex === 3 || selectedIndex === 0"
        >
          <span class="arrow-span" *ngIf="selectedIndex === 3"><</span>
          <span>Trial Design</span>
          <span class="arrow-span" *ngIf="selectedIndex !== 3">></span>
        </div>
        <div class="domain-label-div">
          <span>Domain Value Score </span>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.trialDomainValueScore  !== undefined ?  element.trialDomainValueScore : "NA" }}
    </mat-cell>
  </ng-container>
  <!-- Clinical Domain Value Score -->
  <ng-container matColumnDef="clinicalDomainValueScore">
    <mat-header-cell *matHeaderCellDef>
      <div class="dual-header">
        <div
          (click)="filterColumns(4)"
          *ngIf="selectedIndex === 4 || selectedIndex === 0"
        >
          <span class="arrow-span" *ngIf="selectedIndex === 4"><</span>
          <span>Clinical benefit</span>
          <span class="arrow-span" *ngIf="selectedIndex !== 4">></span>
        </div>
        <div class="domain-label-div">
          <span>Domain Value Score </span>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{
        element.clinicalDomainValueScore
           !== undefined ?  element.clinicalDomainValueScore
          : "NA"
      }}
    </mat-cell>
  </ng-container>
  <!-- Other Domain Value Score -->
  <ng-container matColumnDef="otherDomainValueScore">
    <mat-header-cell *matHeaderCellDef>
      <div class="dual-header">
        <div
          (click)="filterColumns(5)"
          *ngIf="selectedIndex === 5 || selectedIndex === 0"
        >
          <span class="arrow-span" *ngIf="selectedIndex === 5"><</span>
          <span>Other</span>
          <span class="arrow-span" *ngIf="selectedIndex !== 5">></span>
        </div>
        <div class="domain-label-div">
          <span>Domain Value Score </span>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.otherDomainValueScore  !== undefined ?  element.otherDomainValueScore : "NA" }}
    </mat-cell>
  </ng-container>
  <ng-container
    *ngFor="let column of extraColumns; let i = index"
    matColumnDef="{{ column }}"
  >
    <mat-header-cell *matHeaderCellDef>
      <div class="label-div" style="width:100%" [style.border-top]="'3px solid ' + getTopColor(i)">
        <div [matTooltip]="column">
          {{ column.length > 15 ? column.substring(0, 15) + "..." : column }}
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        class="ageOfDiagnosis"
        [ngClass]="{ 'option-0': selectedOption === 0 }"
      >
        <div *ngIf="selectedOption === 1">
          {{ element[column]?.value  !== null ?  element[column]?.value: "NA" }}
        </div>
        <div>
          {{ element[column]?.score  !== null ? element[column]?.score : "NA" }}
          <a (click)="openDialog(element.brandedInnName,column,element[column].htaInfo,element[column].valueScopeReference)"
          target="_blank"
          [style.visibility]="          
          element[column]?.htaInfo?.length !== 0 && element[column]?.htaInfo !== '' && element[column]?.htaInfo!==null ? 'visible': 'hidden'"
        >
          <img src="/assets/document.png" alt="Document logo" />
        </a>
        </div>
      </div>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    [ngClass]="{ highlight: row?.highlight === true }"
    *matRowDef="let row; columns: displayedColumns"
  ></mat-row>
</mat-table>
<div class="keys">
  <div>
    <span class="title">Keys:</span>
  </div>
  <div>
    <div class="bar" style="background-color: #d9dee9"></div>
    <span>Not Considered</span>
  </div>
  <div>
    <div class="bar" style="background-color: #901812"></div>
    <span>Limited Importance</span>
  </div>
  <div>
    <div class="bar" style="background-color: #d84b44"></div>
    <span>Low Importance</span>
  </div>
  <div>
    <div class="bar" style="background-color: #de9b37"></div>
    <span>Medium Importance</span>
  </div>
  <div>
    <div class="bar" style="background-color: #4aa47a"></div>
    <span>High Importance</span>
  </div>
  <div>
    <div class="bar" style="background-color: #20704a"></div>
    <span>Key driver</span>
  </div>
</div>
