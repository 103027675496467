import { Component } from '@angular/core';

@Component({
  selector: 'he-analogue-selection',
  templateUrl: './analogue-selection.component.html',
  styleUrls: ['./analogue-selection.component.scss']
})
export class AnalogueSelectionComponent {
  sideBarOpen = true;
  constructor() { }


  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
