import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EChartsOption, ECharts } from 'echarts';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { Subject, takeUntil } from 'rxjs';
import { HeaderService } from '../../../../shared/services/header.service';
import { ValueScoringService } from '../../services/value-scoring.service';

export interface ValueScoring {
  [key: string]: string | number | boolean;
}

@Component({
  selector: 'he-project-value-scoring-chart',
  templateUrl: './project-value-scoring-chart.component.html',
  styleUrls: ['./project-value-scoring-chart.component.scss'],
})
export class ProjectValueScoringChartComponent implements OnInit, OnChanges,OnDestroy {
  public chartOption!: EChartsOption;

  @Output() chartInstance = new EventEmitter<any>();
  @Input() public viewMode = 'AbsoluteValue';
  public chartsOption!: EChartsOption;
  public TotalValueScoringData: any = [];
  public BrandName: any = [];
  public CountryData: any[] = [];
  public SeriesData: any[] = [];
  public updateOptions: any = {};
  public projectId = '';
  public scenario = '';
  private unsubscriber$ = new Subject<void>();

  constructor(
    private valueScoringService: ValueScoringService,
    private countryFlagService: CountryFlagService,
    private activatedRouter: ActivatedRoute,
    private headerService: HeaderService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.TotalValueScoringData.length !== 0) {
      this.formatData();
    }
  }
  ngOnInit(): void {
    this.activatedRouter.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe((p) => {
      this.scenario = this.headerService.getScenario();
      this.projectId = p.project;      
      this.initChartOptions();
    });

    this.headerService.onValueScoreChanged.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      data.UpdatedCountryScores.forEach((d: any) => {
        const countryDataIndex =
          this.TotalValueScoringData.valueScores.findIndex(
            (f: any) => f.country === d.country
          );

        if (countryDataIndex > -1) {
          const brandDataIndex = this.TotalValueScoringData.valueScores[
            countryDataIndex
          ].brands.findIndex((f: any) => f.id === d.assetId);

          if (brandDataIndex > -1) {
            this.TotalValueScoringData.valueScores[countryDataIndex].brands[
              brandDataIndex
            ].totalValue = d.totalValueScore;
          }
        }
      });

      this.SeriesData = [];
      this.CountryData = [];
      this.formatData();
    });

    this.headerService.onScenarioChange.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      this.scenario = data;
      if (this.projectId && this.scenario) {
        this.valueScoringService
          .getTotalValueScoreByCountries(this.projectId, this.scenario)
          .then((data) => {
            this.TotalValueScoringData = data;
            this.TotalValueScoringData.valueScores[0].brands.sort((a:any,b:any) => {
              return a.sequenceNo - b.sequenceNo;
            })
          })
          .then(() => {
            this.formatData();
          });
      }
    });

    this.initChartOptions();
  }

  private initChartOptions() {
    this.chartOption = {
      grid: {
        left: '4%',
        right: '4%',
        bottom: '34%',
        top: '5%',
        containLabel: true,
      },
      title: {
        left: '0px',
        top: 0,
        textStyle: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 20,
          color: '#374050',
        },
        padding: [3, 0],
      },
      legend: {
        bottom: 0,
        left: 60,
        icon: 'circle',
        textStyle: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 20,
          verticalAlign: 'middle',
        },
      },
      tooltip: {
        formatter(params: any) {
          const data = params || [0, 0];
          return [
            data.seriesName,
            data.marker +
              data.name +
              ': &nbsp;&nbsp;' +
              Math.round(parseFloat(data.value))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              '%',
          ].join('<br/>');
        },
      },
      xAxis: {
        type: 'category',
        name: 'ANALOGUES AND ASSETS',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          fontWeight: 500,
          fontSize: 14,
          color: 'black',
        },
        data: [],
        axisLabel: {
          formatter(value: any) {
            return (
              '{' +
              value.replace(' ', '').toUpperCase().trim() +
              '| }  {value|' +
              value +
              '}'
            );
          },
          fontSize: 11,
          margin: 10,
          rich: {
            value: {
              lineHeight: 35,
              align: 'center',
              color: 'black',
            },
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}',
        },
        min: 0,
      },
      color: [
        '#716BC1',
        '#BD769B',
        '#CC9997',
        '#E0C784',
        '#D7CE6E',
        '#C5DD83',
        '#99CC9B',
        '#90B3D3',
        '#768ECE',
        '#74849E',
        '#ACB6C6',
        '#D5DAE3',
      ],
      series: [],
    };
    switch (this.viewMode) {
      case 'AbsoluteValue':
        this.chartOption.tooltip = {
          formatter(params: any) {
            const data = params || [0, 0];
            return [
              data.seriesName,
              data.marker +
                data.name +
                ': &nbsp;&nbsp;' +
                Math.round(parseFloat(data.value))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            ].join('<br/>');
          },
        };

        break;
      case 'Percentage':
        this.chartOption.tooltip = {
          formatter(params: any) {
            const data = params || [0, 0];
            return [
              data.seriesName,
              data.marker +
                data.name +
                ': &nbsp;&nbsp;' +
                Math.round(parseFloat(data.value))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                '%',
            ].join('<br/>');
          },
        };

        break;
      default:
        this.chartOption.tooltip = {
          formatter(params: any) {
            const data = params || [0, 0];
            return [
              data.seriesName,
              data.marker +
                data.name +
                ': &nbsp;&nbsp;' +
                parseFloat(data.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            ].join('<br/>');
          },
        };

        break;
    }
  }

  formatData() {
    this.initChartOptions();
    this.updateOptions = {};
    this.BrandName = [];
    this.CountryData = [];
    this.SeriesData = [];
    for (let i = 0; i < this.TotalValueScoringData.valueScores.length; i++) {
      this.CountryData.push(this.TotalValueScoringData.valueScores[i].country);
      for (
        let j = 0;
        j < this.TotalValueScoringData.valueScores[i].brands.length;
        j++
      ) {
        if (
          !this.BrandName.includes(
            this.TotalValueScoringData.valueScores[i].brands[j].name
          )
        ) {
          this.BrandName.push(
            this.TotalValueScoringData.valueScores[i].brands[j].name
          );
        }
      }
    }
    this.CountryData.sort().reverse();

    this.BrandName.forEach((brand: string) => {
      const currentSeriesData: any = {
        name: brand,
        data: [],
        type: 'bar',
        barGap: '10%',
        barCategoryGap: '10%',
        barMaxWidth: '10%',
      };
      this.CountryData.forEach((country: string) => {
        const currentCountrysValScoringData =
          this.TotalValueScoringData.valueScores.find(
            (valueScore: any) =>
              valueScore.country.toLowerCase().trim() ===
              country.toLowerCase().trim()
          );
        if (currentCountrysValScoringData) {
          const currentBrandsData = currentCountrysValScoringData.brands.find(
            (brandData: any) =>
              brandData.name.toLowerCase().trim() === brand.toLowerCase().trim()
          );
          switch (this.viewMode) {
            case 'AbsoluteValue':
              currentSeriesData['data'] = [
                ...currentSeriesData['data'],
                { value: currentBrandsData.totalValue },
              ];
              break;
            case 'Percentage':
              currentSeriesData['data'] = [
                ...currentSeriesData['data'],
                { value: currentBrandsData.totalValueScorePercent},
              ];
              break;
            default:
              currentSeriesData['data'] = [
                ...currentSeriesData['data'],
                { value: currentBrandsData.totalValue },
              ];
              break;
          }
        }
      });
      this.SeriesData.push(currentSeriesData);
    });
    const labelData = this.xAxisLablePrepare();

    this.updateOptions = {
      series: this.SeriesData,
      legend: {
        data: this.BrandName,
      },
      xAxis: {
        axisLabel:
        {
          show:false
        },
        data: this.CountryData,
      },
    };
  }

  xAxisLablePrepare(): any {
    const axisLabel: any = {
      formatter(value: any) {
        return (
          '{' + value.replace(' ', '').toUpperCase().trim() + '|}  ' + value
        );
      },
      margin: 10,
      rich: {
        value: {
          lineHeight: 35,
          align: 'center',
          color: 'black',
        },
      },
    };
    this.CountryData.forEach((element: string, index: number) => {
      axisLabel.rich[element.replace(' ', '').toUpperCase().trim()] = {
        height: 25,
        align: 'center',
        backgroundColor: {
          image:
            'assets/flags/' +
            this.countryFlagService.getFlagId(element) +
            '.svg',
        },
      };
    });
    return axisLabel;
  }

  saveChartApi(ev: ECharts): void {
    this.chartInstance.emit(ev);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
