import { filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Sort } from '@angular/material/sort';
import {
  CriteriaApiInterFace,
  CriteriaInterface,
  AnalogueTableInterface,
  LegendEnum,
  criteriaPayloadInterface,
  AnalogueListInterface,
  patientNumberCriteriaApiInterFace,
} from '../../models/criteria.interface';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { Subject, take, takeUntil } from 'rxjs';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { ProjectService } from '../../services/project.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { AnalogueMasterData } from '../../../../horizon-project/models/AnalogueMaseterData';
import { AnalogueiHeatMapComponent } from '../analoguei-heat-map/analoguei-heat-map.component';
import { ProjectDraftService } from '../../services/project-draft.service';
import { NetPriceService } from '../../services/net-price.service';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';

@Component({
  selector: 'he-analogue-selection-criteria',
  templateUrl: './analogue-selection-criteria.component.html',
  styleUrls: ['./analogue-selection-criteria.component.scss'],
})
export class AnalogueSelectionCriteriaComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable)public table!: MatTable<any>;
  @ViewChild(AnalogueiHeatMapComponent)public analogueHeatMapComponent!: AnalogueiHeatMapComponent;

  public firstPanelOpen = true;
  public secondPanelOpen = false;
  public thirdPanelOpen = false;
  public showMustHaveError = false;
  public minlaunchDate = 2019;
  public mindate = 2019;
  public countryFilter:string = "";
  public maxlaunchDate = new Date().getFullYear();
  public maxdate = new Date().getFullYear();
  public sort: any;
  public launchDateForm = new FormGroup({
    minlaunchDate: new FormControl(''),
    maxlaunchDate: new FormControl(''),
  });
  analogueColumns: string[] = [
    'brandName',
    'indication',
    'mechanismOfAction',
    'therapyArea',
    'remove',
  ];
  public mustHave: CriteriaInterface[] = [];
  public niceToHave: CriteriaInterface[] = [];
  public Unselected: CriteriaInterface[] = [];
  public defaultCriteria: CriteriaInterface[] = [];
  public isDraftProject: boolean = false;
  public loading = false;
  public criteriaList: {
    apititle: string;
    inputType: string;
    title: string;
  }[] = [
    {
      apititle: 'orphan',
      inputType: '1',
      title: 'Orphan',
    },
    {
      apititle: 'oneOffTherapy',
      inputType: '1',
      title: 'One-off therapy',
    },
    {
      apititle: 'population',
      inputType: '1',
      title: 'Paediatric/adult population',
    },
    {
      apititle: 'eligiblePatientNumber',
      inputType: '2',
      title: 'Patient number (per European market)',
    },
    {
      apititle: 'eligiblePatientNumberUSA',
      inputType: '2',
      title: 'Patient number (USA)',
    },
    {
      apititle: 'routeOfAdministration',
      inputType: '2',
      title: 'Route of administration',
    },
    {
      apititle: 'moaCategory',
      inputType: '3',
      title: 'Mechanism of action category',
    },
    {
      apititle: 'therapyAreas',
      inputType: '4',
      title: 'Therapy areas/Indications',
    },
    {
      apititle: 'lineoftherapy',
      inputType: '1',
      title: 'Line of therapy',
    },
    {
      apititle: 'indications',
      inputType: '4',
      title: 'Indications',
    },
    {
      apititle: 'formulation',
      inputType: '2',
      title: 'Formulation',
    },
    {
      apititle: 'biomarker',
      inputType: '2',
      title: 'Biomarker',
    },
  ];

  public legends = [
    {
      name: 'Meets criteria',
      color: LegendEnum[1],
    },
    {
      name: 'Partially meets criteria',
      color: LegendEnum[2],
    },
    {
      name: 'Does not meet criteria',
      color: LegendEnum[3],
    },
    {
      name: 'No criteria included',
      color: LegendEnum[0],
    },
  ];
  public selectedMarket: any = [];
  public newSelectedMarket: any = [];
  public analogues: AnalogueListInterface[] = [];
  public sortedAnalogues: any[] = [];
  public MasterAnalogueData: AnalogueMasterData[] = [];

  public dataSource: AnalogueTableInterface[] = [];
  private unsubscriber$ = new Subject<void>();

  public brandsCountData: any = [];
  private readonly PATIENT_NUMBER_MARKET_AREA = {
    Europe: 'EUROPE',
    UnitedStates: 'UNITED STATES',
  };
  public minMarket:number = 2;
  public newHeatmap:boolean = true;
  public dateInfoData:string='';
  constructor(
    public criteriaSelectionService: CriteriaSelectionService,
    private toastNotificationService: ToastNotificationService,
    private projectService: ProjectService,
    private projectDraftService: ProjectDraftService,
    private netPriceService: NetPriceService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.featureFlagService.getFeatureFlagData().then((data) => {
      this.newHeatmap = data.find(
       (x) => x.name === 'new-analogue-heatMap' && x.page === 'analogue-selection'
     )?.isActive as boolean;
     this.minMarket = this.newHeatmap?5:2;
   });
    this.checkDraftProject();
    this.getMasterAnalogues();
    this.getAnalogueSelectionCriteria();
    this.criteriaSelectionService.castSavedSelectedAnalogues
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        const filteredData = res.filter(
          (element: any, index: any, array: any) => {
            return (
              array.findIndex(
                (prevElement: any) =>
                  prevElement.name === element.name &&
                  prevElement.indication === element.indication &&
                  prevElement.inn === element.inn
              ) === index
            );
          }
        );
        this.analogues = res;
      });
    this.getCreateProjectDetails();
    this.sortedAnalogues = this.projectService.selectedAnalogues;
    this.getBrandCountData();
    //this.refreshDataOnMarketChange()
  }

  checkDraftProject() {
    this.projectDraftService.checkDraftProjectExist().then((res: any) => {
      if (res.data) {
        this.isDraftProject = res.data;
      }
    });
  }

  public refreshDataOnMarketChange() {
    // this.projectService.castCreateProjectFormData
    // .pipe(take(1))
    // .subscribe((data) => {
    this.Findanalogues();
    // });
  }
  public getMasterAnalogues(): void {
    const TLi = '';
    const MOA = '';
    this.criteriaSelectionService
      .getAnalogueMaster(TLi, MOA)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.MasterAnalogueData = res;
      });
  }

  public getBrandCountData(): void {
    this.criteriaSelectionService.brandCountData.subscribe((res) => {
      this.brandsCountData = res;
    });
  }

  public checkBrandCounts(data: any) {
    for (const country of data) {
      if (country.brandCount > this.minMarket) {
        return true;
      }
    }
    return false;
  }
  private getAnalogueSelectionCriteria(): void {
    this.criteriaSelectionService
      .getAnalogueSelectionCriteria()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.defaultCriteria = [];

        let titles = Object.keys(res);
        titles.forEach((title) => {
          if (
            title !== 'initialLaunchDate' &&
            title !== 'indications'
            // title !== 'therapyAreas'
          ) {
            this.setCriteriaList(title, res);
          } else if (title === 'initialLaunchDate') {
            let launchDates = res.initialLaunchDate.sort(
              (a, b) => JSON.parse(a) - JSON.parse(b)
            );
            this.dateInfoData =  res.infoIconData.find(x=> 'Initial launch date' === x.criteria).description
            this.mindate = JSON.parse(launchDates[0]);
            this.maxdate = JSON.parse(launchDates[launchDates.length - 1]);
            this.minlaunchDate = this.mindate;
            this.maxlaunchDate = this.maxdate;
          }
        });
        this.criteriaSelectionService.castCriteriaList
          .pipe(take(1))
          .subscribe((res) => {
            if (res !== null) {
              this.minlaunchDate = res.minlaunchDate;
              this.maxlaunchDate = res.maxlaunchDate;
              this.mustHave = res.mustHave.filter(
                (m: CriteriaInterface) =>
                  this.defaultCriteria.filter((x) => x.title === m.title)
                    .length > 0
              );
              this.niceToHave = res.niceToHave.filter(
                (m: CriteriaInterface) =>
                  this.defaultCriteria.filter((x) => x.title === m.title)
                    .length > 0
              );
              //this.Unselected = res.Unselected;
              let defaultUnselected = JSON.parse(
                JSON.stringify(
                  this.defaultCriteria.filter(
                    (x) =>
                      this.mustHave.filter((m) => m.title === x.title).length ==
                        0 &&
                      this.niceToHave.filter((n) => n.title === x.title)
                        .length == 0
                  )
                )
              );
              this.Unselected = defaultUnselected;
              this.getSavedAnalogue();
              this.criteriaSelectionService.castopenedTabindext
                .pipe(take(1))
                .subscribe((res) => {
                  // this.panelOpenState = res;
                  this.secondPanelOpen = res;
                });
              this.refreshDataOnMarketChange();
            } else {
              this.Unselected = JSON.parse(
                JSON.stringify(this.defaultCriteria)
              );
            }
          });
      });
  }

  private getSavedAnalogue(): void {
    this.criteriaSelectionService.castSavedTableData
      .pipe(take(1))
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  private setCriteriaList(title: string, results: CriteriaApiInterFace): void {
    if(title != "infoIconData"){
    if (
      this.criteriaList.find((res) => res.apititle === title)?.inputType == '4'
    ) {
      this.defaultCriteria.push({
        title: this.criteriaList.find((res) => res.apititle === title)
          ?.title as string,
        name:  this.titleCaseWord(title),
        infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
        ?.title === x.criteria).description,
        inputType: this.criteriaList.find((res) => res.apititle === title)
          ?.inputType as string,
        // valueList: ,
        multiInputList: this.setValueList(
          title,
          results,
          this.PATIENT_NUMBER_MARKET_AREA.Europe
        ),
      });
    } else {
      if (title === 'eligiblePatientNumber') {
        const marketAreasForPatientNumbers =
          this.getMarketAreaForPatientNumber();

        if (marketAreasForPatientNumbers.length > 0) {
          marketAreasForPatientNumbers.forEach((ma) => {
            if (ma === this.PATIENT_NUMBER_MARKET_AREA.Europe) {
              this.defaultCriteria.push({
                title: 'Patient number (per European market)',
                name: this.titleCaseWord('eligiblePatientNumber'),
                infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
        ?.title === x.criteria).description,
                inputType: this.criteriaList.find(
                  (res) => res.apititle === title
                )?.inputType as string,
                valueList: this.setValueList(
                  title,
                  results,
                  this.PATIENT_NUMBER_MARKET_AREA.Europe
                ),
                selectedValueList: [],
              });
            }

            if (ma === this.PATIENT_NUMBER_MARKET_AREA.UnitedStates) {
              this.defaultCriteria.push({
                title: 'Patient number (USA)',
                name: this.titleCaseWord('eligiblePatientNumberUSA'),
                infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
        ?.title === x.criteria).description,
                inputType: this.criteriaList.find(
                  (res) => res.apititle === title
                )?.inputType as string,
                valueList: this.setValueList(
                  title,
                  results,
                  this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
                ),
                selectedValueList: [],
              });
            }
          });
        }
      } else {
        this.defaultCriteria.push({
          title: this.criteriaList.find((res) => res.apititle === title)
            ?.title as string,
          name: this.titleCaseWord(title),
          infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
        ?.title === x.criteria).description,
          inputType: this.criteriaList.find((res) => res.apititle === title)
            ?.inputType as string,
          valueList: this.setValueList(
            title,
            results,
            this.PATIENT_NUMBER_MARKET_AREA.Europe
          ),
          selectedValueList: [],
        });
      }
    }
  }
  }

  private getMarketAreaForPatientNumber() {
    const marketAreas = [];

    if (this.selectedMarket.length == 1) {
      if (this.selectedMarket.includes('United States')) {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.UnitedStates);
      } else {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.Europe);
      }
    }

    if (this.selectedMarket.length > 1) {
      marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.Europe);
      if (this.selectedMarket.includes('United States')) {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.UnitedStates);
      }
    }

    return marketAreas;
  }

  public titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1);
  }

  private setValueList(
    title: string,
    results: CriteriaApiInterFace,
    marketArea: string
  ): any {
    let values: any[] = [];
    if (
      this.criteriaList.find((res) => res.apititle === title)?.inputType == '4'
    ) {
      let list = Object.entries(results);
      list.forEach((element) => {
        if (element[0] === title) {
          this.criteriaSelectionService.therapyAreaList.next(
            this.setmultiInputList(element[1])
          );
          values = [
            {
              therapyValueList: this.setmultiInputList(element[1]),
              indicationValueList: [],
              selectedIndicationValue: [],
              selectedTherapyValue:'',
              selectAll: false,
            },
          ];
        }
      });
    } else {
      if (title === 'orphan') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Include', 'Exclude', 'Only'];
          }
        });
      } else if (title === 'oneOffTherapy') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Include', 'Exclude', 'Only'];
          }
        });
      } else if (title === 'eligiblePatientNumber') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            const patientNumberData = element[1].filter(
              (f: patientNumberCriteriaApiInterFace) =>
                f.marketArea === marketArea
            );
            values = patientNumberData[0]?.patientNumber;
          }
        });
      } else {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = element[1];
          }
        });
      }
    }
    return values;
  }

  private setmultiInputList(valueList: any[]): any {
    return valueList.map((res: any) => {
      return {
        name: res.therapyArea,
        valueList: res.indications.map((res: any) => {
          return {
            name: res,
          };
        }),
      };
    });
  }

  public drop(event: CdkDragDrop<CriteriaInterface[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public reset(): void {
    this.mustHave = [];
    this.niceToHave = [];
    this.minlaunchDate = this.mindate;
    this.maxlaunchDate = this.maxdate;
    this.Unselected = JSON.parse(JSON.stringify(this.defaultCriteria));
    this.valueChange();
  }

  public valueChange(): void {
    let saveCriteria = {
      minlaunchDate: this.minlaunchDate,
      maxlaunchDate: this.maxlaunchDate,
      mustHave: this.mustHave,
      niceToHave: this.niceToHave,
      Unselected: this.Unselected,
    };
    this.criteriaSelectionService.criteriaList.next(saveCriteria);
  }

  public Findanalogues(): void {
    if (this.checkFormValid()) {
      this.secondPanelOpen = true;
      this.firstPanelOpen = false;
      this.getAnalogue();
    }
  }

  public checkFormValid(): boolean {
    let state = true;
    if (this.selectedMarket.length === 0) {
      state = false;
      this.toastNotificationService.errorMessage('Please select at least one market in Project Details to continue');
    } else if (!this.launchDateForm.valid) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Initial launch date is invalid'
      );
    } else if (this.mustHave.length === 0) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Select at least One more Must Have Criteria'
      );
    } else if (!this.checkMustHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Must Have Criteria value cannot be empty'
      );
    } else if (!this.checNicetoHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Nice to Have Criteria value cannot be empty'
      );
    }
    return state;
  }

  public checNicetoHaveValues(): boolean {
    let state = true;
    this.niceToHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public checkMustHaveValues(): boolean {
    let state = true;
    this.mustHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public getAnalogue(): void {
    this.loading = true;
    this.dataSource = [];
    let body = this.setPayLoad();
    this.criteriaSelectionService
      .getAnalogue(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          this.dataSource = res;
          this.loading = false;
          this.criteriaSelectionService.savedTableData.next(this.dataSource);
          this.checkDataSource();
          this.checkAnalogues();
          if (
            !this.projectService.isDraftAnalogueFetched.value &&
            this.isDraftProject
          ) {
            this.firstPanelOpen = false;
            this.secondPanelOpen = false;
            this.thirdPanelOpen = true;
            this.projectService.isDraftAnalogueFetched.next(true);
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // assign values to the api input as per the criteria selected
  public setPayLoad(): any {
    let body: criteriaPayloadInterface = {
      InitialLaunchDate: {
        Type: 'Must have',
        Data: this.setDates(),
      },
      Countries: this.capitalizeArrayValues(this.selectedMarket),
    };
    if (this.mustHave.length !== 0) {
      this.mustHave.forEach((criteria) => {
        this.setPayLoadValues('Must have', criteria, body);
      });
    }
    if (this.niceToHave.length !== 0) {
      this.niceToHave.forEach((criteria) => {
        this.setPayLoadValues('Nice to have', criteria, body);
      });
    }
    this.netPriceService.heatMapPayload = body;
    return body;
  }

  public setPayLoadValues(
    criteriaType: string,
    criteria: CriteriaInterface,
    body: criteriaPayloadInterface
  ): any {
    switch (criteria.inputType) {
      case '1':
        body[criteria.name] = {
          Type: criteriaType,
          Data: [criteria.selectedValue],
        };
        break;
      case '2': {
        if (
          criteria.name === 'EligiblePatientNumber' ||
          criteria.name === 'EligiblePatientNumberUSA'
        ) {
          const marketArea =
            criteria.name === 'EligiblePatientNumberUSA'
              ? this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
              : this.PATIENT_NUMBER_MARKET_AREA.Europe;

          if (!body['EligiblePatientNumber']) {
            body['EligiblePatientNumber'] = [];
          }

          body['EligiblePatientNumber'].push({
            Type: criteriaType,
            Data: [criteria.selectedValue],
            MarketArea: marketArea,
          });
        } else {
          body[criteria.name] = {
            Type: criteriaType,
            Data: [criteria.selectedValue],
          };
        }

        break;
      }
      case '3':
        body[criteria.name] = {
          Type: criteriaType,
          Data: criteria.selectedValueList,
        };
        break;
      case '4':
        let therapyLIst: any[] = [];
        let indicationLIst: any[] = [];
        criteria.multiInputList?.forEach((res) => {
          therapyLIst.push(res.selectedTherapyValue);
          res.selectedIndicationValue.forEach((indication) => {
            indicationLIst.push(indication);
          });
        });
        body['TherapyArea'] = {
          Type: criteriaType,
          Data: therapyLIst,
        };
        body['IndicationCategory'] = {
          Type: criteriaType,
          Data: indicationLIst,
        };
        break;
    }
    return body;
  }

  public checkDataSource(): void {
    this.dataSource.forEach((analogue) => {
      analogue.toggle = this.analogues.some(
        (res) =>
          res.name === analogue.Brand &&
          res.indication === analogue.Indication.name &&
          res.inn === analogue.INN
      );
      this.analogues.forEach((x) => {
        if (
          x.name === analogue.Brand &&
          x.indication === analogue.Indication.name &&
          x.inn === analogue.INN
        ) {
          x.market = [...x.market,...analogue.Market];
        }
      });
    });
  }

  public checkAnalogues(): void {
    this.analogues.forEach((analogue) => {
      analogue.available = this.dataSource.some(
        (res) => res.Brand === analogue.name
      );
    });
    this.getAnalogueList(this.analogues);
  }

  public setDates(): string[] {
    let dates: string[] = [];
    for (let index = this.minlaunchDate; index <= this.maxlaunchDate; index++) {
      dates.push(JSON.stringify(index));
    }
    return dates;
  }

  /**
   * this function removes the analogue based on anlogues data and removes all entry at once
   * also untoggle the button in heatmap
   * @param analogue
   */
  public removeAnalogue(analogue: AnalogueListInterface): void {
    const index = this.analogues.findIndex(
      (res) =>
        res.indication.toLowerCase().trim() ===
          analogue.indication.toLowerCase().trim() &&
        res.inn.toLowerCase().trim() === analogue.inn.toLowerCase().trim()
    );
    const removalElements = this.sortedAnalogues.filter(
      (res) =>
        res.indication.trim().toLowerCase() ===
          analogue.indication.trim().toLowerCase() &&
        res.inn.trim().toLowerCase() === analogue.inn.trim().toLowerCase()
    );
    if (index >= 0) {
      const dataSourceElements = this.dataSource.filter(
        (res) =>
          res.Indication.name.toLowerCase().trim() ===
            analogue.indication.toLowerCase().trim() &&
          res.INN.toLowerCase().trim() === analogue.inn.toLowerCase().trim()
      );
      if (dataSourceElements.length !== 0) {
        dataSourceElements.forEach((res) => {
          res.toggle = false;
          this.analogueHeatMapComponent.updateMarketsBrandCount();
        });
      }
      this.analogues.splice(index, 1);
    }
    if (removalElements.length >= 0) {
      this.sortedAnalogues = this.sortedAnalogues.filter(
        (res) =>
          res.indication.trim().toLowerCase() !==
            analogue.indication.trim().toLowerCase() &&
          res.inn.trim().toLowerCase() !== analogue.inn.trim().toLowerCase()
      );
      this.table.renderRows();
      //test sorting
      // this.setAnaloguesForNetPriceAssumptions();
      this.updateAnalogueInService();
    }
    this.newSelectedMarket.forEach((x:any) => {
       let index = x.analogues.findIndex((y:any)=> y.name === analogue.name);
      if(index >= 0){
        x.analogues.splice(index,1)
      }
      // if(x.analogues.some((y:any)=> y === analogue.name)){
      //   console.log(analogue.name)
      // }
    });
  }

  public getAnalogueList(analogues: AnalogueListInterface[]): void {
    this.analogues = analogues;
      //test sorting
    // this.setAnaloguesForNetPriceAssumptions();
    this.criteriaSelectionService.savedSelectedAnalogues.next(analogues);
    this.table.renderRows();
    this.updateAnalogueInService();
    this.setAnaloguesNewView();
  }

  public setAnaloguesNewView():void{
    this.newSelectedMarket.forEach((x:any)=>{
      this.analogues.forEach(y=>{
          if(!x.analogues.some((z:any)=> z.name===y.name)){
            if(y.market.some(a=>a.name.toLowerCase() === x.country.toLowerCase())){
              x.analogues.push(y);
            }
          }
      })
      x.analogues.sort((a:any,b:any)=> a.name.localeCompare(b.name))
    })
  }

  public getCreateProjectDetails(): void {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          this.selectedMarket.push(market);
          this.newSelectedMarket.push({country:market,analogues:[]});
        });
        this.newSelectedMarket.sort((a:any,b:any)=> a.country.localeCompare(b.country))
        this.setAnaloguesNewView();
      });
  }
  /**
   * this function adds analogue in the sorting table and updates data in service
   */
  public addAnalogues() {
    // Check if the brand count for all countries is greater than 2
    const isBrandCountValid = this.brandsCountData.every(
      (data: any) => data.brandCount >= this.minMarket
    );
    if (!isBrandCountValid) {
      this.toastNotificationService.errorMessage(
        'Minimum '+ this.minMarket + ' brands are required for all countries.'
      );
      this.projectService.newValuechanged.next(true)
      this.criteriaSelectionService.isAnaloguesValid = false;
      return;
    }
    this.projectService.newValuechanged.next(true)
    this.criteriaSelectionService.isAnaloguesValid = true;
    this.sortedAnalogues = this.projectService.selectedAnalogues
      ? this.projectService.selectedAnalogues
      : [];
      //test sorting
    // this.setAnaloguesForNetPriceAssumptions();
    this.analogues.forEach((analogue) => {
      let sortedRecords = this.sortedAnalogues.filter(
        (ele) =>
          analogue.indication.toLowerCase().trim() ===
            ele.indication.toLowerCase().trim() &&
          analogue.inn.toLowerCase().trim() == ele.inn.toLowerCase().trim()
      );
      if (sortedRecords.length == 0) {
        let allMoaData = this.MasterAnalogueData.filter(
          (res) =>
            res.indication.trim().toLowerCase() ===
              analogue.indication.trim().toLowerCase() &&
            res.inn.trim().toLowerCase() === analogue.inn.trim().toLowerCase()
        );
        allMoaData.map((moaData, index) => {
          const analogueWithMoa = {
            brandName: analogue.name,
            indication: analogue.indication,
            mechanismOfAction: moaData.mechanismOfAction,
            therapyArea: moaData.therapyArea,
            inn: moaData.inn,
            sequenceNo: analogue.sequenceNo + index + 1,
          };
          this.sortedAnalogues.push(analogueWithMoa);
        });
      }
    });
    this.updateAnalogueInService();
    this.secondPanelOpen = false;
    this.thirdPanelOpen = true;
    if (!isBrandCountValid) {
      this.criteriaSelectionService.sortedAnalogues.next(true);
    } else {
      this.criteriaSelectionService.sortedAnalogues.next(false);
    }
  }

  public setAnaloguesForNetPriceAssumptions() {
    let netAnalogues: any[] = [];
    let i = 0;
    //test sorting
    // this.datasoucre
    this.sortedAnalogues
      .forEach((x) => {
        let index = netAnalogues.findIndex(
          (y) =>
            x.brandName === y.Brand &&
            x.inn === y.INN &&
            x.indication === y.TLI
        );
        if (index <= -1) {
          netAnalogues.push({
            Brand: x.brandName,
            INN: x.inn,
            TLI: x.indication,
            markets:  this.setMarketsForAnalogueNetprice(x),
            sqNo:i,
          });
          i++;
        } else {
          // netAnalogues[index].markets = [...x.Market,...netAnalogues[index].markets]
        }
      });
    this.netPriceService.selectedAnalogueNetprice = netAnalogues;
    this.projectService.newValuechanged.next(true)
  }

  setMarketsForAnalogueNetprice(data:any):any[]{
    let markets:any[]=[]; 
    this.dataSource.filter(x=> x.toggle && x.Brand === data.brandName && x.INN === data.inn && x.Indication.name === data.indication ).forEach(res=>{
      markets = [...markets,...res.Market]
    })
    return markets;
  }

  // setMarketsForAna

  /**
   * this functuion removes the analogue data based on index (i.e. one by one each moa variation)
   * in the table and updates the service
   * @param index
   */
  RemoveAnalogue(index: number) {
    const analogueRows = this.sortedAnalogues.filter(
      (res) =>
        res.indication.toLowerCase().trim() ===
          this.sortedAnalogues[index].indication.toLowerCase().trim() &&
        res.inn.toLowerCase().trim() ===
          this.sortedAnalogues[index].inn.toLowerCase().trim()
    );
    if (analogueRows.length <= 1) {
      const removalElements = this.dataSource.filter(
        (res) =>
          res.Indication.name.toLowerCase().trim() ===
            this.sortedAnalogues[index].indication.toLowerCase().trim() &&
          res.INN.toLowerCase().trim() ===
            this.sortedAnalogues[index].inn.toLowerCase().trim()
      );
      if (removalElements.length !== 0) {
        removalElements.forEach((res) => {
          res.toggle = false;
          this.analogueHeatMapComponent.updateMarketsBrandCount();
        });
      }
    }
    if (analogueRows.length <= 1) {
      this.analogues = this.analogues.filter(
        (res) =>
          res.indication.toLowerCase().trim() !==
            this.sortedAnalogues[index].indication.toLowerCase().trim() &&
          res.inn.toLowerCase().trim() !==
            this.sortedAnalogues[index].inn.toLowerCase().trim()
      );
      this.newSelectedMarket.forEach((x:any)=>{
        x.analogues = x.analogues.filter(
          (res:any) =>
            res.indication.toLowerCase().trim() !==
              this.sortedAnalogues[index].indication.toLowerCase().trim() &&
            res.inn.toLowerCase().trim() !==
              this.sortedAnalogues[index].inn.toLowerCase().trim()
        );
      })
    }
    this.sortedAnalogues.splice(index, 1);
    this.table.renderRows();
    this.setAnaloguesForNetPriceAssumptions();
    this.updateAnalogueInService();
    this.setAnaloguesNewView();
  }

  updateAnalogueInService() {
    //this.projectService.selectedAnalogues = JSON.parse(JSON.stringify(this.selectedAnalogues));
    this.projectService.selectedAnalogues = [];
    this.sortedAnalogues.map((analogue: any, index: number) => {
      let nAnalogue = {
        brandName: analogue.brandName,
        indication: analogue.indication,
        mechanismOfAction: analogue.mechanismOfAction,
        sequenceNo: index + 1,
        inn: analogue.inn,
        therapyArea: analogue.therapyArea,
      };
      this.projectService.selectedAnalogues.push(nAnalogue);
    });
    const isBrandCountValid = this.brandsCountData.every(
      (data: any) => data.brandCount >= this.minMarket
    );
    if (!isBrandCountValid) {
      this.criteriaSelectionService.sortedAnalogues.next(true);
      this.criteriaSelectionService.isAnaloguesValid = false;
      this.projectService.newValuechanged.next(true)
    }else{
      this.criteriaSelectionService.isAnaloguesValid = true;
      this.projectService.newValuechanged.next(true)
    }
    this.criteriaSelectionService.savedSelectedAnalogues.next(this.analogues);
    //test sorting
    this.setAnaloguesForNetPriceAssumptions();

  }

  moveUp(index: any) {
    if (index > 0) {
      const tmp = this.sortedAnalogues[index - 1];
      this.sortedAnalogues[index - 1] = this.sortedAnalogues[index];
      this.sortedAnalogues[index] = tmp;
      this.table.renderRows();
      this.updateAnalogueInService();
    }
  }

  moveDown(index: any) {
    if (index < this.sortedAnalogues.length - 1) {
      const tmp = this.sortedAnalogues[index + 1];
      this.sortedAnalogues[index + 1] = this.sortedAnalogues[index];
      this.sortedAnalogues[index] = tmp;
      this.table.renderRows();
      this.updateAnalogueInService();
    }
  }

  dropSorting(event: CdkDragDrop<string[]>) {
    const previousIndex = this.sortedAnalogues.findIndex(
      (row: any) => row === event.item.data
    );
    moveItemInArray(this.sortedAnalogues, previousIndex, event.currentIndex);
    this.table.renderRows();
    this.updateAnalogueInService();
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.sortedAnalogues.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedAnalogues = data;
      return;
    }

    this.sortedAnalogues = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'brandName':
          return compare(a.brandName, b.brandName, isAsc);
        case 'indication':
          return compare(a.indication, b.indication, isAsc);
        case 'mechanismOfAction':
          return compare(a.mechanismOfAction, b.mechanismOfAction, isAsc);
        case 'therapyArea':
          return compare(a.therapyArea, b.therapyArea, isAsc);

        default:
          return 0;
      }
    });
    this.updateAnalogueInService();
  }

  capitalizeArrayValues(arr: string[]): string[] {
    return arr.map((value) => value.toUpperCase());
  }

  public selectCountry(country:string):void{
    if(this.countryFilter != country){
      this.countryFilter = country;
    }else{
      this.countryFilter = ""
    }
    this.analogueHeatMapComponent.searchTableData(this.countryFilter)
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.criteriaSelectionService.openedTabindex.next(this.secondPanelOpen);
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
