<form [formGroup]="uploadForm">
<div class="upload-data-card">
  <mat-card class="example-card upload-data-mat-card">
    <mat-card-header>
      <div class="heading">
        <span class="heading-title">Upload {{currentTabName}}</span>
        <span class="heading-desc">
          Please upload the {{currentTabName === 'COT Data' ? 'COT data':currentTabName.toLowerCase()}} files here
        </span>
      </div>
    </mat-card-header>
    <mat-card-content class="upload-data-mat-card-content">
      <div class="upload-data-content">
        <div class="upload-section" heUploadData (fileDropped)="onFileDropped($event)">
          <div>
            <img src="../../../../../../assets/uploadImg.png" class="upload-img-logo" alt="Document logo" />
          </div>
          <input type="file" #fileDropRef accept=".csv" id="fileDropRef" style="display: none"
            (change)="fileBrowseHandler($event)" />
          <span>Drag your files here</span><span>or
            <label for="fileDropRef" class="label-color" style="font-weight: 600; cursor: pointer">Browse</label>
          </span>
        </div>
        <div class="upload-section-desc">
          <span>Supported formats : CSV</span>
          <span>Maximum size : 10MB</span>
        </div>
        <div class="uploaded-files-section">
          <div class="uploaded-files-header">
            <div class="uploaded-files-title">
              <span class="heading-title">Selected Files</span>
            </div>
            <div class="uploaded-files-options">
              <mat-form-field appearance="fill" class="mat-form-field upload-type-field country-field">
                <mat-label class="label-color">Please select country</mat-label>
                <mat-select required formControlName="country">
                  <mat-option class="upload-type-option" *ngFor="let country of countries" [value]="country.name" >{{country.name}}</mat-option>
                </mat-select>
                <mat-error>Country is required</mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="currentTab === 'cotData'" appearance="fill" class="mat-form-field upload-type-field currency-field">
                <mat-label class="label-color">Please select currency</mat-label>
                <input
                type="text"
                matInput
                formControlName="currency"
                [matAutocomplete]="auto"
                class="label-color"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayCurrencyFn"
              >
                <mat-option
                  *ngFor="let option of filteredCurrency | async"
                  [value]="option.currency"
                >
                  {{ option.currency }}
                </mat-option>
              </mat-autocomplete>
              <mat-icon matPrefix class="currency-search-icons"
              >search</mat-icon
            >
              <mat-error>Currency is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="uploaded-files-content">
            <div class="files-list">
              <div [ngClass]="getUploadingStyle(i+1)"
                *ngFor="let file of fileListData;let i = index">
                <div class="file-header">
                  <div class="file-icon" style="width: 40px">
                    <img src="/assets/Files-Purple.png" alt="Document logo" />
                  </div>
                  <div class="info">
                    <h4 class="name">
                      {{file?.name}}
                    </h4>
                    <p class="size">
                      {{ file?.name.split(".")[1].toUpperCase() }} document
                      {{ formatBytes(file?.size) }}
                    </p>
                  </div>

                  <button *ngIf="!checkFailedStatus(i+1)&&!uploadedFiles.includes(i+1)" mat-raised-button color="primary"
                    class="upload-btn"
                    [class.spinner]="currentUploadingIndex === i+1" [disabled]="uploadingStatus" (click)="uploadData(i+1)">
                    {{getButtonText(i+1)}}</button>
                  <button *ngIf="checkFailedStatus(i+1)" mat-button class="failed-btn" disabled>
                    Failed 	<mat-icon style="font-size: 21px;width:21px;height:20px">error</mat-icon>
                  </button>
                  <button *ngIf="uploadedFiles.includes(i+1)" mat-button class="uploaded-btn" disabled>
                    Uploaded <mat-icon>done</mat-icon>
                  </button>
                  <a target="_blank" *ngIf="currentUploadingIndex !== i+1 && !uploadedFiles.includes(i+1)" class="open-spacing border-style disable-file" type="button"><mat-icon
                      (click)="deleteFile(i)" class="remove-img">clear</mat-icon></a>
                </div>
                <div class="failed-file-desc" *ngIf="checkFailedStatus(i+1)">
                  <div class="failed-file-desc-header">
                    <img src="/assets/error.png" alt="Document logo" />
                    <span class="error-text"> Errors Occurred</span>
                    <img src="/assets/triangle.png" alt="Document logo"
                      [ngClass]="displayErrors?'acc-logo-opened':'acc-logo-closed'" (click)="toggleErrors()" />
                  </div>
                  <div [ngClass]="getAccordionStyle()">
                    <p class="errorDesc">{{getErrorsDesc(i+1)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
</form> 