import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../../shared/shared.module';
import { ProjectValueScoringComponent } from './components/project-value-scoring/project-value-scoring.component';
// import { ProjectValueScoringSubGridComponent } from './component-pieces/project-value-scoring-sub-grid/project-value-scoring-sub-grid.component';
import { ProjectValueScoringMainGridComponent } from './component-pieces/project-value-scoring-main-grid/project-value-scoring-main-grid.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectValueScoringChartComponent } from './component-pieces/project-value-scoring-chart/project-value-scoring-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoadingSpinnerModule } from '../../../../uikit/loading-spinner';
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatDialogModule} from '@angular/material/dialog';
import { HTALinkDialog } from './component-pieces/project-value-scoring-main-grid/project-value-scoring-main-grid.component';
import { MatButtonModule } from '@angular/material/button';
import { ProjectValueScoringSummaryComponent } from './component-pieces/project-value-scoring-summary/project-value-scoring-summary.component';


@NgModule({
  declarations: [
    ProjectValueScoringComponent,
    ProjectValueScoringMainGridComponent,
    // ProjectValueScoringSubGridComponent,
    ProjectValueScoringChartComponent,
    HTALinkDialog,
    ProjectValueScoringSummaryComponent
  ],
  exports: [ProjectValueScoringComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTooltipModule,
    FormsModule,
    SharedModule,
    LoadingSpinnerModule,
    MatDialogModule,
    MatButtonToggleModule, 
    MatTableExporterModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
})
export class ValueScoringModule {}
