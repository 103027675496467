import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import * as echarts from 'echarts';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { Dropdown, HeaderService } from '../../../../shared/services/header.service';
import html2canvas from 'html2canvas';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { Subject, takeUntil } from 'rxjs';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { ActivatedRoute } from '@angular/router';
import { colorCodeEnum } from '../../../../shared/services/color.enum';
import { ColorSetService } from '../../../../shared/services/colorSet.service';

@Component({
  selector: 'he-project-forecast-value-price',
  templateUrl: './project-forecast-value-price.component.html',
  styleUrls: ['./project-forecast-value-price.component.scss'],
})
export class ProjectForecastValuePriceComponent implements OnInit, OnChanges,OnDestroy {
  @ViewChild('dataToExport', {static: false}) public dataToExport: ElementRef | any;
  @ViewChild('downloadLink') downloadLink: ElementRef| any;
  @ViewChild('canvas') canvas: ElementRef| any;
  @Input() chartData: any;
  @Input() assetData: any;
  @Input() trendLine: any;
  @Input() country: any;
  @Input() defaultBrands: any = [];
  @Input() minValueScore: any;
  @Input() selectedCurrency: string="";
  @Input() infoData: any[]=[];
  @Input() selectedCurrencySymbol: string="";

  public info: string="";
  selectedPriceType = 'launchPrice';

  priceTypes: Dropdown[] = [];

  public chartOption!: echarts.EChartsOption;
  public updateChartOption!: echarts.EChartsOption;

  public echartsInstance!: echarts.ECharts;
  seriesData: any[] = [];

  public analogueData: any[] = [];

  sortingActive: boolean = false;

  legends: string[] = [];
  chartNameInput: any = 'VALUE-PRICE ANALYSIS';
  private unsubscriber$ = new Subject<void>();

  constructor(
    private headerService: HeaderService,
    private exportImageService: ExportImageService,
    private analogueService: AnalogueService,
    private route: ActivatedRoute,
    private headerservice: HeaderService,
    private colorSetService: ColorSetService
  ) {}

  resetGraph() {
    this.headerService.setSelectedBrands(this.defaultBrands);
  }
  ngOnInit(): void {
    //this.refreshChart();
    this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(async (f) => {
      await this.initializeCompData();
      this.initChartOptions();
      this.priceTypes = this.headerService.priceTypes;
      this.priceTypes.filter(x=> x.value === 'netPrice')[0].visible = this.headerservice.netPricePermission.isNetPriceAvailable
      this.priceTypes.filter(x=> x.value === 'netPrice')[0].disabled = !this.headerservice.netPricePermission.isNetPriceVisible
      this.priceTypes.sort((a, b) => a.id - b.id);
    });

    this.analogueService.castAnalogueGridData.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {          
      this.analogueData = data;
    })

    this.headerService.onPriceTypeChanged.pipe(takeUntil(this.unsubscriber$)).subscribe((priceType) => {
      this.selectedPriceType=priceType;
    });

  }

  
  private async initializeCompData(){
    this.legends = [];
    this.seriesData=[];
    this.analogueData=[];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshChart();
    this.setInfoData();
  }

  public setInfoData():void{
    let price = this.priceTypes.filter((x) => x.value === this.selectedPriceType)[0].viewValue;
    if(this.infoData.length > 0){
      this.info = this.infoData.filter((x:any)=> x.priceType === price)[0].infoIconData;
    }
  }

  private initChartOptions() {
    this.chartOption = {
      backgroundColor: '#ffffff',
      tooltip: {
        formatter(params: any) {
          return [].join('<br/>');
        },
      },
      legend: {
        orient: 'vertical',
        left: 0,
        icon: 'circle',
        data: [],
        selectedMode: false ,
        padding: [25, 0],
      },
      grid: {
        left: '25%',
        right: '20%',
        bottom: '20%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        name: 'VALUE SCORES',
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 50,
        type: 'value',
        min: this.minValueScore,
        scale: true,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: `${this.selectedCurrencySymbol}, COST OF TREATMENT`,
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 80,
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [],
    };
  }

  async refreshChart() {
    this.seriesData = [];
    this.legends = [];
    this.chartOption = {};
    this.updateChartOption = {};

    const assetData = this.assetData;
    const country = this.country;

    if(this.analogueData) {
      let count = 0;
      this.analogueData.forEach((item: any) => {
        if(item.position !== null){
          count = count + 1;
        }
      })
      if(count == this.analogueData.length) {
        this.sortingActive = true;
      }
    }
    if (this.sortingActive) {
    await this.sortChartData();}
    if (Array.isArray(this.chartData)) {
      let brandList:any[] = []
      for (let i = 0; i < this.chartData.length; i++) {
        const item = this.chartData[i];
        const currencySymbol=this.selectedCurrencySymbol;
        if(!brandList.some(x=> x.name === item.name)){
          brandList.push(item)
          item.color = this.colorSetService.setColor(item.name);
        }else{
          item.color = brandList.filter(x=> x.name === item.name)[0].color
        }
        this.seriesData.push({
          name: Capitalise(item.name),
          type: 'effectScatter',
          symbolSize: 10,
          data: [[item.totalValueScore, item.actualPrice]],
          showEffectOn: "emphasis",
          tooltip: {
            formatter(params: any) {
              const data = params.data || [0, 0];
              return [
                '<b>' + params.seriesName.toString() + '</b>',
                country,
                'VS: ' + item.totalValueScore.toFixed(0),
                'COT: ' +
                currencySymbol +
                  data[1]
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              ].join('<br/>');
            },
          },
          itemStyle: {
            color: item.color,
            width: 2,
            height: 2,
          },
        });
        this.legends.push(Capitalise(item.name));
      }
      const currencySymbol=this.selectedCurrencySymbol;
      this.seriesData.push({
        name: 'AssetPoint',
        type: 'effectScatter',
        symbol: 'emptyCircle',
        symbolSize: 8,
        data: [
          [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
        ],
        tooltip: {
          formatter(params: any) {
            const data = params.data || [0, 0];
            return [
              country,
              'VS: ' + data[0].toFixed(0),
              'COT: ' +
                currencySymbol +
                data[1]
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            ].join('<br/>');
          },
        },
        itemStyle: {
          color: '#716BC1',
          width: 2,
          height: 2,
          opacity: 0.47,
        },
      });

      this.seriesData.push({
        name: 'AssetPointLine1',
        type: 'line',
        showSymbol: false,
        lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
        data: [
          [0, this.assetData?.predictedPrice],
          [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
        ],
        zlevel: 0,
      });
      
      this.seriesData.push({
        name: 'AssetPointLine1Label',
        type: 'scatter',
        symbolSize: 1,
        data: [[this.minValueScore, this.assetData?.predictedPrice]],
        label: {
          normal: {
            show: true,
            formatter:
              currencySymbol +
              parseFloat(this.assetData?.predictedPrice)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            borderRadius: 15,
            padding: 8,
            backgroundColor: '#716BC1',
            color: '#ffffff',
            fontSize: 14,
            lineHeight: 18,
            fontWeight: 700,
            opacity: 1,
          },
        },
        zlevel: 2,
      });

      this.seriesData.push({
        name: 'AssetPointLine2',
        type: 'line',
        showSymbol: false,
        lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
        data: [
          [this.assetData?.totalValueScore, 0],
          [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
        ],
        zlevel: 0,
      });

      this.seriesData.push({
        name: 'AssetPointLine1Labe2',
        type: 'scatter',
        symbolSize: 1,
        data: [[this.assetData?.totalValueScore, 0]],
        label: {
          normal: {
            show: true,
            formatter: parseFloat(this.assetData?.totalValueScore).toFixed(0),
            borderRadius: 15,
            padding: 8,
            backgroundColor: '#374050',
            color: '#ffffff',
            fontSize: 14,
            lineHeight: 18,
            fontWeight: 500,
            opacity: 1,
          },
        },
        zlevel: 2,
      });

      this.seriesData.push({
        name: 'TrendLine',
        type: 'line',
        showSymbol: false,
        lineStyle: { color: '#716BC1', width: 5 },
        data: [
          [this.trendLine[0]?.x, this.trendLine[0]?.y],
          [this.trendLine[1]?.x, this.trendLine[1]?.y],
        ],
      });
    }

    this.chartOption = {
      backgroundColor: '#ffffff',
      tooltip: {
        formatter(params: any) {
          return [assetData.name, country].join('<br/>');
        },
      },
      legend: {
        orient: 'vertical',
        left: 0,
        type: "scroll",
        icon: 'circle',
        data: [...this.legends],
        selectedMode: this.legends.length <= 2 ? false : 'multiple',
        padding: [25, 0],
      },
      grid: {
        left: '25%',
        right: '20%',
        bottom: '20%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        name: 'VALUE SCORES',
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 50,
        type: 'value',
        min: this.minValueScore,
        scale: true,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: `${this.selectedCurrencySymbol}, COST OF TREATMENT`,
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 80,
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [...this.seriesData],
    };
    this.updateChartOption = JSON.parse(JSON.stringify(this.chartOption));
  }

  onLegendSelectChanged(params: any) {  
      const brandsForRegression: string[] = [];
      this.legends.forEach((f) => {
        if (params.selected[f]) {
          brandsForRegression.push(f);
        }
      });
      this.headerService.setSelectedBrands(brandsForRegression);
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    this.echartsInstance.on('legendselectchanged', (params: any) => {

      const brandsForRegression: string[] = [];
      this.legends.forEach((f) => {
        if (params.selected[f]) {
          brandsForRegression.push(f);
        }
      });

      ec.setOption(this.chartOption);

      this.headerService.setSelectedBrands(brandsForRegression);
    });
    this.exportImageService.registerApi(ec, this.chartNameInput);
  }

  dwonloadFile() {
    this.exportImageService.saveImage(this.chartNameInput, this.chartNameInput);
  }

  public downloadAsPdf(): void {
    html2canvas(this.dataToExport.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.chartNameInput + '.png';
      this.downloadLink.nativeElement.click();
    });
  }

  public async sortChartData() {
    if(this.chartData)
    this.chartData.sort((a:any, b:any) => {
      return this.analogueData.filter(x=>x.brandedInnName.toLowerCase()===a.name.toLowerCase())[0]?.position 
      - this.analogueData.filter(x=>x.brandedInnName.toLowerCase()===b.name.toLowerCase())[0]?.position; 
    });
  }
  priceTypeChange($event:string) {
    this.headerService.setSelectedPriceType($event);
    this.setInfoData();
  }


  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
