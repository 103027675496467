import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, debounceTime } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AnalogueCotDataFormat } from '../models/AnalogueCotDataFormat';
import { AnalogueMasterData } from '../models/AnalogueMaseterData';
import { AnalogueRegulatoryApprovalFormat } from '../models/AnalogueRegulatoryApprovalFormat';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnalogueService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient, private activatedRouter: ActivatedRoute) {
    this.activatedRouter.queryParams.subscribe((p) => {
      this.analogueCotData = undefined;
      this.analogueRegulatoryData = undefined;
    })
  }

  public analogueGridData = new BehaviorSubject<any>([]);
  public castAnalogueGridData = this.analogueGridData.asObservable();

  public analogueCotData: any = undefined;
  public analogueCotDataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public analogueRegulatoryData: any = undefined;
  public analogueRegulatoryDataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  getAnalogueCotData(
    projectId: string
  ): Promise<AnalogueCotDataFormat[]> {
    return new Promise((resolve, reject) => {
      if (this.analogueCotData)
        resolve(this.analogueCotData);
      else {
        this.analogueCotDataLoading.next(true);
        this.http
          .get<AnalogueCotDataFormat[]>(
            this.projectAPIUri +
            `/Analogue/BrandCotData?projectId=${projectId}`
          )
          .subscribe({
            next: (data) => {
              resolve(data);
              this.analogueCotData = data;
              this.analogueCotDataLoading.next(false);
              setTimeout(() => { this.analogueCotData = undefined }, 10000);
            },
            error: (err) => { reject(err); },
          });
      }

    });
  }

  getAnalogueRegulatoryData(
    projectId: string
  ): Promise<AnalogueRegulatoryApprovalFormat[]> {
    return new Promise((resolve, reject) => {
      if (this.analogueRegulatoryData)
        resolve(this.analogueRegulatoryData);
      else {
        this.analogueRegulatoryDataLoading.next(true);
        this.http
          .get<AnalogueRegulatoryApprovalFormat[]>(
            this.projectAPIUri + `/Analogue/RegulatoryApprovalData?projectId=${projectId}`
          )
          .subscribe({
            next: (data) => {
              resolve(data);
              this.analogueRegulatoryData=data;
              this.analogueRegulatoryDataLoading.next(false);
              setTimeout(() => { this.analogueRegulatoryData = undefined }, 10000);
            },
            error: (err) => {
              reject(err);
            },
          });
      }
    });
  }


  getAnalogueMasterTLIs(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.http.get<string[]>(`${this.projectAPIUri}/Analogue/GetAnalogueMasterTLIs`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    })
  }

  getAnalogueMasterMOAs(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.http.get<string[]>(`${this.projectAPIUri}/Analogue/GetAnalogueMasterMOAs`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    })
  }

  getAnalogueMaster(TLI: string, MOA: string): Promise<AnalogueMasterData[]> {
    return new Promise<AnalogueMasterData[]>((resolve, reject) => {
      this.http.get<AnalogueMasterData[]>(`${this.projectAPIUri}/Analogue/GetAnalogueMaster?TLI=${TLI}&MOA=${MOA}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    })
  }


}
