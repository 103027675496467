import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, startWith, map, take } from 'rxjs';
import {
  CriteriaInterface,
  multiInputListInterface,
} from '../../models/criteria.interface';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { MatOption } from '@angular/material/core';
@Component({
  selector: 'he-criteria-input',
  templateUrl: './criteria-input.component.html',
  styleUrls: ['./criteria-input.component.scss'],
})
export class CriteriaInputComponent implements OnInit {
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;

  @Input()
  public inputdata!: CriteriaInterface;
  @Output()
  public onValueChange = new EventEmitter<any>();
  // @ViewChild('allSelected') private allSelected: MatOption;
  public toppings = new FormControl('');
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public fruitCtrl = new FormControl('');
  public filteredList: Observable<string[]>;
  public fruits: string[] = [];
  public allFruits: string[] = [];
  public defaultThearpyList: any[] = [];
  public defaultThearpyList2: any[] = [];
  public allSelected: boolean = false;

  public selectToggle(value: string): void {
    this.inputdata.selectedValue = value;
    this.onValueChange.emit();
  }

  constructor(private criteriaSelectionService: CriteriaSelectionService) {
    this.filteredList = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit
          ? this._filter(fruit)
          : (this.inputdata.valueList as string[]).slice()
      )
    );
  }

  ngOnInit(): void {
    if (this.inputdata.inputType == '4') {
      this.criteriaSelectionService.castTherapyAreaList
        .pipe(take(1))
        .subscribe((res) => {
          this.defaultThearpyList = res;
          this.defaultThearpyList2 = res;
        });
    }
  }

  toggleAllSelection(index:number) {
    if (!(this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll) {
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll= true;
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectedIndicationValue = (this.inputdata.multiInputList as multiInputListInterface[])[index].indicationValueList.map((x:any)=> x.name)
    // //   this.searchUserForm.controls.userType
    // //     .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
    } else {
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll = false;
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectedIndicationValue = [];
    }
  }

  remove(fruit: string): void {
    const index = (this.inputdata.selectedValueList as string[]).indexOf(fruit);
    if (index >= 0) {
      (this.inputdata.selectedValueList as string[]).splice(index, 1);
    }
    this.onValueChange.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (
      (this.inputdata.selectedValueList as string[]).some(
        (x) => x === event.option.viewValue
      )
    ) {
      this.remove(event.option.viewValue);
    } else {
      (this.inputdata.selectedValueList as string[]).push(
        event.option.viewValue
      );
    }

    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.onValueChange.emit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return (this.inputdata.valueList as string[]).filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  public selectMultiValue(index: number, value: string) {
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].selectedTherapyValue = value;
    let valueListIndex = (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].therapyValueList.findIndex((res) => res.name === value);
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].indicationValueList = (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].therapyValueList[valueListIndex].valueList;
    this.removeFromList();
    this.onValueChange.emit();
  }

  public removeFromList(): void {
    this.inputdata.multiInputList?.forEach((therapyArea1, newIndex1) => {
      this.defaultThearpyList2 = JSON.parse(
        JSON.stringify(this.defaultThearpyList)
      );
      this.inputdata.multiInputList?.forEach((therapyAreaNew, newIndex2) => {
        if (newIndex1 !== newIndex2) {
          if (therapyAreaNew.selectedTherapyValue !== '') {
            let newRemoveIndex = this.defaultThearpyList2.findIndex(
              (res) => res.name === therapyAreaNew.selectedTherapyValue
            );
            this.defaultThearpyList2.splice(newRemoveIndex, 1);
          }
        }
      });
      (this.inputdata.multiInputList as multiInputListInterface[])[
        newIndex1
      ].therapyValueList = JSON.parse(JSON.stringify(this.defaultThearpyList2));
    });
  }

  public selectIndicationMultiValue(index: number, value: string[]) {
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].selectedIndicationValue = value;
    this.onValueChange.emit();
  }

  public addNew(): void {
    this.inputdata.multiInputList?.push({
      therapyValueList: this.inputdata.multiInputList[0].therapyValueList,
      selectedTherapyValue: '',
      indicationValueList: [],
      selectedIndicationValue: [],
      selectAll: false,
    });
    this.removeFromList();
  }

  public removeInput(index:number):void{
    this.inputdata.multiInputList?.splice(index,1)
    this.removeFromList();
  }
}
