import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {AnalogueCotDataFormat } from '../../../../horizon-project/models/AnalogueCotDataFormat';
import { ActivatedRoute } from '@angular/router';
import {Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { Subject, takeUntil } from 'rxjs';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';

@Component({
  selector: 'he-project-analogues-section',
  templateUrl: './project-analogues-section.component.html',
  styleUrls: ['./project-analogues-section.component.scss']
})

export class ProjectAnaloguesSectionComponent implements OnInit,AfterViewChecked,OnDestroy {
  public showSection = true ;
  public displayedColumns: string[] = ['brandName', 'indication', 'mechanism'];
  dataSource = new MatTableDataSource([{}]);
  projectId = '';
  cotData: AnalogueCotDataFormat[] = [];
  formattedCotGridData: any[] = [];
  countries: string[] = [];
  brands: any = [];
  brandObj: any = {};

  private unsubscriber$ = new Subject<void>();

  constructor(
    private activatedRouter: ActivatedRoute,
    private analogueService:AnalogueService,
    private cdRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.analogueService.analogueCotDataLoading.pipe(takeUntil(this.unsubscriber$)).subscribe((data)=>{
      this.showSection=!data;
    })
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  private async loadData() {
    this.activatedRouter.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(async (p) => {
      this.projectId = p.project;
      this.formattedCotGridData = [];
      if (this.projectId){
        await this.analogueService
          .getAnalogueCotData(this.projectId)
          .then((cotData: AnalogueCotDataFormat[]) => {
            this.cotData = cotData;            
          }).then(()=>{
            this.formatCotGridData();
          });
      }
    });
  }

  public formatCotGridData() {
    this.formattedCotGridData = [];
    this.brands = [];
    this.brandObj = {};
    const countries = this.cotData
      ? [...new Set(this.cotData.map((item) => Capitalise(item.country)))]
      : [];
    this.countries = countries.sort().reverse();
    this.cotData.forEach((element,index) => {
      let brandObj = {
        brandName: element.brandName,
        tli: element.tli,
        inn : element.inn
      }
      this.brands.filter((brand:any) => brand.brandName === element.brandName && 
      brand.tli === element.tli && brand.inn === element.inn).length === 0 ? this.brands.push(brandObj) : null;
    })

    this.brands.forEach((element:any, index:any) => {
      const brandsCotData = this.cotData.filter(
        (x) => x.brandName.toLowerCase().trim() === element.brandName.toLowerCase().trim() 
        && x.tli.toLowerCase().trim() === element.tli.toLowerCase().trim() && x.inn.toLowerCase().trim() === element.inn.toLowerCase().trim()
      );
      const formatCotRow: any = {
        highlight: true,
        position: index + 1,
        brandName:  Capitalise(brandsCotData[0].brandName),
        indication: Capitalise(brandsCotData[0].tli),
        mechanism: Capitalise(brandsCotData[0].moa),
        sequenceNo: (brandsCotData[0].sequenceNo),

      };
      this.formattedCotGridData.push(formatCotRow);

    });
    this.dataSource =  new MatTableDataSource(this.formattedCotGridData);
    this.displayedColumns = [
      'sequenceNo',
      'brandName',
      'indication',
      'mechanism',
    ];
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
