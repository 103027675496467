import { Component, ViewChild } from '@angular/core';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../../../shared/services/header.service';
import { UploadDataService } from '../../services/upload-data.service';
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';
import {
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'he-upload-data-page',
  templateUrl: './upload-data-page.component.html',
  styleUrls: ['./upload-data-page.component.scss'],
})
export class UploadDataPageComponent {
  @ViewChild('fileDropRef') public fileDropRef: any;

  uploadMenu=[
    {viewValue:'COT Data',value:'cotData'},
    {viewValue:'Tagging Data',value:'taggingData'},
    {viewValue:'Analogue Info Data',value:'analogueInfoData'},
    {viewValue:'Value Score Data',value:'valueScoreData'},
    {viewValue:'Reference Data', value:'referenceData'}
  ]
  files: any[] = [];
  fileListData: any = [];
  fileName: string[] = ['xlxs', 'csv'];
  NewFileList: any = [];

  displayErrors: boolean = false;
  uploadingStatus: boolean = false;
  uploadingSuccess: boolean = false;
  currentUploadingIndex:number = 0;
  uploadedFiles:any=[];
  failedFiles:any=[]

  currentTab:string = ''
  currentTabName:any=''

  countries:any=[]
  currencies:any=[]

  filteredCurrency: Observable<any[]> = new Observable<any[]>();

  uploadForm = new FormGroup({
    country:new FormControl('',[Validators.required]),
    currency:new FormControl('',[Validators.required])
  })


  constructor(
    private toastNotificationService: ToastNotificationService,
    private router: Router,private activatedRoute:ActivatedRoute,
    private headerService:HeaderService,
    private uploadDataService:UploadDataService,
  ) {
    this.filteredCurrency =
    this.uploadForm.controls.currency.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map((value) => this._filterCurrency(value || ''))
    );
  }

  ngOnInit(){
    this.getCurrentTab()
    this.getCountriesData()
    this.getCurrencyData()
  }

  _filterCurrency(val: string): any[] {
    const filterVal = val.toLowerCase();
    return this.currencies.filter((x:any) => x.currency.toLowerCase().includes(filterVal));
  }

  displayCurrencyFn(data: string): string {
    return data;
  }

  get country(): AbstractControl<any, any> | null{
    return this.uploadForm.get('country');
  }
  
  get currency(): AbstractControl<any, any> | null{
    return this.uploadForm.get('currency');
  }

  getCurrentTab(){
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.currentTab = res.data
      this.currentTabName = this.getCurrentTabName(this.currentTab)
      this.initializeVariable()
    })
  }

  initializeVariable(){
    this.files = [];
    this.fileListData= [];
    this.NewFileList = []
    this.displayErrors = false;
    this.uploadingStatus = false;
    this.uploadingSuccess = false;
    this.currentUploadingIndex = 0;
    this.uploadedFiles = [];
    this.failedFiles=[]
    this.uploadForm.reset() 
    this.uploadDataService.fileListDataLength.next(this.fileListData.length)
  }

  getCurrentTabName(currentTab:any){
    return this.uploadMenu.find((item) => item.value === currentTab)?.viewValue;
  }

  private async getCountriesData(){
    await this.uploadDataService
    .getCountriesData()
    .then((data: any) => {
      this.countries = data;
    })
    .catch((err) => {
      console.log(err);
      this.toastNotificationService.errorMessage(
        'Unable to fetch Countries Data',
        'info',
        10000
      );
    });
  }

  private async getCurrencyData(){
    await this.uploadDataService
    .getCurrencyData()
    .then((data: any) => {
      this.currencies = data;
      this.currencies.push({
          currency: "USD",
          rate: 1,
          inverse: 1
      })
    })
    .catch((err) => {
      console.log(err);
      this.toastNotificationService.errorMessage(
        'Unable to fetch Currency Data',
        'info',
        10000
      );
    });
  }

  toggleErrors() {
    this.displayErrors = !this.displayErrors;
  }

  getAccordionStyle() {
    return this.displayErrors ? 'error-visible' : 'error-hidden';
  }

  getUploadingStyle(index:any) {
    if (this.uploadedFiles.includes(index)) {
      return 'uploaded-file';
    } else if (this.checkFailedStatus(index)) {
      return 'failed-file';
    }
    return 'single-file';
  }

  getButtonText(index:any){
    if(this.currentUploadingIndex === index && this.uploadingStatus){
      return 'Uploading'
    }
    else if(this.currentUploadingIndex !== index && this.uploadingStatus){
      return 'Pending Upload'
    }
    return 'Upload'
  }

  checkFailedStatus(index:any){
    const file = this.failedFiles.find((item:any)=>item.id === index)
    if(file){
      return true
    }
    return false
  }

  getErrorsDesc(index:any){
   const desc = this.failedFiles.find((item:any)=>item.id === index).error
   return desc
  }

  onFileDropped($event: any) {
    if($event.length > 1){
      this.toastNotificationService.errorMessage("Please select one file at a time")
      return 
    }
    this.prepareFilesList($event);
  }

  fileBrowseHandler($event?: any) {
    this.prepareFilesList($event.target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileListData.splice(index, 1);
    this.uploadDataService.fileListDataLength.next(this.fileListData.length)
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   * @param Bytes (Bytes in KB, MB, GB)
   * @param decimals (Decimals point in size)
   */

  prepareFilesList(files: Array<any>) {
    this.fileListData = []
    this.failedFiles = []
    this.uploadedFiles = []
    this.uploadForm.reset()
    const maxFIleSize = 10000000;
    this.uploadDataService.fileDataStatus.next(false);
    for (const item of files) {
      if (this.fileName.includes(item.name.split('.').pop())) {
        if (item.size <= maxFIleSize) {
          this.files.push(item);
          this.fileListData.push(item);
          this.uploadDataService.fileListDataLength.next(this.fileListData.length)
        } else {
          this.toastNotificationService.errorMessage(
            'File size needs to be less than 10MB'
          );
        }
      } else {
        this.toastNotificationService.errorMessage('File type not supported');
      }
    }
    // this.projectService.uploadFileList.next(this.fileListData);
    this.fileDropRef.nativeElement.value = '';
  }

  // public checkDuplicateFile(file:any):boolean{
  //   let duplicate = false;
  //   this.NewFileList.forEach((element:any)=>{
  //     if(element?.name === file.name){
  //       duplicate = true;
  //     }
  //   })
  //   this.fileListData.forEach((element:any)=>{
  //     if(element?.name === file.name){
  //       duplicate = true;
  //     }
  //   })
  //   return duplicate;
  // }

  formatBytes(bytes?: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadData(index:any): void {
    switch(this.currentTab){
      case 'cotData':
        this.uploadCOTData(index)
        break
      case 'taggingData':
        this.uploadTaggingData(index)
        break
      case 'analogueInfoData':
        this.uploadAnalogueInfoData(index)
        break
      case 'valueScoreData':
        this.uploadValueScoreData(index)
        break
      case 'referenceData':
        this.uploadAnalogueHTAInfoData(index)
        break
      default:
        this.toastNotificationService.errorMessage('No Data Type Found')
    }
  }

    uploadCOTData(index:any){
    if(this.uploadForm.controls.country.invalid && this.uploadForm.controls.currency.invalid){
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else{
    this.currentUploadingIndex = index
    this.uploadingStatus = true
    this.uploadDataService.uploadCOTData(this.uploadForm.value,this.fileListData).then((res)=>{
      if(res.status === 1){
        this.toastNotificationService.successMessage('COT Data Uploaded Successfully')
        this.afterSuccess(index);
      }
      else{
        this.toastNotificationService.errorMessage('COT Data Uploading Failed')
        this.failedFiles.push({
          id:index,error:res.data
        })
        this.afterError();
      }
    }).catch((err)=>{
      console.log(err)
      this.toastNotificationService.errorMessage('COT Data Uploading Failed')
      this.failedFiles.push({
        id:index,error:err.error.data
      })
      this.afterError();
    })
  }
  }

  uploadTaggingData(index:any){
    if(this.uploadForm.controls.country.invalid){
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else{
    this.currentUploadingIndex = index
    this.uploadingStatus = true
    this.uploadDataService.uploadTaggingData(this.uploadForm.value,this.fileListData).then((res)=>{
      if(res.status === 1){
        this.toastNotificationService.successMessage('Tagging Data Uploaded Successfully')
        this.afterSuccess(index);
      }
      else{
        this.toastNotificationService.errorMessage('Tagging Data Uploading Failed')
        this.failedFiles.push({
          id:index,error:res.data
        })
        this.afterError();
      }
    }).catch((err)=>{
      console.log(err)
      this.toastNotificationService.errorMessage('Tagging Data Uploading Failed')
      this.failedFiles.push({
        id:index,error:err.error.data
      })
      this.afterError();
    })
  }
  }

  uploadAnalogueInfoData(index:any){
    if(this.uploadForm.controls.country.invalid){
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else{
    this.currentUploadingIndex = index
    this.uploadingStatus = true
    this.uploadDataService.uploadAnalogueInfoData(this.uploadForm.value,this.fileListData).then((res)=>{
      if(res.status === 1){
        this.toastNotificationService.successMessage('Analogue Info Data Uploaded Successfully')
        this.afterSuccess(index);
      }
      else{
        this.toastNotificationService.errorMessage('Analogue Info Data Uploading Failed')
        this.failedFiles.push({
          id:index,error:res.data
        })
        this.afterError();
      }
    }).catch((err)=>{
      console.log(err)
      this.toastNotificationService.errorMessage('Analogue Info Data Uploading Failed')
      this.failedFiles.push({
        id:index,error:err.error.data
      })
      this.afterError();
    })
  }
  }


  uploadValueScoreData(index:any){
    if(this.uploadForm.controls.country.invalid){
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else{
    this.currentUploadingIndex = index
    this.uploadingStatus = true
    this.uploadDataService.uploadValueScoreData(this.uploadForm.value,this.fileListData).then((res)=>{
      if(res.status === 1){
        this.toastNotificationService.successMessage('Value Score Data Uploaded Successfully')
        this.afterSuccess(index);
      }
      else{
        this.toastNotificationService.errorMessage('Value Score Data Uploading Failed')
        this.failedFiles.push({
          id:index,error:res.data
        })
        this.afterError();
      }
    }).catch((err)=>{
      console.log(err)
      this.toastNotificationService.errorMessage('Value Score Data Uploading Failed')
      this.failedFiles.push({
        id:index,error:err.error.data
      })
      this.afterError();
    })
  }
  }

  uploadAnalogueHTAInfoData(index:any){
    if(this.uploadForm.controls.country.invalid){
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else{
    this.currentUploadingIndex = index
    this.uploadingStatus = true
    this.uploadDataService.uploadAnalogueHTAInfoData(this.uploadForm.value,this.fileListData).then((res)=>{
      if(res.status === 1){
        this.toastNotificationService.successMessage('Reference Data Uploaded Successfully')
        this.afterSuccess(index);
      }
      else{
        this.toastNotificationService.errorMessage('Reference Data Uploading Failed')
        this.failedFiles.push({
          id:index,error:res.data
        })
        this.afterError();
      }
    }).catch((err)=>{
      console.log(err)
      this.toastNotificationService.errorMessage('Reference Data Uploading Failed')
      this.failedFiles.push({
        id:index,error:err.error.data
      })
      this.afterError();
    })
  }
  }

  
  afterSuccess(index:any) {
    this.uploadingStatus = false;
    this.currentUploadingIndex = 0;
    this.uploadedFiles.push(index);
    this.uploadDataService.fileDataStatus.next(true);
  }

  afterError() {
    this.currentUploadingIndex = 0;
    this.uploadingStatus = false;
    // this.displayErrors = true;
    this.uploadDataService.fileDataStatus.next(false);
  }


}
