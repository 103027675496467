<div class="d-flex d-align-c d-j-between margin-padding2">
  <div class="custom-input custom-autocomplete">
    <mat-form-field appearance="outline" id="search-analogue-field">
      <input
        matInput
        [formControl]="searchTable"
        placeholder="Search below table"
      />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="d-flex d-align-c d-j-start"></div>
  <div class="d-flex d-align-c d-j-between legends">
    <p class="legends-main-title">Legend:</p>
    <div *ngFor="let legend of legends" class="d-flex d-align-c d-j-c">
      <div
        class="legend-color"
        [ngStyle]="{ 'background-color': legend.color }"
      ></div>
      <p class="legend-name">{{ legend.name }}</p>
    </div>
  </div>
</div>
<div class="margin-padding3">
  <div class="table-container analogue-selection-table">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      id="analogue-heat-map-table"
    >
      <ng-container matColumnDef="toggle" sticky>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="criteria-toggle">
          <mat-slide-toggle
            class="example-margin"
            (change)="selectBrand($event.checked, element)"
            [checked]="element.toggle"
            [disabled]="disabled"
          >
          </mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="Brand" sticky>
        <th mat-header-cell mat-sort-header="Brand" *matHeaderCellDef>Brand Name</th>
        <td mat-cell *matCellDef="let element">{{ element.Brand }}</td>
      </ng-container>

      <ng-container matColumnDef="INN" sticky>
        <th mat-header-cell mat-sort-header="INN" *matHeaderCellDef>INN</th>
        <td mat-cell *matCellDef="let element">{{ element.INN }}</td>
      </ng-container>
      <ng-container matColumnDef="Market">
        <th mat-header-cell mat-sort-header="Market" *matHeaderCellDef>Market Data Availability</th>
        <td mat-cell *matCellDef="let element">
          <div class="country-list" >
            <ng-container *ngFor="let market of element.Market">
              <img #tooltip="matTooltip"
              matTooltip="{{market.name}}" class="flag" src='{{ "assets/flags/" + market.id + ".svg" }}' width="22px">
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Orphan">
        <th mat-header-cell mat-sort-header="Orphan" *matHeaderCellDef>
          Orphan
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.Orphan.color }"
        >
          {{ element.Orphan.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Oneofftherapy">
        <th mat-header-cell mat-sort-header="Oneofftherapy" *matHeaderCellDef>
          One-off therapy
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.Oneofftherapy.color }"
        >
          {{ element.Oneofftherapy.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Population">
        <th mat-header-cell mat-sort-header="Population" *matHeaderCellDef>
          Population
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.Population.color }"
        >
          {{ element.Population.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Eligiblepatientnumber">
        <th
          mat-header-cell
          mat-sort-header="Eligiblepatientnumber"
          *matHeaderCellDef
        >
          Eligible patient number
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{
            'background-color': element.Eligiblepatientnumber.color
          }"
        >
          {{ element.Eligiblepatientnumber.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Indication">
        <th mat-header-cell mat-sort-header="Indication" *matHeaderCellDef>
          Indication
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.Indication.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Routeofadministration">
        <th
          mat-header-cell
          mat-sort-header="Routeofadministration"
          *matHeaderCellDef
        >
          Route of administration
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{
            'background-color': element.Routeofadministration.color
          }"
        >
          {{ element.Routeofadministration.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Mechanismofaction">
        <th
          mat-header-cell
          mat-sort-header="Mechanismofaction"
          *matHeaderCellDef
        >
          Mechanism of action category
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.Mechanismofaction.color }"
        >
          {{ element.Mechanismofaction.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="TherapyArea">
        <th
          mat-header-cell
          mat-sort-header="TherapyArea"
          *matHeaderCellDef
        >
        Therapy Area
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.TherapyArea.color }"
        >
          {{ element.TherapyArea.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="IndicationCategory">
        <th
          mat-header-cell
          mat-sort-header="IndicationCategory"
          *matHeaderCellDef
        >
        Indication Category
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element.IndicationCategory.color }"
        >
          {{ element.IndicationCategory.name }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          <he-loading-spinner
            *ngIf="loading"
            [diameter]="70"
            [height]="'350px'"
          ></he-loading-spinner>
          <ng-container *ngIf="!loading">
            <div class="d-flex d-align-c d-j-c no-data">
              No data matching the filter
            </div>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>
