import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { reject, tap } from 'lodash';
import { ProjectService } from './project.service';
import { ToastNotificationService } from '../../../shared/services/toast-notification.service';
import { CriteriaSelectionService } from './criteria-selection.service';
import { NetPriceService } from './net-price.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDraftService {
                       
  constructor(private http: HttpClient,
    public projectService:ProjectService,private router: Router,public criteriaSelectionService:CriteriaSelectionService,
    private toastNotificationService: ToastNotificationService,private netPriceService: NetPriceService,
    ) { }
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  checkDraftProjectExist(){
    return new Promise((resolve,reject)=>{
      this.http.get<any>(this.projectAPIUri+'/Projects/IsProjectDraftExists')
      .subscribe({
        next:(data)=>resolve(data),
        error:(err)=>reject(err)
      })
    })
  }        

  saveAsDraft():void{
    const projectData = {
      projectName: this.projectService.createProjectFormData.value.projectName,
      projectDetails: this.projectService.createProjectFormData.value.projectDetails,
      assetName: this.projectService.createProjectFormData.value.assetName,
      topLevelIndication: this.projectService.createProjectFormData.value.topLevelIndication,
      moa: this.projectService.createProjectFormData.value.moa,
      framework:
        this.projectService.createProjectFormData.value.framework.valueFrameworkName,
      selectedMarkets: this.projectService.createProjectFormData.value.selectedMarkets,
      projectCollaborators: this.projectService.ProjectCollaborators,
      analogueSelectionCriteria : this.criteriaSelectionService.criteriaList.value,
      savedSelectedAnalogues: this.criteriaSelectionService.savedSelectedAnalogues.value,
      projectAnalogues: this.projectService.selectedAnalogues,
      selectedAnalogueNetprice: this.netPriceService.selectedAnalogueNetprice,
      projectNetPrice:{
        IsNetPriceIncluded:this.netPriceService.ProjectNetPrice.IsNetPriceIncluded,
        isAnnualCOTInlcuded: this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded,
        OverallNetPrice:this.netPriceService.ProjectNetPrice.OverallNetPrice,
        AnalogueNetPrice:this.netPriceService.ProjectNetPrice.AnalogueNetPrice,
      },
      brandCOunt:this.criteriaSelectionService.brandCountData.value,
      projectScenarios: this.setSenarios(),
    };
    const message = 'Project draft saved successfully'
    this.http.post<any>(this.projectAPIUri+'/Projects/SaveProjectAsDraft',projectData)
    .subscribe((data)=>{
      if(data){
        this.projectService.isDraftProjectFetched.next(false)
        this.projectService.isDraftAnalogueFetched.next(false)
        this.projectService.createProjectFormData.next({});
        this.projectService.clearScenario()
        this.router.navigateByUrl(`/horizon/list-projects`);
        this.toastNotificationService.successMessage(message);
      }
    },
    (error)=>{
      this.toastNotificationService.errorMessage(
        `Project save as draft was unsuccessful - ${error.error}`,
        'info',
        10000
      )
    })
  }

  setSenarios():any{
   let scenarios = this.projectService.scenarios;
   scenarios.forEach((scenario:any)=>{
    scenario.ValueAttributeScores.forEach((valueDomain: any) => {
      // if (isAllMarketVisible) {
        valueDomain.allCountries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            // if (valueAttribute.name === attribute && contry.name === country) {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if(valueAttribute.selectedScore === '' ||
                  valueAttribute.selectedScore === undefined){
                    valueAttribute.touched = false;
                  }
                  break;
                  case 'value':
                    if(valueDomain.inputType === 'value'){
                      if(valueAttribute.value === ''){
                        valueAttribute.touched = false;
                      }
                  
                    }else{
                      if(valueAttribute.rangeStart === '' && valueAttribute.rangeEnd === ''){
                        valueAttribute.touched = false;
                        }
                    }

                  break;
              }
            // }
          });
        })
        valueDomain.countries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if(valueAttribute.selectedScore === '' ||
                  valueAttribute.selectedScore === undefined){
                    valueAttribute.touched = false;
                  }
                  break;
                  case 'value':
                    if(valueDomain.inputType === 'value'){
                      if(valueAttribute.value === ''){
                        valueAttribute.touched = false;
                      }
                  
                    }else{
                      if(valueAttribute.rangeStart === '' && valueAttribute.rangeEnd === ''){
                        valueAttribute.touched = false;
                        }
                    }

                  break;
              }
            // }
          });
        });

    }); 
  })
    return scenarios;
  }

  getDraftedProject(){
    return new Promise((resolve,reject)=>{
      this.http.get<any>(this.projectAPIUri+'/Projects/GetProjectDraft')
      .subscribe({
        next:(data)=>resolve(data),
        error:(err)=>reject(err)
      })
    })
  }

  addDraftScenario(scenarioData:any){
    scenarioData.sort((a:any,b:any)=>a.Sequence - b.Sequence)
    if(this.projectService.scenarios.length ===0 ){
      this.projectService.scenarios = scenarioData;
    }
    this.projectService.autoAssignScenarioAlphaSequence()
  }

  deleteDraftProject():void{
    const message = 'Draft project deleted successfully'
    this.http.delete<any>(this.projectAPIUri+'/Projects/DeleteProjectDraft')
    .subscribe((data)=>{
      if(data.data){ 
        this.projectService.isDraftProjectFetched.next(false)
        this.projectService.isDraftAnalogueFetched.next(false)
        this.projectService.createProjectFormData.next({});  
        this.projectService.clearScenario()  
        this.router.navigateByUrl(`/horizon/list-projects`);
        this.toastNotificationService.successMessage(message);      }
    },
    (error)=>{
      this.toastNotificationService.errorMessage(
        `Project draft delete was unsuccessful - ${error.error}`,
        'info',
        10000
      )
    })
  }
  

}
