import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { NetPriceInterface } from '../models/netprice.interface';
import { ToastNotificationService } from '../../../shared/services/toast-notification.service';
import { UpdateFrameworkService } from '../../../shared/services/update-framework.service';

@Injectable({
  providedIn: 'root'
})
export class NetPriceService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  public ProjectNetPrice: NetPriceInterface = {
    IsNetPriceIncluded:false, 
    isAnnualCOTInlcuded:false, 
    OverallNetPrice:[], 
    AnalogueNetPrice:[], 
    AnaglueChanged:false
  };
  public heatMapPayload:any=null;
  public netPriceChange = new BehaviorSubject<NetPriceInterface>({
    IsNetPriceIncluded:false, 
    isAnnualCOTInlcuded:false, 
    OverallNetPrice:[], 
    AnalogueNetPrice:[], 
    AnaglueChanged:false
  });
  public valueChanged:boolean=false;
  public netErrorActive:boolean=false;
  public selectedMarkets:string[]=[]
  public castnetPriceChange = this.netPriceChange.asObservable();
  public selectedAnalogueNetprice:any[]=[]

  constructor(private http: HttpClient,public toastNotificationService:ToastNotificationService,private updateFrameworkService:UpdateFrameworkService) { }

  public getCountryNetPriceInfo(): Observable<any> {
    return this.http.get<any>(
      `${this.projectAPIUri}/NetPrice/GetCountryNetPriceInfo`
    );
  }

  public setToastError():void{
    if(!this.checkNetPrice()){
      if(!this.netErrorActive && this.ProjectNetPrice.isAnnualCOTInlcuded){
        this.toastNotificationService.errorMessageNew(
          'If you wish to include net price assumptions, please input the annual cost of treatment for all selected analogues or apply percentage discounts to proceed to the next stage',
          'info',
          10000
        )
        this.netErrorActive= true;
        this.updateFrameworkService.frameWorkErrorActive = false;
      }else if(!this.updateFrameworkService.frameWorkErrorActive && !this.ProjectNetPrice.isAnnualCOTInlcuded && this.updateFrameworkService.isUpdateFrameWorkComplete.value){
        this.toastNotificationService.dissmiss();
        this.netErrorActive = false;
      }else if(!this.updateFrameworkService.frameWorkErrorActive && !this.netErrorActive){
        this.toastNotificationService.dissmiss();
        this.updateFrameworkService.setUpdateError();
        this.netErrorActive= false;
      }
    }else{
      if(!this.updateFrameworkService.frameWorkErrorActive && !this.netErrorActive){
        this.toastNotificationService.dissmiss();
        this.updateFrameworkService.setUpdateError();
        this.netErrorActive= false;
      }
    }
  }

  public dismissNetError():void{
    this.toastNotificationService.dissmiss()
    this.netErrorActive= false;
  }
  
  public checkNetPrice():boolean{
    let valid = true;
    if(this.ProjectNetPrice.IsNetPriceIncluded){
      this.ProjectNetPrice.OverallNetPrice.forEach((res:any)=>{
        if(res.NetPriceDiscount > 100){
          valid = false;
        }else if(res.NetPriceDiscount <0){
          valid = false;
        }
      })
      this.ProjectNetPrice.AnalogueNetPrice.forEach((x:any)=>{
        this.ProjectNetPrice.OverallNetPrice.forEach((y:any)=>{
          if(x[y.country].NetPriceDiscount > 100){
            valid = false;
          }else if(x[y.country].NetPriceDiscount <0){
            valid = false;
          }
          if(this.ProjectNetPrice.isAnnualCOTInlcuded){
            if(x[y.country].AnalogueCOT <= 0){
              valid = false;
            }
          }else{
            this.netErrorActive = false;
          }
        })
      })
    }
    if(this.ProjectNetPrice.isAnnualCOTInlcuded && valid){
      this.netErrorActive = false;
    }
    return valid;
  }
  

  public setErrorNet():void{
    
  }

}
