<h1 mat-dialog-title class="mat-dialog-title">Steps to choose the analogues :</h1>
<div mat-dialog-content>
  <ol class="analogues-helper-list">
    <li>Choose the criteria you want to use to select the analogues</li>
    <li>Click on the "Find Analogues" button</li>
    <li>Choose the analogues you want to use from the table</li>
    <li>Click on the "Add Analogues" button</li>
    <li>Select the order in which you want the analogues to be available in the project</li>
  </ol>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
