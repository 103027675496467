import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface AssetTLIAndCountry {
  assetId: string;
  assetName: string;
  country: string;
  brand: string;
  inn: string;
  topLevelIndication: string;
}

export interface MarketArea {
  countryName: string;
}

export interface Collaborator {
  firstName: string;
  lastName: string;
  fullName: string;
  company: string;
  email: string;
}

export interface ProjectScenario{
  projectId: string;
  scenarioId: string;
  scenarioName: string;
}

export interface HorizonProjectDetailsResponse {
  projectOwnerName: string;
  assetTLIAndCountrys: AssetTLIAndCountry[];
  marketAreas: MarketArea[];
  collaborators: Collaborator[];
  name: string;
  description: string;
  ownerEmail: string;
  isDeleted: boolean;
  deletedOn?: any;
  id: string;
  createdOn: Date;
  modifiedOn: Date;
  createdBy: string;
  modifiedBy: string;
  projectFiles: string[];
  projectScenarios: ProjectScenario[];
  brandTLIAndCountrys:any[];
  updatedFrameworkDTO:any;
  valueFrameworkId?:string;
  projectFrameworkUpgradeLogsDTO?:any;
}

// export interface ProjectData {
//   projectId: string;
//   country: string;
//   scenario: string;
// }

@Injectable({
  providedIn: 'root',
})
export class HorizonProjectDetailsService {
  public projectId = '';
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  countryName = '';
  country = new BehaviorSubject<string>(this.countryName);

  scenarioName = '';
  scenario = new BehaviorSubject<string>(this.scenarioName);

  data = new BehaviorSubject<HorizonProjectDetailsResponse>({
    assetTLIAndCountrys: [],
    projectOwnerName: 'Default projectOwnerName',
    marketAreas: [],
    collaborators: [],
    name: 'Default name',
    description: 'Default description',
    ownerEmail: 'Default ownerEmail',
    isDeleted: false,
    id: 'Default id',
    createdOn: new Date(),
    modifiedOn: new Date(),
    createdBy: 'Default createdBy',
    modifiedBy: 'Default modifiedBy',
    projectFiles: [],
    projectScenarios: [],
    brandTLIAndCountrys: [],
  updatedFrameworkDTO:{}

  });

  data$ = this.data.asObservable();

  country$ = this.country.asObservable();
  scenario$ = this.scenario.asObservable();

  constructor(activatedRouter: ActivatedRoute, private http: HttpClient) {
    // activatedRouter.queryParams.subscribe((p) => {
    //   if (p && p.project) {
    //     this.projectId = p.project;
    //     this.http
    //       .get<HorizonProjectDetailsResponse>(
    //         this.projectAPIUri + `/Projects/project/${this.projectId}`
    //       )
    //       .subscribe((res) => this.data.next(res));
    //   }
    // });
  }

}
