import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  FilterItemInterface,
  FilterItemValueInterface,
  FiltersObject,
  SetOfFiltersInterface
} from './interfaces';
import {
  FilterIncludeExcludeEnum,
  FilterMeasuresPeriodEnum,
  FilterMeasuresPriceCategoryEnum,
  FilterMeasuresPriceTypeEnum,
  FilterNameEnum,
  FilterProductParamsBrandedStatusEnum,
  FilterProductParamsOrphanStatusEnum,
  FilterProductParamsReimbursementEnum
} from './enum';
import { SessionStoreBase } from '../../app/common/session-store-service';
import { SessionEnums } from '../../app/common';

@Injectable()
export class FiltersValueService {
  constructor(private session: SessionStoreBase) { }

  public filterValues$: BehaviorSubject<FiltersObject> = new BehaviorSubject<FiltersObject>([
    { filterName: FilterNameEnum.indicationsAllowed, filterValue: [{ name: 'Melanoma' }] },
    { filterName: FilterNameEnum.indications, filterValue: [{ name: 'Melanoma' }] },
    { filterName: FilterNameEnum.countries, filterValue: [{ name: 'USA' }, { name: 'UK' }, { name: 'GERMANY' }] },
    { filterName: FilterNameEnum.brands, filterValue: [] },
    { filterName: FilterNameEnum.comparatorDrug, filterValue: [{ name: true }] },
    { filterName: FilterNameEnum.calcPer, filterValue: [{ name: 'Month', type: FilterMeasuresPeriodEnum.month }] },
    { filterName: FilterNameEnum.includeWastage, filterValue: [{ name: 'Include wastage', type: true }] },
    { filterName: FilterNameEnum.priceCategory, filterValue: [{ name: 'List', type: FilterMeasuresPriceCategoryEnum.List }] },
    { filterName: FilterNameEnum.priceType, filterValue: [{ name: 'Price Hierarchy', type: FilterMeasuresPriceTypeEnum.hierarchy }] },
    { filterName: FilterNameEnum.currency, filterValue: [{ name: 'Euro (€)', type: 'EUR' }] },
    { filterName: FilterNameEnum.orphanStatus, filterValue: [{ name: 'Include all', type: FilterProductParamsOrphanStatusEnum.all }] },
    { filterName: FilterNameEnum.brandedStatus, filterValue: [{ name: 'Include all', type: FilterProductParamsBrandedStatusEnum.all }] },
    { filterName: FilterNameEnum.discontinued, filterValue: [{ name: 'Exclude discontinued', type: FilterIncludeExcludeEnum.exclude }] },
    { filterName: FilterNameEnum.piStatus, filterValue: [{ name: 'Exclude PI', type: FilterIncludeExcludeEnum.exclude }] },
    {
      filterName: FilterNameEnum.reimbursementStatus, filterValue: [{
        name: 'Include All',
        type: FilterProductParamsReimbursementEnum.all
      }]
    },
    { filterName: FilterNameEnum.topLevelIndications, filterValue: [{ name: '' }] },
    { filterName: FilterNameEnum.onLoad, filterValue: [{ name: false }] }
  ]);
  public productpageFirstLoad = 0;
  public maxBrands = 15;
  public maxCountries = 20;
  public maxCotBrands = 500;

  public getProductpageFirstLoad() {
    return this.productpageFirstLoad;
  }
  public setProductpageFirstLoad(value: any) {
    this.productpageFirstLoad = value;
  }
  public brandValues$ = this.filterValues$.pipe(
    map((common) => this.findItemInFilters(common, FilterNameEnum.brands)?.filterValue.slice(0, this.maxBrands))
  );

  public countryValues$ = this.filterValues$.pipe(
    map((common) => this.findItemInFilters(common, FilterNameEnum.countries)?.filterValue)
  );

  public countryNames$ = this.countryValues$.pipe(
    map((country) => country?.map(x => x.name))
  );

  public indicationValues$ = this.filterValues$.pipe(
    map((common) => {
      return this.findItemInFilters(common, FilterNameEnum.indications)?.filterValue;
    })
  );

  public indicationAllowedValues$ = this.filterValues$.pipe(
    map((common) => {
      return this.findItemInFilters(common, FilterNameEnum.indicationsAllowed)?.filterValue;
    })
  );

  public measures$ = this.filterValues$.pipe(
    map((common) => {
      return {
        calcPer: this.findItemInFilters(common, FilterNameEnum.calcPer)?.filterValue[0].type,
        currency: this.findItemInFilters(common, FilterNameEnum.currency)?.filterValue[0].type,
        includeWastage: this.findItemInFilters(common, FilterNameEnum.includeWastage)?.filterValue[0].type,
      };
    }),
  );

  public maxCountriesExceeded$ = this.countryValues$.pipe(
    map((value) => {
      return value.length > this.maxCountries;
    })
  );

  public findItemInFilters(filtersObject: FiltersObject, filterName: FilterNameEnum): FilterItemInterface {
    return filtersObject && filtersObject.find((item) => item.filterName === filterName) || { filterName, filterValue: [] };
  }

  public updateItemInFilters(filterNameToUpdate: FilterNameEnum, newFilterValue: FilterItemValueInterface[]): FiltersObject {
    const updatedFiltersObject: FiltersObject = [...this.filterValues$.getValue()
      .filter((filtersItem) => filtersItem.filterName !== filterNameToUpdate)];
    updatedFiltersObject.push({
      filterName: filterNameToUpdate,
      filterValue: newFilterValue
    });
    return updatedFiltersObject;
  }

  public deletedItemInFilters(item: FilterItemValueInterface, filterType: FilterNameEnum, filtersValue: FiltersObject): FiltersObject {
    const filterItemValue: FilterItemValueInterface[] = this.findItemInFilters(filtersValue, filterType)?.filterValue || [];
    return this.updateItemInFilters(filterType, filterItemValue.filter((item1) => item1.id !== item.id));
  }

  public getSetOfFilters(filtersObject: FiltersObject, isFromIndicationMatrix = false): SetOfFiltersInterface {
    const indications: string[] = this.findItemInFilters(filtersObject, FilterNameEnum.indications)?.filterValue.length ?
      isFromIndicationMatrix ? [] :
        [this.findItemInFilters(filtersObject, FilterNameEnum.indications)?.filterValue.map((item) => item.name)[0]] : [];
    const allBrands = this.findItemInFilters(filtersObject, FilterNameEnum.brands)?.filterValue.map((item) => item.name) || [];
    return {
      indications,
      countries: this.findItemInFilters(filtersObject, FilterNameEnum.countries)?.filterValue.map((item) => item.name) || [],
      brands: allBrands.slice(0, this.maxBrands),
      comparatorDrug: this.findItemInFilters(filtersObject, FilterNameEnum.comparatorDrug)?.filterValue[0].name,
      calcPer: this.findItemInFilters(filtersObject, FilterNameEnum.calcPer)?.filterValue[0].type,
      includeWastage: this.findItemInFilters(filtersObject, FilterNameEnum.includeWastage)?.filterValue[0].type,
      reimbursementStatus: this.findItemInFilters(filtersObject, FilterNameEnum.reimbursementStatus)?.filterValue[0].type,
      priceCategory: this.findItemInFilters(filtersObject, FilterNameEnum.priceCategory)?.filterValue[0].type,
      priceType: this.findItemInFilters(filtersObject, FilterNameEnum.priceType)?.filterValue[0].type,
      currency: this.findItemInFilters(filtersObject, FilterNameEnum.currency)?.filterValue[0].type,
      orphanStatus: this.findItemInFilters(filtersObject, FilterNameEnum.orphanStatus)?.filterValue[0].type,
      brandedStatus: this.findItemInFilters(filtersObject, FilterNameEnum.brandedStatus)?.filterValue[0].type,
      discontinued: this.findItemInFilters(filtersObject, FilterNameEnum.discontinued)?.filterValue[0].type,
      piStatus: this.findItemInFilters(filtersObject, FilterNameEnum.piStatus)?.filterValue[0].type,
      onLoad: this.findItemInFilters(filtersObject, FilterNameEnum.onLoad)?.filterValue[0].name
    };
  }

  setBrandsProperty(brands: FilterItemValueInterface[]) {
    this.session.setSession(SessionEnums.brands, brands);
  }

  public hasRequiredValue(payload: SetOfFiltersInterface): boolean {
    return !!(payload.brands.length && payload.countries.length && payload.indications.length);
  }
}
