<div class="toolbar d-flex flex-row justify-content-between align-items-start">
  <div class="title-wrap">
    <h2 *ngIf="title" class="title">
      <ng-container [ngTemplateOutlet]="title.template"></ng-container>
    </h2>
    <div *ngIf="description" class="description">
      <ng-container [ngTemplateOutlet]="description.template"></ng-container>
    </div>
  </div>
  <div class="actions-wrap d-flex">
    <div *ngIf="infoButton">
      <ng-container [ngTemplateOutlet]="infoButton.template"></ng-container>
    </div>
    <div *ngIf="group">
      <ng-container [ngTemplateOutlet]="group.template"></ng-container>
    </div>
    <mat-button-toggle-group *ngIf="hasToggler()" appearance="legacy" [value]="mode" class="he-button-toggle-group">
      <mat-button-toggle [value]="modes.chart" [title]="isChartModeDisabled? '': 'View visualisation for this element'" (click)="onUseChart()" class="he-button-toggle he-button-toggle-grey"
        [disabled]="isChartModeDisabled ? true : null" [class.he-widget-mode-disabled]="isChartModeDisabled" 
        [matTooltip]="chartModeTooltip ?? ''"
        matTooltipClass="he-tooltip"
        [matTooltipDisabled]="!isChartModeDisabled">
        <he-icon [id]="'pie-chart-viz'"></he-icon>
      </mat-button-toggle>
      <mat-button-toggle [value]="modes.table" title="View data for this element" (click)="onUseTable()" class="he-button-toggle he-button-toggle-grey">
        <he-icon [id]="'list'"></he-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <button title="Options" mat-icon-button [matMenuTriggerFor]="menu" class="he-icon-button he-button">
      <he-icon [id]="'more-vertical'"></he-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="he-menu-panel">
      <button mat-menu-item title="Chat with support" (click)="onAskQuestion()">
        <he-icon [id]="'user'"></he-icon>
        Ask question
      </button>
      <div *ngIf="actions">
        <ng-container [ngTemplateOutlet]="actions.template"></ng-container>
      </div>
    </mat-menu>
  </div>
</div>

<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="modes.chart">
    <div *ngIf="chart">
      <ng-container [ngTemplateOutlet]="chart.template"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="modes.table">
    <div *ngIf="table">
      <ng-container [ngTemplateOutlet]="table.template"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span>{{ 'info-widget: unknown mode' }}</span>
  </ng-container>
</ng-container>

<ng-container *ngIf="panel" [ngTemplateOutlet]="panel.template"></ng-container>
