export type FiltersObject = FilterItemInterface[];

export interface FilterItemInterface {
  filterName: FilterNameEnum;
  filterValue: FilterItemValueInterface[];
}

export interface FilterItemValueInterface {
  id?: number;
  type?: any;
  name: any; // string | boolean
  flagId?: string;
  status?: string;
}

export enum FilterNameEnum {
  indicationsAllowed = 'indicationsAllowed',
  indications = 'indications',
  countries = 'countries',
  allCountriesInScope = 'allCountriesInScope',
  brands = 'brands',
  comparatorDrug = 'comparatorDrug',
  calcPer = 'calcPer',
  cotType = 'cotType',
  includeWastage = 'includeWastage',
  reimbursementStatus = 'reimbursementStatus',
  averageBodyWeight='averageBodyWeight',
  bodySurfaceArea='bodySurfaceArea',
  priceCategory = 'priceCategory',
  priceType = 'priceType',
  currency = 'currency',
  localCurrency = 'localCurrency',
  orphanStatus = 'orphanStatus',
  brandedStatus = 'brandedStatus',
  discontinued = 'discontinued',
  piStatus = 'piStatus',
  topLevelIndications = 'topLevelIndications',
  onLoad = 'onLoad',
  searchType = 'searchType',
  routeOfAdministartion = 'Route Of Administration',
  cotLevel = 'cotLevel',
  cotCalculator = 'cotCalculator',
  corridorCOTIds = 'corridorCOTIds',
}

export enum FilterMeasuresPeriodEnum {
  annum = 'annum',
  month = 'month',
  day = 'day',
  cycle = 'cycle',
  ddd = 'ddd',
  pack = 'pack',
  mg = 'mg',
  unit = 'unit',
  pfs = 'pfs',
}

export enum FilterMeasuresCOTTypeEnum {
  adult = 'Adult',
  paediatric = 'Children (Paediatric)'
}

export enum FilterMeasuresPriceCategoryEnum {
  List = 'List',
  VisibleNet = 'VisibleNet',
  LessDiscount = 'LessDiscount',
}

export enum FilterMeasuresPriceTypeEnum {
  exfactoryprice = 'exfactoryprice',
  hospitalprice = 'hospitalprice',
  ppp = 'ppp',
  ppexvat = 'ppexvat',
  ppincvat = 'ppincvat',
  hierarchy = 'hierarchy'
}

export enum FilterProductParamsReimbursementEnum {
  all = 'All',
  reimbursed = 'Reimbursed',
  reimbursedWithRestrictions = 'Reimbursed with restrictions',
  notReimbursed = 'Not reimbursed',
}

export enum FilterProductParamsOrphanStatusEnum {
  all = 'All',
  orphan = 'Orphan',
  nonOrphan = 'Non orphan',
}

export enum FilterProductParamsBrandedStatusEnum {
  all = 'All',
  branded = 'BRANDED',
  generic = 'GENERIC',
  biosimilar = 'BIOSIMILAR',
}

export enum FilterIncludeExcludeEnum {
  include = 'Include',
  exclude = 'Exclude',
}
export enum FilterRouteOfAdministrationEnum {
  Injection = 'Injection',
  Infusion = 'Infusion',
  Capsules = 'Capsules',
  Tablets = 'Tablets',
  TopicalLotion = 'Topical Lotion',
  MedicatedImplant = 'Medicated Implant'


}
export enum ContextSourceTypeEnum {
  brand = 'brand',
  indication = 'indication'
}

export interface DatasetRowInterface {
  [key: string]: string | boolean | number | Date;
}

export interface ISearchType {
  id: string,
  type: string,
  name: string
}


export interface CustomColumnListOutput {
  selectedColumns: string;
  unSelectedColumns: string;
  gridViewType: string;
  type: string;
  error?: boolean;
}

export enum FilterCotLevelsEnum {
  highest = 'Highest',
  median = 'Median',
  lowest = 'Lowest',
}
