<mat-tab-group animationDuration="0ms" [selectedIndex]="0" (selectedTabChange)="tabClick($event)">
  <mat-tab label="Project Details">
    <div class="card-wrapper">
      <mat-card class="example-card horizon-card" style="padding: 0px">
        <mat-card-header> </mat-card-header>
        <mat-card-content>
          <he-project-details-tab></he-project-details-tab>
        </mat-card-content>
      </mat-card>
    </div>
     <div class="card-wrapper">
          <he-project-analogues-section></he-project-analogues-section>
    </div> 
    <div class="card-wrapper">
      <mat-card class="example-card horizon-card" style="padding: 0px">
        <mat-card-header> </mat-card-header>
        <mat-card-content>
          <he-project-files></he-project-files>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab label="Asset TPP">
    <!-- <he-project-asset-tpp style="display: grid;"></he-project-asset-tpp> -->
    <he-project-asset-tpp-new style="display: grid"></he-project-asset-tpp-new>
  </mat-tab>
  <mat-tab label="Analogues">
    <he-project-analogue></he-project-analogue>
  </mat-tab>
  <mat-tab label="Value">
    <he-project-value-scoring></he-project-value-scoring>
  </mat-tab>
  <mat-tab label="Forecast">
    <he-project-forecast></he-project-forecast>
  </mat-tab>
  <mat-tab label="Summary" *heFeatureFlag="{ Name: 'project-summary', Page: 'Dashboard' }">
    <he-project-summary></he-project-summary>
    <!-- <he-project-forecast></he-project-forecast> -->
  </mat-tab>
</mat-tab-group>
