import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  AnalogueTableInterface,
  CriteriaApiInterFace,
  LegendEnum,
} from '../models/criteria.interface';
import { AnalogueMasterData } from '../../../horizon-project/models/AnalogueMaseterData';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';

@Injectable({
  providedIn: 'root',
})
export class CriteriaSelectionService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  public criteriaList = new BehaviorSubject<any>(null);
  public castCriteriaList = this.criteriaList.asObservable();

  public openedTabindex = new BehaviorSubject<boolean>(false);
  public castopenedTabindext = this.openedTabindex.asObservable();

  public savedTableData = new BehaviorSubject<any>([]);
  public castSavedTableData = this.savedTableData.asObservable();

  public therapyAreaList = new BehaviorSubject<any>([]);
  public castTherapyAreaList = this.therapyAreaList.asObservable();

  public savedSelectedAnalogues = new BehaviorSubject<any>([]);
  public castSavedSelectedAnalogues =
    this.savedSelectedAnalogues.asObservable();

    public sortedAnalogues = new BehaviorSubject<any>([]);
    public castsortedAnaloguesStatus =
      this.sortedAnalogues.asObservable();

  public brandCountData = new BehaviorSubject<any>([]);
  public castBrandCountData = this.brandCountData.asObservable();

  public isAnaloguesValid:boolean = false;

  constructor(private http: HttpClient,private countryFlagService: CountryFlagService) {}

  public cleaSelectionCriteria(): void {
    this.criteriaList.next(null);
    this.openedTabindex.next(false);
    this.savedTableData.next([]);
    this.brandCountData.next([]);
    this.savedSelectedAnalogues.next([]);
  }

  public getAnalogueSelectionCriteria(): Observable<CriteriaApiInterFace> {
    return this.http.get<CriteriaApiInterFace>(
      `${this.projectAPIUri}/Master/GetAnalogueSelectionCriteria`
    );
  }

  public getAnalogue(body: any): Observable<AnalogueTableInterface[]> {
    const urlString = new URL(
      `${this.projectAPIUri}/Analogue/GetAnalogueByCriteria`
    );
    return this.http.post<AnalogueTableInterface[]>(urlString.href, body).pipe(
      map((analogue: any | null) => {
        return analogue.map((res: any) => {
          return {
            toggle: false,
            Brand: res.brand.value,
            Orphan: {
              name: res.orphan.value,
              color: LegendEnum[res.orphan.heatMapCode],
            },
            INN: res.inn.value,
            Market:this.setMarkeIds(res.countries),
            Oneofftherapy: {
              name: res.oneOffTherapy.value,
              color: LegendEnum[res.oneOffTherapy.heatMapCode],
            },
            Eligiblepatientnumber: {
              name: res.eligiblePatientNumber.value,
              color: LegendEnum[res.eligiblePatientNumber.heatMapCode],
            },
            Population: {
              name: res.population.value,
              color: LegendEnum[res.population.heatMapCode],
            },
            Indication: {
              name: res.indication.value,
              color: LegendEnum[res.indication.heatMapCode],
            },
            Routeofadministration: {
              name: res.routeOfAdministration.value,
              color: LegendEnum[res.routeOfAdministration.heatMapCode],
            },
            Mechanismofaction: {
              name: res.moaCategory.value,
              color: LegendEnum[res.moaCategory.heatMapCode],
            },
            //Might needed later
            // TherapyArea: {
            //   name: res.therapyArea.value,
            //   color: LegendEnum[res.therapyArea.heatMapCode],
            // },
            // IndicationCategory: {
            //   name:res.indicationCategory.value ,
            //   color: LegendEnum[res.indicationCategory.heatMapCode],
            // },
          };
        }).sort((a:any, b:any) => {
          return this.compare(a.Brand, b.Brand,true)
        });
      })
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public setMarkeIds(countries:string[]):any[]{
    let ids:any=[]
    countries.forEach(country => {
      ids.push({
        name:country,
        id:this.countryFlagService.getFlagId(country)})
    });
    return ids
  }

  public randomNo():number{
    return Math.floor(Math.random() * 4)
  }

  public getAnalogueMaster(
    TLI: string,
    MOA: string
  ): Observable<AnalogueMasterData[]> {
    const urlString = new URL(
      `${this.projectAPIUri}/Analogue/GetAnalogueMaster?TLI=${TLI}&MOA=${MOA}`
    );
    return this.http.get<AnalogueMasterData[]>(urlString.href);
  }
}
