import { Component, EventEmitter, Input, Output,OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  NetPriceInterface,
  OverallNetPriceInterface,
} from '../../models/netprice.interface';
import { NetPriceService } from '../../services/net-price.service';
import { ProjectService } from '../../services/project.service';
import { Subject, take, takeUntil } from 'rxjs';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
export interface PeriodicElement {
  Brand: string;
  position: number;
  INN: number;
  TLI: string;
}

@Component({
  selector: 'he-specific-price-assumption',
  templateUrl: './specific-price-assumption.component.html',
  styleUrls: ['./specific-price-assumption.component.scss'],
})
export class SpecificPriceAssumptionComponent implements OnInit {
  @Input() ProjectNetPrice: NetPriceInterface = {
    IsNetPriceIncluded: false,
    isAnnualCOTInlcuded: false,
    OverallNetPrice: [],
    AnalogueNetPrice: [],
    AnaglueChanged:false

  };
  @Output() public emitUpdatePrice = new EventEmitter<any>();
  loading:boolean= true;

  displayedColumns: string[] =[];
  // countries: string[] = [];
  dataSource: any = [];
  private unsubscriber$ = new Subject<void>();
  constructor(
    public netPriceService: NetPriceService,
    private projectService: ProjectService,
    private criteriaSelectionService: CriteriaSelectionService,

  ) {}


  ngOnInit(): void {
    this.loading = true;
    this.dataSource = this.ProjectNetPrice.AnalogueNetPrice;
    this.displayedColumns = [... ['Brand', 'INN', 'TLI'], ...this.netPriceService.selectedMarkets.sort((a:any,b:any)=> a.localeCompare(b))];
    this.netPriceService.selectedAnalogueNetprice.forEach((res) => {
      let newData: any = {
        Brand: res.Brand,
        INN: res.INN,
        TLI: res.TLI.toUpperCase(),
        markets: res.markets,
        sqNo: res.sqNo,
      };
      if (!this.dataSource.some((x: any) => x.Brand === res.Brand  && x.INN === res.INN && x.TLI === res.TLI)) {
        this.ProjectNetPrice.OverallNetPrice.forEach((country) => {
          if (
            res.markets.some(
              (x: any) => x.name.toLowerCase() === country.country.toLowerCase()
            )
          ) {
            newData[country.country] = {
              NetPriceDiscount:(0).toFixed(2),
              AnalogueCOT:''
            };
          } else {
            newData[country.country] ={
              NetPriceDiscount: 'NA',
              AnalogueCOT: 'NA'
             };
          }
        });
        this.dataSource.push(newData);
      }else{
        this.dataSource.filter((x:any)=>x.Brand === res.Brand  && x.INN === res.INN && x.TLI === res.TLI)[0].sqNo = res.sqNo;
      }
      this.dataSource = this.dataSource.sort((a: any, b: any) => {
        return a.sqNo - b.sqNo;
      })
      this.dataSource.forEach((res:any)=>{
        res.TLI = res.TLI.toUpperCase();
      })
    });

    this.getAnalogue();
    this.dataSource.forEach((data: any, index: number) => {
      if (
        !this.netPriceService.selectedAnalogueNetprice.some(
          (x: any) => x.Brand === data.Brand && x.INN === data.INN && x.TLI === data.TLI
        )
      ) {
        this.dataSource.splice(index, 1);
      }
    });
    setTimeout(() => {
    this.loading=false;
    }, 2000);
    // this.setPrice(this.ProjectNetPrice.AnaglueChanged);
  }


  public getAnalogue(): void {
    let body = this.netPriceService.heatMapPayload;
    if(body !== null){
    body.Countries = this.ProjectNetPrice.OverallNetPrice.map(country=> country.country);
      this.criteriaSelectionService
      .getAnalogue(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          res.forEach(analgoue => {
            this.dataSource.forEach((element: any) => {
              if (element.Brand === analgoue.Brand && element.INN === analgoue.INN && element.TLI === analgoue.Indication.name) {
                if(element.markets != undefined){
                element.markets = [...element.markets , ...analgoue.Market];
                }else{
                  element.markets = analgoue.Market;
                }
                this.ProjectNetPrice.OverallNetPrice.forEach((country) => {
                  if (
                    !element.markets.some(
                      (x: any) => x.name.toLowerCase() === country.country.toLowerCase()
                    )
                  ) {
                    if(element[country.country] === undefined){
                      element[country.country] = {
                        NetPriceDiscount: 'NA',
                        AnalogueCOT: 'NA'
                       };
                    }
                  }
                  else{
                    if(element[country.country]?.NetPriceDiscount === undefined){
                      element[country.country] = {
                        NetPriceDiscount:(0).toFixed(2),
                        AnalogueCOT:''
                       };
                    }
                  }
                });
              }
            });
            
          });
          this.dataSource = this.dataSource.sort((a: any, b: any) => {
            return a.sqNo - b.sqNo;
          })
          this.loading=false;
          }
      );

    }
  }
  
  public updatePrice(value: any, element: any, country: string,changed:boolean): void {
    element[country] = value;
    this.setPrice(changed);
    this.resetDiscount();
    this.projectService.valueChangeStatus.next(true);
    this.projectService.netPriceChanged = true
    this.projectService.newValuechanged.next(true)

  }

  public resetDiscount():void{
      this.ProjectNetPrice.AnalogueNetPrice.forEach(res=>{
        this.netPriceService.selectedMarkets.forEach(x=>{
 
          if( res[x].NetPriceDiscount !== 'NA'){
            if(this.ProjectNetPrice.isAnnualCOTInlcuded){
              res[x].NetPriceDiscount = '';
            }else{
              res[x].AnalogueCOT = '';
            }
          }
        })
      })
      if(this.ProjectNetPrice.isAnnualCOTInlcuded){
      this.ProjectNetPrice.OverallNetPrice.forEach(res=>{
        res.NetPriceDiscount = '';
      })
    }

  }

  public setPrice(changed:boolean): void {
    this.ProjectNetPrice.AnaglueChanged = changed;
    this.ProjectNetPrice.AnalogueNetPrice = this.dataSource;
    this.emitUpdatePrice.emit()
    // this.netPriceService.netPriceChange.next(this.ProjectNetPrice);
    // this.netPriceService.valueChanged = true;

  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}

