<span class="d-flex mb-2" *ngIf="label">{{ label }}</span>
<div heClickOutside (clickOutside)="closeSelect()" class="he-select-container" [class.disabled]="disabled">
  <div [ngClass]="{'open': isOpened}" (click)="isOpened ? closeSelect() : openSelect()" class="he-select"
    [class.mt-1]="label">
    <div *ngIf="!isEditable ; else  editableControl">
      <span #nonEditableControl *ngIf="selectedItem; else noSelected" class="he-selected-item down"
        [ngClass]="{'down': !isOpened, 'up': isOpened}">
        <img *ngIf="selectedItem.flagId" class="flag" src="assets/flags/{{selectedItem.flagId}}.svg" />
        {{ selectedItem?.name === 'Pfs' ? 'PFS' : selectedItem?.name}}
      </span>
    </div>
    <!-- Editable dropdown in COT Corridor and History - more than 200 brand search -->
    <ng-template #editableControl>
      <input *ngIf="selectedItem; else noSelected" class="he-input" (input)="onSearchChange($event)"
        value={{brandnameTodisplay}}>
      <i class="arrow" [class.downarrow]="!isOpened" [class.uparrow]="isOpened"
        (click)="isOpened ? closeSelect() : openSelect()">
      </i>
    </ng-template>
    <!---->
    <ng-template #noSelected>
      <span class="no-options">{{ defaultText }}</span>
    </ng-template>
  </div>
  <ul [ngStyle]="{'display': isOpened ? 'block' : 'none'}">
    <li (click)="selectItem(item)" *ngFor="let item of tempItems" [class.active]="selectedItem?.name === item.name">
      <img *ngIf="item.flagId" class="flag" src="assets/flags/{{item.flagId}}.svg" />
      {{ item.name }}
    </li>
  </ul>
</div>